import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { orderBy as sort } from "lodash";

import { SetTicketsAction } from "../interface/redux-types/SetTicketsAction";
import { RootState } from "..";
import { TicketState } from "../interface/redux-types/TicketState";
import { SetTicketAction } from "../interface/redux-types/SetTicketAction";
import { SetTicketInTicketsAction } from "../interface/redux-types/SetTicketInTicketsAction";
import { isDateEqualTo } from "../utils/date-helper";

const initialState: TicketState = {
  tickets: [],
  ticket: null,
  showActive: true,
  showReceived: false,
};

const slice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    setTickets: (state, action: PayloadAction<SetTicketsAction>) => {
      state.tickets = action.payload.tickets;
    },

    setTicketInTickets: (
      state,
      action: PayloadAction<SetTicketInTicketsAction>
    ) => {
      const { ticket } = action.payload;

      const index = state.tickets.findIndex((t) => t.id === ticket.id);
      state.tickets[index] = ticket;
    },
    setTicket: (state, action: PayloadAction<SetTicketAction>) => {
      state.ticket = action.payload.ticket;
    },
    setFilterToggleShowActive: (state) => {
      state.showActive = !state.showActive;
      state.showReceived = false;
    },
    setFilterToggleShowReceived: (state) => {
      state.showReceived = !state.showReceived;
      state.showActive = false;
    },
    updateBulkTickets: (state, action: PayloadAction<SetTicketsAction>) => {
      const updatedTickets = action.payload.tickets;
      for (let updatedTicket of updatedTickets) {
        let index = state.tickets.findIndex((t) => t.id === updatedTicket.id);
        state.tickets[index] = updatedTicket;
      }
    },
  },
});

export const {
  setTickets,
  setTicketInTickets,
  setTicket,
  updateBulkTickets,
  setFilterToggleShowActive,
  setFilterToggleShowReceived,
} = slice.actions;

export const getTickets = createSelector(
  (state: RootState) => state.ticketStore.tickets,
  (state: RootState) => state.ticketStore.showActive,
  (state: RootState) => state.ticketStore.showReceived,
  (tickets, showActive, showReceived) => {
    if (showActive) {
      tickets = tickets.filter(
        (t) => isDateEqualTo(t.timeLoaded, new Date()) && t.isReceived === false
      );
    }

    if (showReceived) {
      tickets = tickets.filter(
        (t) =>
          (isDateEqualTo(t.timeLoaded, new Date()) && t.isReceived) ||
          (isDateEqualTo(t.timeReceived, new Date()) && t.isReceived)
      );
    }

    tickets = sort(tickets, ["ticketNumber"], ["desc"]);
    return tickets;
  }
);

export const getReportTickets = createSelector(
  (state: RootState) => state.ticketStore.tickets,
  (tickets) => {
    tickets = sort(tickets, ["ticketNumber"], ["desc"]);
    return tickets;
  }
);

export const getTicket = createSelector(
  (state: RootState) => state.ticketStore.ticket,
  (ticket) => {
    return ticket;
  }
);

export const getShowActive = createSelector(
  (state: RootState) => state.ticketStore.showActive,
  (showActive) => {
    return showActive;
  }
);

export const getShowReceived = createSelector(
  (state: RootState) => state.ticketStore.showReceived,
  (showReceived) => {
    return showReceived;
  }
);

export const getTodaysTickets = createSelector(
  (state: RootState) => state.ticketStore.tickets,
  (tickets) => {
    tickets = tickets.filter((t) => isDateEqualTo(t.timeLoaded, new Date()));
    return tickets;
  }
);

export const getTicketCount = createSelector(
  (state: RootState) => state.ticketStore.tickets,
  (tickets) => {
    return tickets.length;
  }
);

export const reducer = slice.reducer;
