import { useMemo, useState } from "react";
import { connect } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { history } from "../..";
import { clearUser, getUsers, setUsers } from "../../store/userStore";
import userService from "../../services/userService";
import { User } from "../../interface/api/User";
import { SetUsersAction } from "../../interface/redux-types/SetUsersAction";
import { getRoleName } from "../../utils/constants";
import PageHero from "../shared/page-hero";
import DeleteDialog from "../shared/dialog/delete";
import { StyledTableCell } from "../shared/styled-table-cell";
import { StyledTableRow } from "../shared/styled-table-row";
import useTimeout from "../../hooks/useTimeout";
import Button from "../shared/Button";
import { DeleteIcon, EditIcon } from "../shared/icons";

const useStyles = makeStyles({
  table: {
    minWidth: 1200,
  },
  iconRow: {
    display: "flex",
    justifyContent: "space-around",
    minWidth: "150px",
  },
});

const UsersView = ({}) => {
  const classes = useStyles();

  const users = useAppSelector(getUsers);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Partial<User>>({});
  const onClose = () => setOpen(false);

  useTimeout(() => dispatch(clearUser()), 100);

  useMemo(() => {
    userService.getUsers().then((response) => {
      if (response.ok) {
        const users = response.data as User[];
        const setUsersAction: SetUsersAction = {
          users,
        };
        dispatch(setUsers(setUsersAction));
      }
    });
  }, []);

  const onConfirmDelete = () => {
    if (selectedItem && selectedItem.id) {
      userService.deleteUser(selectedItem.id).then((response) => {
        if (response.ok) {
          const setUsersAction: SetUsersAction = {
            users: users.filter((u) => u.id !== selectedItem.id),
          };
          dispatch(setUsers(setUsersAction));
        }
      });
    }

    onClose();
  };

  return (
    <>
      <Container maxWidth="xl">
        <PageHero
          title="Users"
          subtitle="Add and Manage Users."
          showBackButton={false}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Button
              label="Add User"
              type="button"
              onClick={() => history.push(`/user/new`)}
              variant="contained"
              color="primary"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs>
            {users && (
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>ID</StyledTableCell>
                      <StyledTableCell>Username</StyledTableCell>
                      <StyledTableCell>First Name</StyledTableCell>
                      <StyledTableCell>Last Name</StyledTableCell>
                      <StyledTableCell>Password</StyledTableCell>
                      <StyledTableCell>Role</StyledTableCell>
                      <StyledTableCell>Phone Number</StyledTableCell>
                      <StyledTableCell>Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user: User) => {
                      return (
                        <StyledTableRow key={user.id}>
                          <StyledTableCell>{user.id}</StyledTableCell>
                          <StyledTableCell>{user.username}</StyledTableCell>
                          <StyledTableCell>{user.firstName}</StyledTableCell>
                          <StyledTableCell>{user.lastName}</StyledTableCell>
                          <StyledTableCell>******</StyledTableCell>
                          <StyledTableCell>
                            {getRoleName(user.roleId)}
                          </StyledTableCell>
                          <StyledTableCell>{user.phoneNumber}</StyledTableCell>
                          <StyledTableCell>
                            <div className={classes.iconRow}>
                              <div>
                                <EditIcon
                                  onClick={() =>
                                    history.push(`/user/${user.id}`)
                                  }
                                />
                              </div>
                              <div>
                                <DeleteIcon
                                  onClick={() => {
                                    setSelectedItem(user);
                                    setOpen(true);
                                  }}
                                />
                              </div>
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Container>
      <DeleteDialog
        open={open}
        onClose={onClose}
        onConfirmDelete={onConfirmDelete}
      />
    </>
  );
};

export default connect()(UsersView);
