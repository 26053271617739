import http from "./httpService";
import auth from "./authService";
import { User } from "../interface/api/User";

http.setJwt(auth.getJwt());

export const deleteUser = (id: number | string) => {
  return http.delete(`/api/user/${id}`);
};

export const getUser = (id: number | string) => {
  return http.get(`/api/user/${id}`);
};

export const getUsers = () => {
  return http.get("/api/user/all");
};

export function saveUser(user: User) {
  if (user && user.id > 0) {
    return http.put("/api/user/edit", user);
  }
  return http.post("/api/user/new", user);
}

export default {
  getUser,
  deleteUser,
  getUsers,
  saveUser,
};
