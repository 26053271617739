import { useFormikContext } from "formik";
import { Box, Grid } from "@material-ui/core";

import { TicketFormValues } from "../../../interface/formik/TicketFormValues";
import FormErrorMessage from "../../shared/form-error-message";
import FormTextField from "../../shared/form-text-field";

const TicketHeader = () => {
  const { values } = useFormikContext<TicketFormValues>();
  return (
    <>
      <Box
        sx={{
          marginBottom: "25px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <FormTextField
              name="id"
              label="Ticket Id"
              value={values.id}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormTextField
              name="ticketNumber"
              label="Ticket Number"
              value={values.ticketNumber}
            />
            <FormErrorMessage name="ticketNumber" />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TicketHeader;
