import TextField from "@material-ui/core/TextField";
import { useFormikContext } from "formik";

import { FormTextFieldProps } from "../../interface/formik/FormTextFieldProps";

const FormTextField = ({
  name,
  type,
  label,
  value,
  onChange,
  disabled,
  dontCapitalize,
}: FormTextFieldProps) => {
  const { handleChange, handleBlur } = useFormikContext<any>();

  const parseValue = () => {
    if (typeof value === "string" && !dontCapitalize) {
      return capitalizeFirstLetter(value);
    } else {
      return value;
    }
  };

  const capitalizeFirstLetter = (value: string) => {
    if (!value) return "";
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  return (
    <TextField
      type={type}
      variant="outlined"
      label={label}
      name={name}
      disabled={disabled}
      onChange={onChange ? (e) => onChange(e) : handleChange}
      onBlur={handleBlur}
      value={value ? parseValue() : ""}
      fullWidth
    />
  );
};

FormTextField.defaultProps = {
  type: "text",
};

export default FormTextField;
