import http from "./httpService";
import auth from "./authService";
import { TransportCompany } from "../interface/api/TransportCompany";

http.setJwt(auth.getJwt());

export const deleteTransportCompany = (id: number | string) => {
  return http.delete(`/api/transportcompany/${id}`);
};

export const getTransportCompany = (id: number | string) => {
  return http.get(`/api/transportcompany/${id}`);
};

export const getTransportCompanies = () => {
  return http.get("/api/transportcompany/all");
};

export function saveTransportCompany(transportCompany: TransportCompany) {
  if (transportCompany.id > 0) {
    return http.put("/api/transportcompany/edit", transportCompany);
  }
  return http.post("/api/transportcompany/new", transportCompany);
}

export default {
  deleteTransportCompany,
  getTransportCompany,
  getTransportCompanies,
  saveTransportCompany,
};
