import { useMemo, useState } from "react";
import { connect } from "react-redux";
import { Container, Typography, Box, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { toast } from "react-toastify";

import { TicketLog } from "../../interface/api/TicketLog";
import PageHero from "../shared/page-hero";
import ticketService from "../../services/ticketService";
import { Ticket } from "../../interface/api/Ticket";
import { TicketPayload } from "../../interface/api/TicketPayload";
import { useAppSelector } from "../../hooks";
import { getCurrentUser } from "../../store/userStore";
import { history } from "../..";
import useWindowSize from "../../hooks/useWindowSize";
import Button from "../shared/Button";

const TicketLogView = ({}) => {
  const { id }: any = useParams();
  const [ticketLogs, setTicketLogs] = useState<TicketLog[]>([]);
  const [ticket, setTicket] = useState<Ticket>();
  const user = useAppSelector(getCurrentUser);
  const [restoring, setRestoring] = useState(false);
  const { isMobile } = useWindowSize();

  useMemo(() => {
    const init = async () => {
      let response = await ticketService.getTicketLogs(id);
      if (response.ok) {
        const data = response.data as TicketLog[];
        data.sort((a, b) => (a.date > b.date ? -1 : a.date < b.date ? 1 : 0));
        setTicketLogs(data);
      }

      response = await ticketService.getTicket(id);
      if (response.ok) {
        const data = response.data as Ticket;
        setTicket(data);
      }
    };
    init();
  }, [id]);

  const formatData = (data: string) => {
    const formattedData = data
      .replace(/,/g, ",\n")
      .replace(/^./, "{\n")
      .replace(/.$/, "\n}");
    return formattedData;
  };

  const onRestore = async (oldData: string) => {
    if (!ticket) return;

    setRestoring(true);

    const _ticket = JSON.parse(oldData) as unknown as Ticket;

    const ticketPayload: TicketPayload = {
      ticket: _ticket,
      loggedInUserId: user ? user.id : undefined,
      createTransportIfDoesNotExist: false,
    };

    ticketService.saveTicket(ticketPayload).then((response) => {
      if (response.ok) {
        toast.success("Ticket restored");
        history.push(`/ticket/${ticket.id}`);
      } else {
        toast.error(response.originalError.message);
      }
    });
  };

  const ticketChanges = (ticketLog: TicketLog, index: number) => {
    return (
      <div
        key={ticketLog.id}
        style={{ borderTop: index === 0 ? "" : "2px solid", padding: "15px" }}
      >
        <div>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box sx={{ marginBottom: 15 }}>
                <Typography>
                  <b>Change Id:</b> {ticketLog.id}
                </Typography>
                <Typography>
                  <b>Timestamp:</b> {new Date(ticketLog.date).toLocaleString()}
                </Typography>
                <Typography>
                  <b>Username:</b> {ticketLog.username}
                </Typography>
                <Typography>
                  <b>First Name:</b> {ticketLog.firstName}
                </Typography>
                <Typography>
                  <b>Last Name:</b> {ticketLog.lastName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Button
                  label={restoring ? "Restoring" : `Restore ID:${ticketLog.id}`}
                  type="button"
                  variant="contained"
                  color="secondary"
                  disabled={restoring}
                  onClick={() => onRestore(ticketLog.currentDataObject)}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
        <ReactDiffViewer
          oldValue={formatData(ticketLog.currentDataObject)}
          newValue={formatData(ticketLog.updatedDataObject)}
          compareMethod={DiffMethod.WORDS}
          splitView={isMobile ? false : true}
          hideLineNumbers={true}
          showDiffOnly={true}
          leftTitle={"Before"}
          rightTitle={"After"}
        />
      </div>
    );
  };

  return (
    <Container maxWidth="xl">
      <PageHero
        title="Ticket Logs"
        subtitle="A Log of Ticket Edits."
        showBackButton={true}
      />
      {ticketLogs.length ? (
        ticketLogs.map((log, index) => ticketChanges(log, index))
      ) : (
        <PageHero
          title="No ticket logs found"
          subtitle=""
          showBackButton={false}
        />
      )}
    </Container>
  );
};

export default connect()(TicketLogView);
