import { Card, CardContent, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useAppSelector } from "../../../hooks/useAppSelector";

import { TruckScaleFormValues } from "../../../interface/formik/ScaleFormValues";
import { getSetting } from "../../../store/settingStore";
import { formatTime } from "../../../utils/date-helper";
import Alert from "../../shared/Alert";

const TicketDetails = () => {
  const { values } = useFormikContext<TruckScaleFormValues>();
  const { ticket, truck } = values;
  const setting = useAppSelector(getSetting);

  if (!ticket) return <Alert severity="error">No ticket selected.</Alert>;
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography>
          <b>Haulage Co.:</b> {ticket.transportCompanyName}
        </Typography>
        <Typography>
          <b>Truck No./Plate No.:</b> {ticket.truckNumber} /{" "}
          {ticket.truckLicensePlate}
        </Typography>
        <Typography>
          <b>Tare Weight:</b>
          {truck ? truck.tareWeight : setting.defaultTareWeight} KG
        </Typography>
        <Typography>
          <b>Driver Name:</b> {ticket.truckDriverName}
        </Typography>
        <Typography>
          <b>Time Loaded:</b>{" "}
          {formatTime(ticket.timeLoaded, ticket.isSoilFLOLoad)}
        </Typography>
        <Typography>
          <b>Material:</b> {ticket.materialName}
        </Typography>
        <Typography>
          <b>Weight:</b> {ticket.weight} KG
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TicketDetails;
