import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import configureStore from "./store/configureStore";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { ToastContainer } from "react-toastify";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import "react-toastify/dist/ReactToastify.css";

const baseUrl = document
  .getElementsByTagName("base")[0]
  .getAttribute("href") as string;
export const history = createBrowserHistory({ basename: baseUrl });

const store = configureStore(history);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const theme = createTheme({
  palette: {
    primary: {
      main: "#032e5b",
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={false}
      closeOnClick
      style={{ width: "40%" }}
    />
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker();
