import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from "@material-ui/core";
import { useFormikContext } from "formik";

import { ConfirmUpdateWeightProps } from "../../../interface/dialog/ConfirmUpdateWeightProps";
import { TruckScaleFormValues } from "../../../interface/formik/ScaleFormValues";
import Button from "../Button";

const useStyles = makeStyles({
  dialogActions: {
    padding: "20px",
  },
});

const ConfirmUpdateWeightDialog = ({
  open,
  onClose,
}: ConfirmUpdateWeightProps) => {
  const classes = useStyles();

  const { handleSubmit } = useFormikContext<TruckScaleFormValues>();

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>{"Manually Set Scale Weight"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to manually update the scale weight?
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          label="No"
          type="button"
          onClick={() => onClose()}
          color="default"
          variant="contained"
        />
        <Button
          label="Yes"
          type="button"
          onClick={() => {
            handleSubmit();
            onClose();
          }}
          autoFocus
          color="primary"
          variant="contained"
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmUpdateWeightDialog;
