import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

import { FreeSelectProps } from "../../interface/formik/FreeSelectProps";

const FreeSelect = ({
  name,
  label,
  value,
  items,
  onChange,
}: FreeSelectProps) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        value={value ? value : ""}
        name={name}
        id={name}
        onChange={(e) => onChange(e)}
        label={label}
        defaultValue={0}
        displayEmpty={true}
        fullWidth
      >
        <MenuItem key={0} value={0}>
          (Clear Value)
        </MenuItem>
        {items}
      </Select>
    </FormControl>
  );
};

export default FreeSelect;
