import { makeStyles, Typography } from "@material-ui/core";

import { InputSubtitleProps } from "../../interface/InputSubtitleProps";

const useStyles = makeStyles({
  text: {
    marginTop: "6px",
    paddingLeft: 12,
  },
});

const InputSubtitle = ({ text }: InputSubtitleProps) => {
  const classes = useStyles();
  return (
    <Typography color="primary" variant="subtitle2" className={classes.text}>
      {text}
    </Typography>
  );
};

export default InputSubtitle;
