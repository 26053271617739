const useFocusSearch = (name: string) => {
  const focusOnSearchInput = () => {
    const searchInput = document.getElementsByName(name);
    if (searchInput) {
      searchInput[0]?.focus?.();
    }
  };

  const doFocusSearchInput = () => {
    setTimeout(() => {
      focusOnSearchInput();
    }, 500);
  };

  return { focusOnSearchInput, doFocusSearchInput };
};

export default useFocusSearch;
