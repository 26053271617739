import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { history } from "../..";
import contractorCompanyService from "../../services/contractorCompanyService";
import { SetContractorCompaniesAction } from "../../interface/redux-types/SetContractorCompaniesAction";
import { ContractorCompany } from "../../interface/api/ContractorCompany";
import {
  clearContractorCompany,
  clearContractorCompanyContacts,
  getContractorCompanies,
  setContractorCompanies,
} from "../../store/contractorStore";
import PageHero from "../shared/page-hero";
import DeleteDialog from "../shared/dialog/delete";
import { StyledTableCell } from "../shared/styled-table-cell";
import { StyledTableRow } from "../shared/styled-table-row";
import useTimeout from "../../hooks/useTimeout";
import Button from "../shared/Button";
import { DeleteIcon, EditIcon, OverThresholdIcon } from "../shared/icons";
import { ContractorCompaniesWithThresholdsResponse } from "../../interface/api/ContractorCompaniesWithThresholdsResponse";

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
  iconRow: {
    display: "flex",
    justifyContent: "space-around",
    minWidth: "150px",
  },
});

const ContractorsView = () => {
  const classes = useStyles();

  const contractorCompanies = useAppSelector(getContractorCompanies);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Partial<ContractorCompany>>(
    {}
  );
  const [
    contractorIdsThatAreOverThreshold,
    setContractorIdsThatAreOverThreshold,
  ] = useState<number[]>([]);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  useEffect(() => {
    return () => {
      dispatch(clearContractorCompanyContacts());
    };
  }, []);

  useTimeout(() => dispatch(clearContractorCompany()), 100);

  useMemo(() => {
    contractorCompanyService.getContractorCompanies().then((response) => {
      if (response.ok) {
        const contractorCompaniesWithThresholdsResponse =
          response.data as ContractorCompaniesWithThresholdsResponse;
        const contractorCompanies =
          contractorCompaniesWithThresholdsResponse.contractorCompanies as ContractorCompany[];

        setContractorIdsThatAreOverThreshold(
          contractorCompaniesWithThresholdsResponse.contractorIdsThatAreOverThreshold
        );

        const setContractorCompaniesAction: SetContractorCompaniesAction = {
          contractorCompanies: contractorCompanies,
        };
        dispatch(setContractorCompanies(setContractorCompaniesAction));
      }
    });
  }, []);

  const onConfirmDelete = () => {
    if (selectedItem && selectedItem.id) {
      contractorCompanyService
        .deleteContractorCompany(selectedItem.id)
        .then((response) => {
          if (response.ok) {
            const setContractorCompaniesAction: SetContractorCompaniesAction = {
              contractorCompanies: contractorCompanies.filter(
                (c) => c.id !== selectedItem.id
              ),
            };
            dispatch(setContractorCompanies(setContractorCompaniesAction));
          }
        });
    }

    onClose();
  };

  return (
    <>
      <Container maxWidth="xl">
        <PageHero
          title="Contractors"
          subtitle="Add and manage Contractor Companies."
          showBackButton={false}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Button
              type="button"
              onClick={() => history.push(`/contractor-company/new`)}
              variant="contained"
              color="primary"
              label="Add Contractor Company"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs>
            {contractorCompanies && (
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>ID</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {contractorCompanies.map(
                      (contractorCompany: ContractorCompany) => {
                        return (
                          <StyledTableRow key={contractorCompany.id}>
                            <StyledTableCell>
                              {contractorCompany.id}
                            </StyledTableCell>
                            <StyledTableCell>
                              {contractorCompany.name}
                            </StyledTableCell>
                            <StyledTableCell>
                              {contractorIdsThatAreOverThreshold.includes(
                                contractorCompany.id
                              ) && <OverThresholdIcon />}
                            </StyledTableCell>
                            <StyledTableCell>
                              <div className={classes.iconRow}>
                                <div>
                                  <EditIcon
                                    onClick={() =>
                                      history.push(
                                        `/contractor-company/${contractorCompany.id}`
                                      )
                                    }
                                  />
                                </div>
                                <div>
                                  <DeleteIcon
                                    onClick={() => {
                                      setSelectedItem(contractorCompany);
                                      onOpen();
                                    }}
                                  />
                                </div>
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Container>
      <DeleteDialog
        open={open}
        onClose={onClose}
        onConfirmDelete={onConfirmDelete}
      />
    </>
  );
};

export default connect()(ContractorsView);
