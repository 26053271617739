import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from "@material-ui/core";

import { DeleteDialogProps } from "../../../interface/dialog/DeleteDialogProps";
import Button from "../Button";

const useStyles = makeStyles({
  dialogActions: {
    padding: "20px",
  },
});

const DeleteDialog = ({
  open,
  onClose,
  onConfirmDelete,
}: DeleteDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>{"Confirm Deletion?"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to permanently remove this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          label="No"
          type="button"
          onClick={() => onClose()}
          color="default"
          variant="contained"
        />
        <Button
          label="Delete"
          type="button"
          onClick={() => onConfirmDelete()}
          autoFocus
          color="primary"
          variant="contained"
        />
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
