import * as Yup from "yup";
import { PhoneRegExp } from "../../../utils/constants";

export const TruckFormSchema = Yup.object().shape({
  number: Yup.string().required("Truck number is required"),
  licensePlate: Yup.string().required("License plate is required"),
  driverNumber: Yup.string()
    .nullable()
    .matches(PhoneRegExp, "Phone number is not valid"),
});
