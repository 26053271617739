import { useState } from "react";

import useEventListener from "./useEventListener";
import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";
import { WindowDimensions } from "../interface/WindowDimensions";
import { WindowSize } from "../interface/WindowSize";

function useWindowSize(): WindowDimensions {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  });

  const handleSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEventListener("resize", handleSize);

  // Set size at the first client-side load
  useIsomorphicLayoutEffect(() => {
    handleSize();
  }, []);

  return {
    windowSize,
    isMobile: Number(windowSize.width) <= 991,
    isDesktop: Number(windowSize.width) >= 1440,
  };
}

export default useWindowSize;
