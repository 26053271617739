import http from "./httpService";
import auth from "./authService";
import { ContractorPayload } from "../interface/api/ContractorPayload";

http.setJwt(auth.getJwt());

export const deleteContractorContact = (id: number | string) => {
  return http.delete(`/api/contractorcontact/${id}`);
};

export const getContractorContact = (id: number | string) => {
  return http.get(`/api/contractorcontact/${id}`);
};

export const getContractorContacts = (contractorPayload: ContractorPayload) => {
  return http.post("/api/contractorcontact/all", contractorPayload);
};

export const saveContractorContact = (contractorPayload: ContractorPayload) => {
  const contractorContact = contractorPayload.contractorContact;
  if (contractorContact && contractorContact.id > 0) {
    return http.put("/api/contractorcontact/edit", contractorPayload);
  }
  return http.post("/api/contractorcontact/new", contractorPayload);
};

export default {
  deleteContractorContact,
  getContractorContact,
  getContractorContacts,
  saveContractorContact,
};
