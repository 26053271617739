import { useState } from "react";
import { connect } from "react-redux";
import { Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import { Formik } from "formik";

import { BulkTicketsFormSchema } from "../schema/bulk-tickets-form-schema";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getCurrentUser } from "../../../store/userStore";
import FormErrorMessage from "../../shared/form-error-message";
import FormTextField from "../../shared/form-text-field";
import Button from "../../shared/Button";
import { ClearBulkTicketsPayload } from "../../../interface/api/ClearBulkTicketsPayload";
import ConfirmBulkClearDialog from "../../shared/dialog/confirm-bulk-clear";
import ticketService from "../../../services/ticketService";
import { Ticket } from "../../../interface/api/Ticket";
import { SetTicketsAction } from "../../../interface/redux-types/SetTicketsAction";
import { updateBulkTickets } from "../../../store/ticketStore";
import { toast } from "react-toastify";
import { CheckBulkUpdateResponse } from "../../../interface/api/CheckBulkUpdateResponse";

const ClearBulkTicketsForm = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const { setPending } = props;
  const user = useAppSelector(getCurrentUser);
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => {
    setOpen(false);
    setValues(undefined);
  };
  const [values, setValues] = useState<ClearBulkTicketsPayload>();
  const [response, setResponse] = useState<CheckBulkUpdateResponse>();

  const clearBulkTicketsPayload: ClearBulkTicketsPayload = {
    from: 0,
    to: 0,
    clearContractor: false,
    clearProject: false,
    clearJobSite: false,
    clearSiteContact: false,
    clearOfficeContact: false,
    clearMaterialType: false,
    loggedInUserId: user ? user.id : 0,
    clearAll: false,
  };

  const checkBulkUpdate = async (values: ClearBulkTicketsPayload) => {
    if (!values) return;

    let from = Number(values.from);
    let to = values.to ? Number(values.to) : from;

    let clearBulkTicketsPayload = {
      ...values,
      from,
      to,
    } as ClearBulkTicketsPayload;

    const response = await ticketService.checkClearBulkUpdate(
      clearBulkTicketsPayload
    );

    if (!response.ok) {
      toast.error("Error checking ticket updates");
      onClose();
    }
    setResponse(response.data as CheckBulkUpdateResponse);
  };

  const onSubmit = async (
    values: ClearBulkTicketsPayload,
    setSubmitting: Function
  ) => {
    setValues(values);
    await checkBulkUpdate(values);
    onOpen();
    setSubmitting(false);
  };

  const onConfirmSubmit = async () => {
    setPending(true);
    if (!values) {
      setPending(false);
      return;
    }

    let from = Number(values.from);
    const parsedTo = Number(values.to);
    let to = parsedTo > 0 ? parsedTo : from;

    toast.info(
      `Updating tickets from ${values.from.toString()} to ${to.toString()}`
    );

    ticketService.clearBulkTickets({ ...values, from, to }).then((response) => {
      if (response.ok) {
        const updatedTickets = response.data as Ticket[];
        const setTicketsAction: SetTicketsAction = {
          tickets: [...updatedTickets],
        };

        dispatch(updateBulkTickets(setTicketsAction));
      } else {
        toast.error("Error occurred updating tickets.");
      }

      setPending(false);
    });

    onClose();
  };

  return (
    <>
      <Formik
        initialValues={clearBulkTicketsPayload}
        validationSchema={BulkTicketsFormSchema}
        onSubmit={async (values, { setSubmitting }) =>
          onSubmit(values, setSubmitting)
        }
        enableReinitialize={true}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <FormTextField
                    name="from"
                    label="From Ticket Number"
                    value={values.from}
                  />
                  <FormErrorMessage name="from" />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <FormTextField
                    name="to"
                    label="To Ticket Number"
                    value={values.to}
                  />
                  <FormErrorMessage name="to" />
                </Grid>
                <Grid item sm={6} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.clearAll}
                        onChange={() =>
                          setFieldValue("clearAll", !values.clearAll)
                        }
                        name="clearAll"
                        color="primary"
                      />
                    }
                    label="Clear All"
                  />
                </Grid>
                <Grid item sm={6} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.clearContractor}
                        onChange={() =>
                          setFieldValue(
                            "clearContractor",
                            !values.clearContractor
                          )
                        }
                        name="Clear Contractor"
                        color="primary"
                        disabled={values.clearAll}
                      />
                    }
                    label="Contractor"
                  />
                </Grid>
                <Grid item sm={6} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.clearProject}
                        onChange={() =>
                          setFieldValue("clearProject", !values.clearProject)
                        }
                        name="Clear Project"
                        color="primary"
                      />
                    }
                    label="Project"
                    disabled={values.clearAll}
                  />
                </Grid>
                <Grid item sm={6} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.clearJobSite}
                        onChange={() =>
                          setFieldValue("clearJobSite", !values.clearJobSite)
                        }
                        name="Clear Job Site"
                        color="primary"
                      />
                    }
                    label="Job Site"
                    disabled={values.clearAll}
                  />
                </Grid>
                <Grid item sm={6} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.clearSiteContact}
                        onChange={() =>
                          setFieldValue(
                            "clearSiteContact",
                            !values.clearSiteContact
                          )
                        }
                        name="Clear Site Contact"
                        color="primary"
                      />
                    }
                    label="Site Contact"
                    disabled={values.clearAll}
                  />
                </Grid>
                <Grid item sm={6} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.clearOfficeContact}
                        onChange={() =>
                          setFieldValue(
                            "clearOfficeContact",
                            !values.clearOfficeContact
                          )
                        }
                        name="Clear Office Contact"
                        color="primary"
                        disabled={values.clearAll}
                      />
                    }
                    label="Office Contact"
                  />
                </Grid>
                <Grid item sm={6} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.clearMaterialType}
                        onChange={() =>
                          setFieldValue(
                            "clearMaterialType",
                            !values.clearMaterialType
                          )
                        }
                        name="Clear Material Type"
                        color="primary"
                        disabled={values.clearAll}
                      />
                    }
                    label="Material Type"
                  />
                </Grid>
                <Grid item sm={6} md={3}>
                  <Button
                    label={isSubmitting ? "Clearing Tickets..." : "Clear"}
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                  />
                </Grid>
              </Grid>
            </form>
            <ConfirmBulkClearDialog
              open={open}
              onClose={onClose}
              onConfirmSubmit={onConfirmSubmit}
              response={response}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default connect()(ClearBulkTicketsForm);
