import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { UserState } from "../interface/redux-types";
import { RootState } from "..";
import { SetCurrentUserAction } from "../interface/redux-types/SetCurrentUserAction";
import { SetUserErrorAction } from "../interface/redux-types/SetUserErrorAction";
import { SetUsersAction } from "../interface/redux-types/SetUsersAction";
import { SetUserAction } from "../interface/redux-types/SetUserAction";

const initialState: UserState = {
  error: "",
  currentUser: null,
  token: "",
  users: [],
  user: null,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<SetCurrentUserAction>) => {
      state.currentUser = action.payload.user;
      state.token = action.payload.token;
    },

    setUserError: (state, action: PayloadAction<SetUserErrorAction>) => {
      state.error = action.payload.error;
    },

    setUsers: (state, action: PayloadAction<SetUsersAction>) => {
      state.users = action.payload.users;
    },

    setUser: (state, action: PayloadAction<SetUserAction>) => {
      state.user = action.payload.user;
    },

    clearUser: (state) => {
      state.user = null;
    },
  },
});

export const { setCurrentUser, setUserError, setUsers, setUser, clearUser } =
  slice.actions;

export const getCurrentUser = createSelector(
  (state: RootState) => state.userStore.currentUser,
  (currentUser) => {
    return currentUser;
  }
);

export const getUserError = createSelector(
  (state: RootState) => state.userStore.error,
  (error) => {
    return error;
  }
);

export const getUsers = createSelector(
  (state: RootState) => state.userStore.users,
  (users) => {
    return users;
  }
);

export const getUser = createSelector(
  (state: RootState) => state.userStore.user,
  (user) => {
    return user;
  }
);

export const reducer = slice.reducer;
