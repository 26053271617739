import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { RootState } from "..";
import { SetSettingAction } from "../interface/redux-types/SetSettingAction";
import { SettingState } from "../interface/redux-types/SettingState";

const initialState: SettingState = {
  setting: {
    id: 0,
    currentPrefix: 0,
    defaultTareWeight: 0,
    haulageRecordReceiverName: "",
    haulageRecordReceiverPhone: "",
    haulageRecordReceiverEmail: "",
  },
};

const slice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setSetting: (state, action: PayloadAction<SetSettingAction>) => {
      state.setting = action.payload.setting;
    },
  },
});

export const { setSetting } = slice.actions;

export const getSetting = createSelector(
  (state: RootState) => state.settingStore.setting,
  (setting) => {
    return setting;
  }
);

export const reducer = slice.reducer;
