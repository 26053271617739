import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { history } from "../..";
import PageHero from "../shared/page-hero";
import {
  clearMaterial,
  clearMaterialType,
  getMaterials,
  getMaterialTypes,
  setMaterials,
  setMaterialTypes,
} from "../../store/scaleStore";
import { Material } from "../../interface/api/Material";
import scaleService from "../../services/scaleService";
import { SetMaterialsAction } from "../../interface/redux-types/SetMaterialsAction";
import DeleteDialog from "../shared/dialog/delete";
import { StyledTableCell } from "../shared/styled-table-cell";
import { StyledTableRow } from "../shared/styled-table-row";
import useTimeout from "../../hooks/useTimeout";
import Button from "../shared/Button";
import { DeleteIcon, EditIcon } from "../shared/icons";
import { MaterialType } from "../../interface/api/MaterialType";
import { SetMaterialTypesAction } from "../../interface/redux-types/SetMaterialTypesAction";

const useStyles = makeStyles({
  table: {
    minWidth: 1200,
  },
  iconRow: {
    display: "flex",
    justifyContent: "space-around",
  },
  tableContainer: {
    marginTop: "2rem",
  },
});

const MaterialsView = ({}) => {
  const classes = useStyles();

  const materials = useAppSelector(getMaterials);
  const materialTypes = useAppSelector(getMaterialTypes);
  const dispatch = useAppDispatch();
  const [openDeleteMaterialItem, setOpenDeleteMaterialItem] = useState(false);
  const [openDeleteMaterialTypeItem, setOpenDeleteMaterialTypeItem] =
    useState(false);
  const [selectedMaterialItem, setSelectedMaterialItem] = useState<
    Partial<Material>
  >({});
  const [selectedMaterialTypeItem, setSelectedMaterialTypeItem] = useState<
    Partial<MaterialType>
  >({});

  const onOpenDeleteMaterialItem = () => setOpenDeleteMaterialItem(true);
  const onCloseDeleteMaterialItem = () => setOpenDeleteMaterialItem(false);
  const onOpenDeleteMaterialTypeItem = () =>
    setOpenDeleteMaterialTypeItem(true);
  const onCloseDeleteMaterialTypeItem = () =>
    setOpenDeleteMaterialTypeItem(false);

  useEffect(() => {
    dispatch(clearMaterialType);
    dispatch(clearMaterial);
  }, []);

  useTimeout(() => dispatch(clearMaterial()), 100);

  useMemo(() => {
    scaleService.getMaterials().then((response) => {
      if (response.ok) {
        const materials = response.data as Material[];
        const setMaterialsAction: SetMaterialsAction = {
          materials,
        };
        dispatch(setMaterials(setMaterialsAction));
      }
    });

    scaleService.getMaterialTypes().then((response) => {
      if (response.ok) {
        const materialTypes = response.data as MaterialType[];
        const setMaterialTypesAction: SetMaterialTypesAction = {
          materialTypes,
        };
        dispatch(setMaterialTypes(setMaterialTypesAction));
      }
    });
  }, []);

  const onConfirmDeleteMaterialItem = () => {
    if (selectedMaterialItem && selectedMaterialItem.id) {
      scaleService.deleteMaterial(selectedMaterialItem.id).then((response) => {
        if (response.ok) {
          const setMaterialsAction: SetMaterialsAction = {
            materials: materials.filter(
              (m) => m.id !== selectedMaterialItem.id
            ),
          };
          dispatch(setMaterials(setMaterialsAction));
        }
      });
    }

    onCloseDeleteMaterialItem();
  };

  const onConfirmDeleteMaterialTypeItem = () => {
    if (selectedMaterialTypeItem && selectedMaterialTypeItem.id) {
      scaleService
        .deleteMaterialType(selectedMaterialTypeItem.id)
        .then((response) => {
          if (response.ok) {
            const setMaterialTypesAction: SetMaterialTypesAction = {
              materialTypes: materialTypes.filter(
                (m) => m.id !== selectedMaterialTypeItem.id
              ),
            };
            dispatch(setMaterialTypes(setMaterialTypesAction));
          }
        });
    }

    onCloseDeleteMaterialTypeItem();
  };

  return (
    <>
      <Container maxWidth="xl">
        <PageHero
          title="Materials"
          subtitle="Add and Manage Materials."
          showBackButton={true}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={10}>
            <Typography variant="h4">Materials</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              label="Add Material"
              type="button"
              onClick={() => {
                dispatch(clearMaterial());
                history.push(`/material/new`);
              }}
              variant="contained"
              color="primary"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs>
            {materials && (
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>ID</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {materials.map((material: Material) => {
                      return (
                        <StyledTableRow key={material.id}>
                          <StyledTableCell>{material.id}</StyledTableCell>
                          <StyledTableCell>{material.name}</StyledTableCell>
                          <StyledTableCell>
                            <div className={classes.iconRow}>
                              <div>
                                <EditIcon
                                  onClick={() =>
                                    history.push(`/material/${material.id}`)
                                  }
                                />
                              </div>
                              <div>
                                <DeleteIcon
                                  onClick={() => {
                                    setSelectedMaterialItem(material);
                                    onOpenDeleteMaterialItem();
                                  }}
                                />
                              </div>
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
        <div className={classes.tableContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={10}>
              <Typography variant="h4">Material Types</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                label="Add Material Type"
                type="button"
                onClick={() => {
                  dispatch(clearMaterialType());
                  history.push(`/material-type/new`);
                }}
                variant="contained"
                color="primary"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs>
              {materialTypes && (
                <TableContainer>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {materialTypes.map((materialType) => {
                        return (
                          <StyledTableRow key={materialType.id}>
                            <StyledTableCell>{materialType.id}</StyledTableCell>
                            <StyledTableCell>
                              {materialType.name}
                            </StyledTableCell>
                            <StyledTableCell>
                              <div className={classes.iconRow}>
                                <div>
                                  <EditIcon
                                    onClick={() =>
                                      history.push(
                                        `/material-type/${materialType.id}`
                                      )
                                    }
                                  />
                                </div>
                                <div>
                                  <DeleteIcon
                                    onClick={() => {
                                      setSelectedMaterialTypeItem(materialType);
                                      onOpenDeleteMaterialTypeItem();
                                    }}
                                  />
                                </div>
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
          </Grid>
        </div>
      </Container>
      <DeleteDialog
        open={openDeleteMaterialItem}
        onClose={onCloseDeleteMaterialItem}
        onConfirmDelete={onConfirmDeleteMaterialItem}
      />
      <DeleteDialog
        open={openDeleteMaterialTypeItem}
        onClose={onCloseDeleteMaterialTypeItem}
        onConfirmDelete={onConfirmDeleteMaterialTypeItem}
      />
    </>
  );
};

export default connect()(MaterialsView);
