import { Route } from "react-router";

import Layout from "./components/layout";
import ProtectedRoute from "./components/protected-route";
import LoginView from "./components/login";
import DashboardView from "./components/dashboard";
import Logout from "./components/logout";
import DigitalTicketView from "./components/digital-ticket";
import TicketView from "./components/ticket";
import ContractorsView from "./components/contractors";
import ContractorCompanyView from "./components/contractors/company";
import ContractorContactView from "./components/contractors/contact";
import TransportsView from "./components/transports";
import TransportCompanyView from "./components/transports/company";
import TransportContactView from "./components/transports/contacts/contact";
import TruckView from "./components/transports/trucks/truck";
import UsersView from "./components/users";
import UserView from "./components/users/user";
import ReportsView from "./components/reports/reports-view";
import TicketLogView from "./components/ticket-log";
import HaulageRecordView from "./components/haulage-record";
import PrintRecordView from "./components/print-record/print-record-view";
import ScaleView from "./components/scale";
import MaterialsView from "./components/materials";
import MaterialView from "./components/materials/material";
import MaterialTypeView from "./components/materials/material-type";
import ProjectView from "./components/contractors/project/contractor-project-view";
import ProjectLogView from "./components/project-log/project-log-view";

import "./styles/global.css";

export default () => (
  <Layout>
    <Route exact path="/" component={LoginView} />
    <Route exact path="/login" component={LoginView} />
    <Route path="/logout" component={Logout} />
    <Route path="/ticket/:id" component={TicketView} />
    <Route path="/haulage-record/:id" component={HaulageRecordView} />
    <ProtectedRoute path="/dashboard" component={DashboardView} />
    <ProtectedRoute path="/scale" component={ScaleView} />
    <ProtectedRoute path="/digital-ticket/:id" component={DigitalTicketView} />
    <ProtectedRoute path="/ticket-log/:id" component={TicketLogView} />
    <ProtectedRoute path="/project-log/:id" component={ProjectLogView} />
    <ProtectedRoute path="/print-record/:id" component={PrintRecordView} />
    <ProtectedRoute
      path="/contacts/:contactId/contractor-company/:companyId"
      component={ContractorContactView}
    />
    <ProtectedRoute
      path="/contractor-company/:id"
      component={ContractorCompanyView}
    />
    <ProtectedRoute path="/contractors" component={ContractorsView} />
    <ProtectedRoute
      path="/trucks/:truckId/transport-company/:companyId"
      component={TruckView}
    />
    <ProtectedRoute
      path="/contacts/:contactId/transport-company/:companyId"
      component={TransportContactView}
    />
    <ProtectedRoute
      path="/transport-company/:id"
      component={TransportCompanyView}
    />
    <ProtectedRoute path="/transports" component={TransportsView} />
    <ProtectedRoute path="/user/:id" component={UserView} />
    <ProtectedRoute path="/users" component={UsersView} />
    <ProtectedRoute path="/material/:id" component={MaterialView} />
    <ProtectedRoute path="/materials" component={MaterialsView} />
    <ProtectedRoute path="/material-type/:id" component={MaterialTypeView} />
    <ProtectedRoute path="/reports" component={ReportsView} />
    <ProtectedRoute
      path="/projects/:id/contractor-company/:companyId"
      component={ProjectView}
    />
  </Layout>
);
