import { Formik } from "formik";
import { useMemo } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Container, Grid } from "@material-ui/core";

import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { history } from "../../../..";
import {
  getTransportCompany,
  getTransportCompanyContact,
  setTransportCompany,
  setTransportCompanyContact,
} from "../../../../store/transportStore";
import transportContactService from "../../../../services/transportContactService";
import { TransportContact } from "../../../../interface/api/TransportContact";
import { SetTransportContactAction } from "../../../../interface/redux-types/SetTransportContactAction";
import transportCompanyService from "../../../../services/transportCompanyService";
import { TransportCompany } from "../../../../interface/api/TransportCompany";
import { SetTransportCompanyAction } from "../../../../interface/redux-types/SetTransportCompanyAction";
import { TransportContactFormValues } from "../../../../interface/formik/TransportContactFormValues";
import { TransportPayload } from "../../../../interface/api/TransportPayload";
import { TransportContactFormSchema } from "../../schema/transport-contact-form-schema";
import PageHero from "../../../shared/page-hero";
import FormErrorMessage from "../../../shared/form-error-message";
import FormTextField from "../../../shared/form-text-field";
import PhoneInput from "../../../shared/phone-input";
import Button from "../../../shared/Button";

const TransportContactView = ({}) => {
  const { contactId, companyId }: any = useParams();
  const dispatch = useAppDispatch();
  const transportContact = useAppSelector(getTransportCompanyContact);
  const transportCompany = useAppSelector(getTransportCompany);

  useMemo(() => {
    if (contactId > 0) {
      transportContactService
        .getTransportContact(contactId)
        .then((response) => {
          if (response.ok) {
            const transportContact = response.data as TransportContact;
            const setTransportContactAction: SetTransportContactAction = {
              transportContact: transportContact,
            };
            dispatch(setTransportCompanyContact(setTransportContactAction));
          } else {
            toast.error(response.originalError.message);
          }
        });
    }

    if (companyId > 0) {
      transportCompanyService
        .getTransportCompany(companyId)
        .then((response) => {
          if (response.ok) {
            const transportCompany = response.data as TransportCompany;
            const setTransportCompanyAction: SetTransportCompanyAction = {
              transportCompany: transportCompany,
            };

            dispatch(setTransportCompany(setTransportCompanyAction));
          } else {
            toast.error(response.originalError.message);
          }
        });
    }
  }, []);

  const onSubmit = (
    values: TransportContactFormValues,
    setSubmitting: Function
  ) => {
    const transportPayload: TransportPayload = {
      transportCompanyId: values.transportCompanyId,
      transportContact: values as TransportContact,
      truck: null,
    };

    transportContactService
      .saveTransportContact(transportPayload)
      .then((response) => {
        if (response.ok) {
          history.goBack();
        } else {
          const data = response.data as string;
          toast.error(data);
          setSubmitting(false);
        }
      });

    setSubmitting(false);
  };

  const initialValues: TransportContactFormValues = transportContact
    ? transportContact
    : {
        id: 0,
        transportCompanyId: transportCompany ? transportCompany.id : 0,
        transportCompanyName: transportCompany ? transportCompany.name : "",
        name: "",
        number: "",
      };

  return (
    <Container maxWidth="xl">
      <PageHero
        title={
          contactId > 0
            ? `${transportContact ? transportContact.name : contactId}`
            : "New Transport Contact"
        }
        subtitle={contactId > 0 ? `Edit contact.` : "Create a new contact."}
        showBackButton={true}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={TransportContactFormSchema}
        onSubmit={(values, { setSubmitting }) =>
          onSubmit(values, setSubmitting)
        }
        enableReinitialize={true}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <FormTextField
                  name="transportCompanyName"
                  value={values.transportCompanyName}
                  disabled={true}
                  label={"Transport Company Name"}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormTextField name="name" value={values.name} label={"Name"} />
                <FormErrorMessage name="name" />
              </Grid>
              <Grid item xs={12} md={3}>
                <PhoneInput
                  name="number"
                  label={"Number"}
                  value={values.number}
                />
                <FormErrorMessage name="number" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <Button
                  label="Save"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default connect()(TransportContactView);
