import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";

import { FormDateTimePickerProps } from "../../interface/formik/FormDateTimePickerProps";

const FormDatePicker = ({
  value,
  label,
  onChange,
  clearable,
  minDate,
  readOnly,
}: FormDateTimePickerProps) => {
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          inputVariant="outlined"
          value={value}
          onChange={onChange}
          label={label}
          clearable={clearable}
          minDate={minDate}
          readOnly={readOnly}
          fullWidth
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default FormDatePicker;
