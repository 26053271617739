import {
  Typography,
  MenuItem,
  TextareaAutosize,
  Grid,
} from "@material-ui/core";
import { useFormikContext } from "formik";

import { TicketFormValues } from "../../../interface/formik/TicketFormValues";
import FormErrorMessage from "../../shared/form-error-message";
import FormSelect from "../../shared/form-select";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getUsers, setUsers } from "../../../store/userStore";
import FormDateTimePicker from "../../shared/form-date-time-picker";
import { useMemo } from "react";
import { User } from "../../../interface/api/User";
import { SetUsersAction } from "../../../interface/redux-types/SetUsersAction";
import userService from "../../../services/userService";

const UnitedSoilsInformation = () => {
  const users = useAppSelector(getUsers);
  const dispatch = useAppDispatch();

  const { handleChange, values, setFieldValue } =
    useFormikContext<TicketFormValues>();

  useMemo(() => {
    userService.getUsers().then((response) => {
      if (response.ok) {
        const users = response.data as User[];
        const setUsersAction: SetUsersAction = {
          users,
        };
        dispatch(setUsers(setUsersAction));
      }
    });
  }, []);

  return (
    <>
      <Typography style={{ marginBottom: 30 }} variant="h5">
        United Soils Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <FormSelect
            name="userId"
            label={"Receiving Contact"}
            value={values.userId}
            items={[
              users.map((user, index) => (
                <MenuItem key={index} value={user.id}>
                  {user.firstName} {user.lastName}
                </MenuItem>
              )),
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ marginBottom: 30 }}>
          <FormDateTimePicker
            label="Time Received"
            value={values.timeReceived ? new Date(values.timeReceived) : null}
            onChange={(date: any) => setFieldValue("timeReceived", date)}
            minDate={values.timeLoaded ? values.timeLoaded : new Date()}
          />
          <FormErrorMessage name="timeReceived" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8} lg={4}>
          <TextareaAutosize
            id="notes"
            name="notes"
            placeholder="Please enter ticket notes here..."
            onChange={handleChange}
            value={values.notes ? values.notes : ""}
            aria-label="notes"
            minRows={5}
            style={{ width: "100%", padding: "12px" }}
          />
          <FormErrorMessage name="notes" />
        </Grid>
      </Grid>
    </>
  );
};

export default UnitedSoilsInformation;
