import {
  Dialog,
  Typography,
  Grid,
  Toolbar,
  AppBar,
  IconButton,
  List,
  Divider,
  ListItem,
  makeStyles,
} from "@material-ui/core";
import { IoIosClose } from "react-icons/io";
import { useEffect } from "react";
import { Formik, useFormikContext } from "formik";

import { history } from "../../..";
import { ReceiveDialogProps } from "../../../interface/dialog/ReceiveDialogProps";
import { ReceiveIncompleteTicketFormValues } from "../../../interface/formik/ReceiveIncompleteTicketFormValues";
import FormTextField from "../form-text-field";
import FormTruckLicensePlateField from "../form-truck-license-plate-field";
import FormTextReadOnlyField from "../form-text-read-only-field";
import Button from "../Button";
import useFocusSearch from "../../../hooks/useFocusSearch";

const useStyles = makeStyles({
  link: {
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
  },
  divider: { marginTop: "5px", marginBottom: "15px" },
  appBar: {
    position: "relative",
  },
  appBarTitle: {
    flex: 1,
  },
});

const ReceiveDialog = ({
  open,
  onClose,
  selectedTicket,
  onReceiveTicket,
  trucks,
}: ReceiveDialogProps) => {
  const classes = useStyles();
  const { doFocusSearchInput } = useFocusSearch("ticketNumber");

  const receiveIncompleteTicketFormValues: ReceiveIncompleteTicketFormValues = {
    jobSite: selectedTicket ? selectedTicket.jobSite : "",
    siteContactName: selectedTicket ? selectedTicket.siteContactName : "",
    transportCompanyName: selectedTicket
      ? selectedTicket.transportCompanyName
      : "",
    truckDriverName: selectedTicket ? selectedTicket.truckDriverName : "",
    truckLicensePlate: selectedTicket
      ? selectedTicket.truckLicensePlate?.trim()?.replace(" ", "")
      : "",
    truckNumber: selectedTicket ? selectedTicket.truckNumber : "",
  };

  const TicketCompleteView = () => {
    if (!selectedTicket) return <></>;
    return (
      <>
        <ListItem>
          <span>
            Ticket is complete. If you need to edit the ticket data please click{" "}
            <span
              onClick={() => {
                if (selectedTicket) {
                  history.push(`/ticket/${selectedTicket.id}`);
                }
              }}
              className={classes.link}
            >
              here
            </span>
            .
          </span>
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem>
          <Grid item xs={12} md={4}>
            <FormTextReadOnlyField
              label={"Job Site"}
              value={selectedTicket.jobSite}
            />
          </Grid>
        </ListItem>
        <ListItem>
          <Grid item xs={12} md={4}>
            <FormTextReadOnlyField
              label={"Contractor"}
              value={selectedTicket.contractorCompanyName}
            />
          </Grid>
        </ListItem>
        <ListItem>
          <Grid item xs={12} md={4}>
            <FormTextReadOnlyField
              label={"Haulage Co."}
              value={selectedTicket.transportCompanyName}
            />
          </Grid>
        </ListItem>
        <ListItem>
          <Grid item xs={12} md={4}>
            <FormTextReadOnlyField
              label={"License Plate"}
              value={selectedTicket.truckLicensePlate}
            />
          </Grid>
        </ListItem>
        <ListItem>
          <Grid item xs={12} md={4}>
            <FormTextReadOnlyField
              label={"Driver Name"}
              value={selectedTicket.truckDriverName}
            />
          </Grid>
        </ListItem>
        <ListItem>
          <Grid item xs={12} md={4}>
            <FormTextReadOnlyField
              label={"Truck Number"}
              value={selectedTicket.truckNumber}
            />
          </Grid>
        </ListItem>
      </>
    );
  };

  const TicketIncompleteView = () => {
    if (!selectedTicket) return <></>;

    const { values } = useFormikContext<ReceiveIncompleteTicketFormValues>();
    return (
      <>
        <ListItem>
          Ticket is missing required information please review the ticket data
          below. You can still receive the ticket with missing information.
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name={"jobSite"}
                  label={"Job Site"}
                  value={values.jobSite}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name={"siteContactName"}
                  label={"Site Contact"}
                  value={values.siteContactName}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name={"transportCompanyName"}
                  label={"Haulage Co."}
                  value={values.transportCompanyName}
                  disabled={
                    selectedTicket &&
                    Number(selectedTicket.transportCompanyId) > 0
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTruckLicensePlateField
                  value={values.truckLicensePlate}
                  name={"truckLicensePlate"}
                  label={"Truck License Plate"}
                  placeholder={null}
                  disabled={
                    selectedTicket && Number(selectedTicket.truckId) > 0
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  value={values.truckNumber}
                  name={"truckNumber"}
                  label={"Truck Number"}
                  disabled={
                    selectedTicket && Number(selectedTicket.truckId) > 0
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name={"truckDriverName"}
                  label={"Driver Name"}
                  value={values.truckDriverName}
                />
              </Grid>
            </Grid>
          </form>
        </ListItem>
      </>
    );
  };

  const TicketBody = () => {
    const { values, setFieldValue } =
      useFormikContext<ReceiveIncompleteTicketFormValues>();

    useEffect(() => {
      if (!values.truckLicensePlate) return;

      if (
        !values.truckLicensePlate?.includes("_") &&
        values.truckLicensePlate?.length >= 7
      ) {
        const truck = trucks.find(
          (t) =>
            t.licensePlate &&
            t.licensePlate.trim().toLocaleLowerCase() ===
              values.truckLicensePlate.trim().toLocaleLowerCase()
        );
        if (truck) {
          setFieldValue("truckNumber", truck?.number);
          setFieldValue("truckDriverName", truck?.driverName);
          setFieldValue("transportCompanyName", truck?.transportCompanyName);
        }
      }
    }, [values.truckLicensePlate]);

    return (
      <>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => onClose()}
              aria-label="close"
            >
              <IoIosClose />
            </IconButton>
            <Typography
              className={classes.appBarTitle}
              variant="h6"
              component="div"
            >
              Receive Ticket
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          {selectedTicket && selectedTicket.isComplete ? (
            <TicketCompleteView />
          ) : (
            <TicketIncompleteView />
          )}
          <ListItem>
            <Grid item xs={12} md={4}>
              <Button
                label="Yes"
                onClick={() => {
                  if (selectedTicket && !selectedTicket.isComplete) {
                    onReceiveTicket(values);
                  } else {
                    onReceiveTicket();
                  }
                  doFocusSearchInput();
                }}
                autoFocus
                color="primary"
                variant="contained"
                type="button"
              />
            </Grid>
          </ListItem>
        </List>
      </>
    );
  };

  return (
    <Dialog fullScreen open={open} onClose={() => onClose()}>
      <Formik
        initialValues={receiveIncompleteTicketFormValues}
        onSubmit={() => {}}
      >
        <TicketBody />
      </Formik>
    </Dialog>
  );
};

export default ReceiveDialog;
