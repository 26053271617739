import { makeStyles, Typography } from "@material-ui/core";
import { ErrorMessage } from "formik";

import { FormErrorMessageProps } from "../../interface/formik/FormErrorMessageProps";

const useStyles = makeStyles({
  errorMessage: {
    marginTop: "6px",
  },
});

const FormErrorMessage = ({ name }: FormErrorMessageProps) => {
  const classes = useStyles();
  return (
    <ErrorMessage name={name}>
      {(msg) => (
        <Typography
          color="error"
          variant="subtitle2"
          className={classes.errorMessage}
        >
          * {msg}
        </Typography>
      )}
    </ErrorMessage>
  );
};

export default FormErrorMessage;
