import http from "./httpService";
import auth from "./authService";
import { TransportPayload } from "../interface/api/TransportPayload";
import { MoveTrucksPayload } from "../interface/api/MoveTrucksPayload";
import { TareWeightWithTicketPayload } from "../interface/api/TareWeightWithTicketPayload";

http.setJwt(auth.getJwt());

export const deleteTruck = (id: number | string) => {
  return http.delete(`/api/truck/${id}`);
};

export const getTruck = (id: number | string) => {
  return http.get(`/api/truck/${id}`);
};

export const getTrucks = (transportPayload: TransportPayload) => {
  return http.post("/api/truck/all", transportPayload);
};

export const updateTareWeight = (transportPayload: TransportPayload) => {
  return http.put("/api/truck/tare", transportPayload);
};

export function saveTruck(transportPayload: TransportPayload) {
  const truck = transportPayload.truck;
  if (truck && truck.id > 0) {
    return http.put("/api/truck/edit", transportPayload);
  }
  return http.post("/api/truck/new", transportPayload);
}

export const moveTrucks = (moveTrucksPayload: MoveTrucksPayload) => {
  return http.post("/api/truck/move", moveTrucksPayload);
};

export const tareWeightWithTicket = (
  tareWeightWithTicketPayload: TareWeightWithTicketPayload
) => {
  return http.post("/api/truck/tare/weight", tareWeightWithTicketPayload);
};

export default {
  deleteTruck,
  getTruck,
  getTrucks,
  updateTareWeight,
  saveTruck,
  moveTrucks,
  tareWeightWithTicket,
};
