export const timeFormat = "h:mm aa";

export const isDateGreaterThanEqualTo = (
  firstDate: Date | null | undefined,
  secondDate: Date | null | undefined
) => {
  return (
    firstDate &&
    secondDate &&
    new Date(firstDate).setHours(0, 0, 0, 0) >=
      new Date(secondDate).setHours(0, 0, 0, 0)
  );
};

export const isDateEqualTo = (
  firstDate: Date | null | undefined,
  secondDate: Date | null | undefined
) => {
  return (
    firstDate &&
    secondDate &&
    new Date(firstDate).setHours(0, 0, 0, 0) ===
      new Date(secondDate).setHours(0, 0, 0, 0)
  );
};

export const isDateLessThanEqualTo = (
  firstDate: Date | null | undefined,
  secondDate: Date | null | undefined
) => {
  return (
    firstDate &&
    secondDate &&
    new Date(firstDate).setHours(0, 0, 0, 0) <=
      new Date(secondDate).setHours(0, 0, 0, 0)
  );
};

export const formatDateTime = (date: Date | null, isZeroTimezone: boolean) => {
  if (!date) return "";

  return `${formatDate(date, isZeroTimezone)} ${formatTime(
    date,
    isZeroTimezone
  )}`;
};

export const formatDate = (date: Date | null, isZeroTimezone: boolean) => {
  if (!date) return "";
  if (isZeroTimezone) {
    return new Date(`${date}Z`).toLocaleDateString("en-ZA");
  }
  return new Date(date).toLocaleDateString("en-ZA");
};

export const formatTime = (date: Date | null, isZeroTimezone: boolean) => {
  if (!date) return "";

  if (isZeroTimezone) {
    return new Date(`${date}Z`).toLocaleTimeString();
  }
  return new Date(date).toLocaleTimeString();
};

export const removeSeconds = (date: Date | null) => {
  if (!date) return null;
  let parsedDate = new Date(date);
  parsedDate.setSeconds(0, 0);
  return parsedDate;
};
