import { useMemo } from "react";

import { logout } from "../services/authService";

const Logout = () => {
  useMemo(() => {
    logout();
    window.location.href = "/";
  }, []);

  return null;
};

export default Logout;
