import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { history } from "../..";
import {
  clearTransportCompany,
  clearTransportCompanyContacts,
  clearTransportCompanyTrucks,
  getTransportCompanies,
  getTrucks,
  setTransportCompanies,
  setTrucks,
} from "../../store/transportStore";
import transportCompanyService from "../../services/transportCompanyService";
import { TransportCompany } from "../../interface/api/TransportCompany";
import { SetTransportCompaniesAction } from "../../interface/redux-types/SetTransportCompaniesAction";
import PageHero from "../shared/page-hero";
import DeleteDialog from "../shared/dialog/delete";
import { StyledTableCell } from "../shared/styled-table-cell";
import { StyledTableRow } from "../shared/styled-table-row";
import useTimeout from "../../hooks/useTimeout";
import Button from "../shared/Button";
import { DeleteIcon, EditIcon, MoveIcon } from "../shared/icons";
import MoveTrucksDialog from "../shared/dialog/move-trucks";
import truckService from "../../services/truckService";
import { MoveTrucksPayload } from "../../interface/api/MoveTrucksPayload";
import { toast } from "react-toastify";
import { Truck } from "../../interface/api/Truck";
import { SetTrucksAction } from "../../interface/redux-types/SetTrucksAction";
import { TransportPayload } from "../../interface/api/TransportPayload";

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
  iconRow: {
    display: "flex",
    justifyContent: "space-around",
    minWidth: "150px",
  },
});

const TransportsView = ({}) => {
  const classes = useStyles();
  const transportCompanies = useAppSelector(getTransportCompanies);
  const trucks = useAppSelector(getTrucks);
  const dispatch = useAppDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openMoveTrucksDialog, setOpenMoveTrucksDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Partial<TransportCompany>>(
    {}
  );
  const [exporting, setExporting] = useState(false);

  useTimeout(() => dispatch(clearTransportCompany()), 100);

  useEffect(() => {
    return () => {
      dispatch(clearTransportCompanyContacts());
      dispatch(clearTransportCompanyTrucks());
    };
  }, []);

  useMemo(() => {
    transportCompanyService.getTransportCompanies().then((response) => {
      if (response.ok) {
        const transportCompanies = response.data as TransportCompany[];
        const setTransportCompaniesAction: SetTransportCompaniesAction = {
          transportCompanies,
        };
        dispatch(setTransportCompanies(setTransportCompaniesAction));
      }
    });

    const transportPayload: TransportPayload = {
      transportCompanyId: null,
      truck: null,
      transportContact: null,
    };

    truckService.getTrucks(transportPayload).then((response) => {
      if (response.ok) {
        const trucks = response.data as Truck[];
        const setTrucksAction: SetTrucksAction = {
          trucks,
        };
        dispatch(setTrucks(setTrucksAction));
      }
    });
  }, []);

  const onConfirmDelete = () => {
    if (selectedItem && selectedItem.id) {
      transportCompanyService
        .deleteTransportCompany(selectedItem.id)
        .then((response) => {
          if (response.ok) {
            const setTransportCompaniesAction: SetTransportCompaniesAction = {
              transportCompanies: transportCompanies.filter(
                (t) => t.id !== selectedItem.id
              ),
            };
            dispatch(setTransportCompanies(setTransportCompaniesAction));
          }
        });
    }

    setOpenDeleteDialog(false);
  };

  const onMoveTrucks = (transportCompanyId: number) => {
    if (!selectedItem?.id) return;

    const moveTrucksPayload: MoveTrucksPayload = {
      fromTransportCompanyId: selectedItem.id,
      toTransportCompanyId: transportCompanyId,
    };

    truckService.moveTrucks(moveTrucksPayload).then((response) => {
      if (!response.ok) {
        toast.error("Failed to move trucks");
      }
    });

    setOpenMoveTrucksDialog(false);
  };

  const onExport = () => {
    setExporting(true);
    let csvData: any[] = [
      {
        totalTransportCompanies:
          transportCompanies && transportCompanies.length,
      },
    ];
    transportCompanies.forEach((transportCompany: TransportCompany) => {
      csvData.push({
        transportCompanyName: transportCompany.name,
      });

      const transportCompanyTrucks = trucks.filter(
        (t) => t.transportCompanyId === transportCompany.id
      );

      transportCompanyTrucks.forEach((truck: Truck) => {
        csvData.push({
          truckNumber: truck.number,
          licensePlate: truck.licensePlate,
          driverName: truck.driverName,
          driverNumber: truck.driverNumber,
          tareWeight: truck.tareWeight,
        });
      });
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `transport-companies-export` + fileExtension);

    setTimeout(() => setExporting(false), 1500);
  };

  return (
    <>
      <Container maxWidth="xl">
        <PageHero
          title="Transports"
          subtitle="Add and manage Transport Companies"
          showBackButton={false}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Button
              label="Add Transport Company"
              type="button"
              onClick={() => history.push(`/transport-company/new`)}
              variant="contained"
              color="primary"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              label={exporting ? "Exporting..." : "Export Transport Companies"}
              type="button"
              onClick={() => onExport()}
              variant="contained"
              color="default"
              disabled={exporting}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs>
            {transportCompanies && (
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>ID</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transportCompanies.map(
                      (transportCompany: TransportCompany) => {
                        return (
                          <StyledTableRow key={transportCompany.id}>
                            <StyledTableCell>
                              {transportCompany.id}
                            </StyledTableCell>
                            <StyledTableCell>
                              {transportCompany.name}
                            </StyledTableCell>
                            <StyledTableCell>
                              <div className={classes.iconRow}>
                                <div>
                                  <MoveIcon
                                    onClick={() => {
                                      setSelectedItem(transportCompany);
                                      setOpenMoveTrucksDialog(true);
                                    }}
                                  />
                                </div>
                                <div>
                                  <EditIcon
                                    onClick={() =>
                                      history.push(
                                        `/transport-company/${transportCompany.id}`
                                      )
                                    }
                                  />
                                </div>
                                <div>
                                  <DeleteIcon
                                    onClick={() => {
                                      setSelectedItem(transportCompany);
                                      setOpenDeleteDialog(true);
                                    }}
                                  />
                                </div>
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </Container>
      <DeleteDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirmDelete={onConfirmDelete}
      />
      <MoveTrucksDialog
        open={openMoveTrucksDialog}
        transportCompanies={transportCompanies}
        onClose={() => setOpenMoveTrucksDialog(false)}
        onConfirm={onMoveTrucks}
        fromTransportCompanyName={selectedItem.name ?? ""}
      />
    </>
  );
};

export default connect()(TransportsView);
