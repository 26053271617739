import { makeStyles } from "@material-ui/core";
import { useLocation } from "react-router";
import { LayoutProps } from "../interface/LayoutProps";

import NavMenu from "./shared/nav-menu";

const useStyles = makeStyles({
  footer: {
    marginTop: 40,
  },
});

const Layout = ({ children }: LayoutProps) => {
  const classes = useStyles();
  const location = useLocation();

  if (location.pathname.includes("print-record")) {
    return <>{children}</>;
  }

  return (
    <>
      <NavMenu />
      {children}
      <footer className={classes.footer} />
    </>
  );
};

export default Layout;
