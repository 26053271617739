import http from "./httpService";
import auth from "./authService";
import { ScalePayload } from "../interface/api/ScalePayload";
import { Material } from "../interface/api/Material";
import { MaterialType } from "../interface/api/MaterialType";

http.setJwt(auth.getJwt());

export const getScales = () => {
  return http.get("/api/scale/all");
};

export const getScale = (id: number | string) => {
  return http.get(`/api/scale/${id}`);
};

export const scaleWeightStart = (scalePayload: ScalePayload) => {
  return http.post("/api/scale/weigh/start", scalePayload);
};

export const scaleWeightStop = (scalePayload: ScalePayload) => {
  return http.post("/api/scale/weigh/stop", scalePayload);
};

export const deleteMaterial = (id: number | string) => {
  return http.delete(`/api/scale/material/${id}`);
};

export const getMaterial = (id: number | string) => {
  return http.get(`/api/scale/material/${id}`);
};

export const getMaterials = () => {
  return http.get("/api/scale/material/all");
};

export function saveMaterial(material: Material) {
  if (material && material.id > 0) {
    return http.put("/api/scale/material/edit", material);
  }
  return http.post("/api/scale/material/new", material);
}

export const deleteMaterialType = (id: number | string) => {
  return http.delete(`/api/scale/material/type/${id}`);
};

export const getMaterialType = (id: number | string) => {
  return http.get(`/api/scale/material/type/${id}`);
};

export const getMaterialTypes = () => {
  return http.get("/api/scale/material/type/all");
};

export function saveMaterialType(materialType: MaterialType) {
  if (materialType && materialType.id > 0) {
    return http.put("/api/scale/material/type/edit", materialType);
  }
  return http.post("/api/scale/material/type/new", materialType);
}

export default {
  getScales,
  getScale,
  scaleWeightStart,
  scaleWeightStop,
  deleteMaterial,
  getMaterial,
  getMaterials,
  saveMaterial,
  deleteMaterialType,
  getMaterialType,
  getMaterialTypes,
  saveMaterialType,
};
