import TextField from "@material-ui/core/TextField";

import { InputAdornment } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { WeightFieldProps } from "../../interface/formik/WeightFieldProps";

const WeightField = ({
  name,
  label,
  value,
  weighing,
  onChange,
}: WeightFieldProps) => {
  return (
    <>
      <TextField
        id={name}
        name={name}
        label={label}
        value={value}
        InputProps={{
          startAdornment: (
            <FiberManualRecordIcon htmlColor={weighing ? "green" : "black"} />
          ),
          endAdornment: <InputAdornment position="end">KG</InputAdornment>,
        }}
        variant="outlined"
        fullWidth
        onChange={(e) => onChange?.(e.target.value)}
      />
    </>
  );
};

export default WeightField;
