import { Formik } from "formik";
import { useMemo } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Container, Grid, FormControlLabel, Checkbox } from "@material-ui/core";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ContractorCompany } from "../../../interface/api/ContractorCompany";
import { ContractorContact } from "../../../interface/api/ContractorContact";
import { ContractorPayload } from "../../../interface/api/ContractorPayload";
import { ContractorContactFormValues } from "../../../interface/formik/ContractorContactFormValues";
import { SetContractorCompanyAction } from "../../../interface/redux-types/SetContractorCompanyAction";
import { SetContractorContactAction } from "../../../interface/redux-types/SetContractorContactAction";
import contractorCompanyService from "../../../services/contractorCompanyService";
import contractorContactService from "../../../services/contractorContactService";
import {
  getContractorCompany,
  getContractorCompanyContact,
  setContractorCompany,
  setContractorCompanyContact,
} from "../../../store/contractorStore";
import { ContractorContactFormSchema } from "../schema/contractor-contact-form-schema";
import { history } from "../../..";
import PageHero from "../../shared/page-hero";
import FormErrorMessage from "../../shared/form-error-message";
import FormTextField from "../../shared/form-text-field";
import PhoneInput from "../../shared/phone-input";
import Button from "../../shared/Button";

const ContractorContactView = ({}) => {
  const { contactId, companyId }: any = useParams();
  const dispatch = useAppDispatch();
  const contractorContact = useAppSelector(getContractorCompanyContact);
  const contractorCompany = useAppSelector(getContractorCompany);

  useMemo(() => {
    if (contactId > 0) {
      contractorContactService
        .getContractorContact(contactId)
        .then((response) => {
          if (response.ok) {
            const contractorContact = response.data as ContractorContact;
            const setContractorContactAction: SetContractorContactAction = {
              contractorContact: contractorContact,
            };
            dispatch(setContractorCompanyContact(setContractorContactAction));
          } else {
            toast.error(response.originalError.message);
          }
        });
    }

    if (companyId > 0) {
      contractorCompanyService
        .getContractorCompany(companyId)
        .then((response) => {
          if (response.ok) {
            const contractorCompany = response.data as ContractorCompany;
            const setContractorCompanyAction: SetContractorCompanyAction = {
              contractorCompany: contractorCompany,
            };

            dispatch(setContractorCompany(setContractorCompanyAction));
          } else {
            toast.error(response.originalError.message);
          }
        });
    }
  }, []);

  const onSubmit = (
    values: ContractorContactFormValues,
    setSubmitting: Function
  ) => {
    const contractorPayload: ContractorPayload = {
      contractorCompanyId: values.contractorCompanyId,
      contractorContact: values as ContractorContact,
    };

    contractorContactService
      .saveContractorContact(contractorPayload)
      .then((response) => {
        if (response.ok) {
          history.goBack();
        } else {
          const data = response.data as string;
          toast.error(data);
          setSubmitting(false);
        }
      });

    setSubmitting(false);
  };

  const initialValues: ContractorContactFormValues = contractorContact
    ? contractorContact
    : {
        id: 0,
        contractorCompanyId: contractorCompany ? contractorCompany.id : 0,
        contractorCompanyName: contractorCompany ? contractorCompany.name : "",
        name: "",
        number: "",
        isOfficeContact: false,
        isSiteContact: false,
        email: "",
      };

  return (
    <Container maxWidth="xl">
      <PageHero
        title={
          contactId > 0
            ? `${contractorContact ? contractorContact.name : contactId}`
            : "New Contractor Contact"
        }
        subtitle={contactId > 0 ? `Edit contact.` : "Create a new contact."}
        showBackButton={true}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={ContractorContactFormSchema}
        onSubmit={(values, { setSubmitting }) =>
          onSubmit(values, setSubmitting)
        }
        enableReinitialize={true}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name={"contractorCompanyName"}
                  label={""}
                  value={values.contractorCompanyName}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name={"name"}
                  label={"Name"}
                  value={values.name}
                />
                <FormErrorMessage name="name" />
              </Grid>
              <Grid item xs={12} md={4}>
                <PhoneInput
                  name={"number"}
                  label={"Number"}
                  value={values.number}
                />
                <FormErrorMessage name="number" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name={"email"}
                  label={"Email"}
                  value={values.email}
                />
                <FormErrorMessage name="email" />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.isOfficeContact}
                      onChange={() =>
                        setFieldValue(
                          "isOfficeContact",
                          !values.isOfficeContact
                        )
                      }
                    />
                  }
                  label="Office Contact"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.isSiteContact}
                      onChange={() =>
                        setFieldValue("isSiteContact", !values.isSiteContact)
                      }
                    />
                  }
                  label="Site Contact"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  label="Save"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default connect()(ContractorContactView);
