import { Grid, MenuItem } from "@material-ui/core";
import { useFormikContext } from "formik";

import FormErrorMessage from "../shared/form-error-message";
import { ScaleFormProps } from "../../interface/forms/ScaleFormProps";
import scaleService from "../../services/scaleService";
import { ScalePayload } from "../../interface/api/ScalePayload";
import { TruckScaleFormValues } from "../../interface/formik/ScaleFormValues";
import { useAppSelector } from "../../hooks";
import { getMaterials, getScales } from "../../store/scaleStore";
import FormSelect from "../shared/form-select";
import WeightField from "../shared/weight-field";
import FormSelectSearchField from "../shared/form-select-search-field";
import { getTodaysTickets } from "../../store/ticketStore";
import { useMemo } from "react";
import { getTrucks } from "../../store/transportStore";
import { Scale } from "../../interface/api/Scale";
import { Material } from "../../interface/api/Material";
import InputSubtitle from "../shared/input-subtitle";
import TicketDetails from "./details/ticket-details";
import TruckDetails from "./details/truck-details";
import Button from "../shared/Button";

const TruckScale = ({
  weighing,
  setWeighing,
  weight,
  isWeighIn,
  getNetWeight,
  onWeightChange,
  setTareTruckWithTicket,
}: ScaleFormProps) => {
  const scales = useAppSelector(getScales);
  const tickets = useAppSelector(getTodaysTickets);
  const trucks = useAppSelector(getTrucks);
  const materials = useAppSelector(getMaterials);

  const { values, setFieldValue, handleSubmit } =
    useFormikContext<TruckScaleFormValues>();

  const getTicketsData = useMemo(() => {
    if (!tickets) return;
    return tickets.map((t) => {
      return {
        value: t.id,
        label: t.ticketNumber,
      };
    });
  }, [tickets]);

  const getTrucksData = useMemo(() => {
    if (!trucks) return;
    return trucks.map((t) => {
      return {
        value: t.id,
        label: t.licensePlate
          ? t.licensePlate
          : `${t.number} / ${t.transportCompanyName}`,
      };
    });
  }, [trucks]);

  const toggleWeighing = () => {
    const _weighing = !weighing;

    setWeighing(_weighing);

    const scalePayload: ScalePayload = {
      scaleId: values.scaleId,
    };
    if (_weighing) {
      scaleService.scaleWeightStop(scalePayload);
    } else {
      scaleService.scaleWeightStart(scalePayload);
    }
  };

  const getTicket = (values: TruckScaleFormValues) => {
    if (values.ticket) {
      return {
        value: values.ticket.id,
        label: values.ticket.ticketNumber,
      };
    }
    return null;
  };

  const getTruck = () => {
    const { truck } = values;
    if (truck) {
      return {
        value: truck.id,
        label: truck.licensePlate
          ? truck.licensePlate
          : `${truck.number} / ${truck.transportCompanyName}`,
      };
    }
    return null;
  };

  const onTicketNumberChange = (state: any, { action }: any) => {
    const ticketId = action === "clear" ? 0 : state.value;
    if (ticketId > 0) {
      const ticket = tickets.find((t) => t.id === ticketId);
      const truck = trucks.find((t) => t.id === ticket?.truckId);
      setFieldValue("truck", truck);
      setFieldValue("ticket", ticket);
    } else {
      setFieldValue("truck", null);
      setFieldValue("ticket", null);
    }
  };

  const onTruckChange = (state: any, { action }: any) => {
    const truckId = action === "clear" ? 0 : state.value;
    setFieldValue("truckId", truckId);

    if (truckId > 0) {
      const truck = trucks.find((t) => t.id === truckId);
      setFieldValue("truck", truck);
    } else {
      setFieldValue("truck", null);
    }
  };

  const isWeighInDisabled = () => {
    return isWeighIn() ? values.ticket === null : values.truck === null;
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {scales && scales.length > 0 && (
            <Grid item xs={12} md={4}>
              <FormSelect
                name={"scaleId"}
                label={"Scales"}
                value={values.scaleId}
                items={[
                  scales.map((scale: Scale) => (
                    <MenuItem key={scale.id} value={scale.id}>
                      {scale.scaleName}
                    </MenuItem>
                  )),
                ]}
              />
              <FormErrorMessage name={"scaleId"} />
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            {isWeighIn() ? (
              <>
                <FormSelectSearchField
                  name="ticketId"
                  value={getTicket(values)}
                  options={getTicketsData}
                  onChange={onTicketNumberChange}
                  placeholder="Ticket Number"
                  isClearable={true}
                />
                <InputSubtitle text={"Please select a ticket #"} />
                <FormErrorMessage name="ticketId" />
              </>
            ) : (
              <>
                <FormSelectSearchField
                  name={"truckId"}
                  value={getTruck()}
                  options={getTrucksData}
                  onChange={onTruckChange}
                  placeholder="Truck"
                  isClearable={true}
                />
                <InputSubtitle text={"Please select a truck"} />
                <FormErrorMessage name="truckId" />
              </>
            )}
          </Grid>
          {isWeighIn() && materials && materials.length > 0 && (
            <Grid item xs={12} md={4}>
              <FormSelect
                name={"materialId"}
                label={"Material"}
                value={values.materialId}
                items={[
                  materials.map((material: Material) => (
                    <MenuItem key={material.id} value={material.id}>
                      {material.name}
                    </MenuItem>
                  )),
                ]}
              />
              <FormErrorMessage name="materialId" />
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <WeightField
              label={"Scale Weight"}
              value={weight ? weight : 0}
              weighing={weighing}
              onChange={onWeightChange}
            />
          </Grid>
          {isWeighIn() && (
            <Grid item xs={12} md={4}>
              <WeightField
                label={"NET Weight"}
                value={
                  getNetWeight(values.truck) ? getNetWeight(values.truck) : 0
                }
                weighing={weighing}
              />
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <Button
              label={weighing ? "Stop Weighing..." : "Start Weighing"}
              type="button"
              onClick={() => toggleWeighing()}
              variant="contained"
              disabled={isWeighInDisabled()}
              color={!weighing ? "primary" : "default"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              label={isWeighIn() ? "Weigh In" : "Tare Truck"}
              type="submit"
              variant="contained"
              color={"secondary"}
              disabled={isWeighInDisabled()}
            />
          </Grid>
          {!isWeighIn() && (
            <Grid item xs={12} md={4}>
              <Button
                label={"Tare Truck with Ticket"}
                type="button"
                variant="contained"
                color={"secondary"}
                onClick={() => {
                  setTareTruckWithTicket(true);
                  handleSubmit();
                }}
                disabled={!values.ticket || !values.truck}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            {isWeighIn() ? <TicketDetails /> : <TruckDetails />}
          </Grid>
        </Grid>
      </form>
    </>
  );
};
export default TruckScale;
