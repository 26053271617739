import * as Yup from "yup";

export const SaveTicketFormSchema = Yup.object().shape({
  ticketNumber: Yup.string().required("Ticket number is required"),
  date: Yup.date().nullable().required("Date is required"),
  timeLoaded: Yup.date().nullable(),
  timeReceived: Yup.date()
    .nullable()
    .min(Yup.ref("timeLoaded"), "Time received must be after time loaded"),
  weight: Yup.number().nullable().typeError("Weight must be a number"),
});
