import { useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  IconButton,
  Drawer,
  Link,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Avatar,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LineWeightIcon from "@material-ui/icons/LineWeight";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PeopleIcon from "@material-ui/icons/People";
import AssessmentIcon from "@material-ui/icons/Assessment";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import AppsIcon from "@material-ui/icons/Apps";
import { Link as RouterLink } from "react-router-dom";

import { getCurrentUser } from "../../services/authService";
import { getUser } from "../../services/userService";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { User } from "../../interface/api/User";
import { setCurrentUser } from "../../store/userStore";
import logo from "../../assets/logo.png";
import { isAdmin } from "../../utils/constants";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  flex: {
    flex: 1,
  },
  menuButton: {},
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  toolBar: {
    justifyContent: "space-between",
    padding: "0px",
  },
  dashboardLink: {
    color: "#FFF",
  },
  removeDecoration: {
    textDecoration: "none",
  },
  logo: {
    width: "200px",
  },
  avatarContainer: { flex: 1, alignItems: "end", marginBottom: "15px" },
}));

const NavMenu = ({}) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector(getCurrentUser);

  useMemo(() => {
    const init = async () => {
      const token = getCurrentUser();

      if (token) {
        await getUser(token.primarysid).then((response) => {
          if (response.ok) {
            const user = response.data as User;
            const currentUserAction = {
              user,
              token,
            };

            dispatch(setCurrentUser(currentUserAction));
          }
        });
      }
    };
    init();
  }, []);

  if (!user) {
    return <></>;
  }

  return (
    <header>
      <div className={classes.root}>
        <AppBar position="static">
          <Container maxWidth="xl">
            <Toolbar className={classes.toolBar}>
              <Link component={RouterLink} to="/dashboard">
                <Typography
                  color="inherit"
                  className={`${classes.flex} ${classes.dashboardLink} ${classes.removeDecoration}`}
                >
                  United Soils Management
                </Typography>
              </Link>
              <IconButton
                color="inherit"
                aria-label="Menu"
                onClick={() => setIsOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </Container>
        </AppBar>
        <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)}>
          <div className={classes.toolbar}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Link
                component={RouterLink}
                to="/dashboard"
                className={classes.removeDecoration}
                onClick={() => setIsOpen(false)}
              >
                <img
                  src={logo}
                  alt="United Soils Management Logo"
                  className={classes.logo}
                  draggable={false}
                />
              </Link>
            </Box>
          </div>
          <Divider />
          <List style={{ width: drawerWidth }}>
            <Link
              component={RouterLink}
              to="/dashboard"
              onClick={() => setIsOpen(false)}
              className={classes.removeDecoration}
            >
              <ListItem button>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
            </Link>
            <Link
              component={RouterLink}
              to="/scale"
              onClick={() => setIsOpen(false)}
              className={classes.removeDecoration}
            >
              <ListItem button>
                <ListItemIcon>
                  <LineWeightIcon />
                </ListItemIcon>
                <ListItemText primary="Scale" />
              </ListItem>
            </Link>
            {isAdmin(user.role) && [
              <Link
                key="contractors"
                component={RouterLink}
                to="/contractors"
                onClick={() => setIsOpen(false)}
                className={classes.removeDecoration}
              >
                <ListItem button>
                  <ListItemIcon>
                    <OfflineBoltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Contractors" />
                </ListItem>
              </Link>,
              <Link
                key="transports"
                component={RouterLink}
                to="/transports"
                onClick={() => setIsOpen(false)}
                className={classes.removeDecoration}
              >
                <ListItem button>
                  <ListItemIcon>
                    <LocalShippingIcon />
                  </ListItemIcon>
                  <ListItemText primary="Transports" />
                </ListItem>
              </Link>,
              <Link
                key="materials"
                component={RouterLink}
                to="/materials"
                onClick={() => setIsOpen(false)}
                className={classes.removeDecoration}
              >
                <ListItem button>
                  <ListItemIcon>
                    <AppsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Materials" />
                </ListItem>
              </Link>,
              <Link
                key="users"
                component={RouterLink}
                to="/users"
                onClick={() => setIsOpen(false)}
                className={classes.removeDecoration}
              >
                <ListItem button>
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItem>
              </Link>,
              <Link
                key="reports"
                component={RouterLink}
                to="/reports"
                onClick={() => setIsOpen(false)}
                className={classes.removeDecoration}
              >
                <ListItem button>
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                </ListItem>
              </Link>,
            ]}

            <Link
              component={RouterLink}
              to="/logout"
              onClick={() => setIsOpen(false)}
              className={classes.removeDecoration}
            >
              <ListItem button>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </Link>
          </List>
          <ListItem className={classes.avatarContainer}>
            <ListItemIcon>
              <Avatar alt={user.nameid} src="/assets/profile-avatar.png" />
            </ListItemIcon>
            <ListItemText>
              <span>{user.nameid}</span>
            </ListItemText>
          </ListItem>
        </Drawer>
      </div>
    </header>
  );
};

export default NavMenu;
