import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  Container,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { useParams } from "react-router";

import { ContractorCompanyFormValues } from "../../../interface/formik/ContractorCompanyFormValues";
import { ContractorCompanyFormSchema } from "../schema/contractor-company-form-schema";
import contractorCompanyService from "../../../services/contractorCompanyService";
import { ContractorCompany } from "../../../interface/api/ContractorCompany";
import { history } from "../../..";
import { SetContractorCompanyAction } from "../../../interface/redux-types/SetContractorCompanyAction";
import {
  clearContractorCompanyContact,
  clearContractorProject,
  clearContractorProjects,
  getContractorCompany,
  getContractorCompanyContacts,
  getContractorProjects,
  setContractorCompany,
  setContractorCompanyContacts,
  setContractorProjects,
} from "../../../store/contractorStore";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import PageHero from "../../shared/page-hero";
import FormErrorMessage from "../../shared/form-error-message";
import FormTextField from "../../shared/form-text-field";
import { ContractorContact } from "../../../interface/api/ContractorContact";
import DeleteDialog from "../../shared/dialog/delete";
import {
  EditIcon,
  DeleteIcon,
  PersonCheckFillIcon,
  PersonDashFillIcon,
  SoilFLOIcon,
  OverThresholdIcon,
  ProjectLogsIcon,
} from "../../shared/icons";
import { StyledTableCell } from "../../shared/styled-table-cell";
import { StyledTableRow } from "../../shared/styled-table-row";
import Button from "../../shared/Button";
import contractorContactService from "../../../services/contractorContactService";
import { SetContractorContactsAction } from "../../../interface/redux-types/SetContractorContactsAction";
import { Project } from "../../../interface/api/Project";
import { SetContractorProjectsAction } from "../../../interface/redux-types/SetContractorProjectsAction";
import contractorProjectService from "../../../services/contractorProjectService";

const useStyles = makeStyles({
  form: {
    marginTop: "25px",
  },
  table: {
    minWidth: 800,
    marginTop: "1rem",
  },
  iconRow: {
    display: "flex",
    justifyContent: "space-around",
    minWidth: "150px",
  },
  tableContainer: {
    marginTop: "2rem",
  },
});

const ContractorCompanyView = ({}) => {
  const classes = useStyles();
  const { id }: any = useParams();
  const dispatch = useAppDispatch();
  const contractorCompany = useAppSelector(getContractorCompany);
  const contractorContacts = useAppSelector(getContractorCompanyContacts);
  const contractorProjects = useAppSelector(getContractorProjects);

  const [openContractorContactDialog, setOpenContractorContactDialog] =
    useState(false);
  const [openContractorProjectDialog, setOpenContractorProjectDialog] =
    useState(false);
  const [selectedContractorContactItem, setContractorContactSelectedItem] =
    useState<Partial<ContractorContact>>({});
  const [selectedContractorProjectItem, setContractorProjectSelectedItem] =
    useState<Partial<Project>>({});
  const onOpenContractorContactDialog = () =>
    setOpenContractorContactDialog(true);
  const onCloseContractorContactDialog = () =>
    setOpenContractorContactDialog(false);

  const onOpenContractorProjectDialog = () =>
    setOpenContractorProjectDialog(true);
  const onCloseContractorProjectDialog = () =>
    setOpenContractorProjectDialog(false);

  useEffect(() => {
    dispatch(clearContractorProject());
  }, []);

  useMemo(() => {
    if (!id || id === "new") return;

    contractorCompanyService.getContractorCompany(id).then((response) => {
      if (response.ok) {
        const contractorCompany = response.data as ContractorCompany;
        const setContractorCompanyAction: SetContractorCompanyAction = {
          contractorCompany: contractorCompany,
        };

        dispatch(setContractorCompany(setContractorCompanyAction));
      } else {
        toast.error(response.originalError.message);
      }
    });

    contractorContactService
      .getContractorContacts({
        contractorCompanyId: id,
        contractorContact: null,
      })
      .then((response) => {
        if (response.ok) {
          const contractorContacts = response.data as ContractorContact[];
          const setContractorContactsAction: SetContractorContactsAction = {
            contractorContacts: contractorContacts,
          };
          dispatch(setContractorCompanyContacts(setContractorContactsAction));
        }
      });

    contractorProjectService.getContractorProjects(id).then((response) => {
      if (response.ok) {
        const projects = response.data as Project[];
        const setProjectsAction: SetContractorProjectsAction = {
          projects,
        };

        dispatch(setContractorProjects(setProjectsAction));
      } else {
        toast.error(response.originalError.message);
      }
    });
  }, []);

  const onSubmit = (
    values: ContractorCompanyFormValues,
    setSubmitting: Function
  ) => {
    contractorCompanyService
      .saveContractorCompany(values as ContractorCompany)
      .then((response) => {
        if (response.ok) {
          history.push("/contractors");
        } else {
          const data = response.data as string;
          toast.error(data);
          setSubmitting(false);
        }
      });
  };

  const onConfirmContractorContactDelete = () => {
    if (selectedContractorContactItem && selectedContractorContactItem.id) {
      contractorContactService
        .deleteContractorContact(selectedContractorContactItem.id)
        .then((response) => {
          if (response.ok) {
            const setContractorContactsAction: SetContractorContactsAction = {
              contractorContacts: contractorContacts.filter(
                (c) => c.id !== selectedContractorContactItem.id
              ),
            };
            dispatch(setContractorCompanyContacts(setContractorContactsAction));
          }
        });
    }

    onCloseContractorContactDialog();
  };

  const onConfirmContractorProjectDelete = () => {
    if (selectedContractorProjectItem && selectedContractorProjectItem.id) {
      contractorProjectService
        .deleteProject(selectedContractorProjectItem.id)
        .then((response) => {
          if (response.ok) {
            const setContractorProjectsAction: SetContractorProjectsAction = {
              projects: contractorProjects.filter(
                (c) => c.id !== selectedContractorProjectItem.id
              ),
            };
            dispatch(setContractorProjects(setContractorProjectsAction));
          }
        });
    }

    onCloseContractorProjectDialog();
  };

  const initialValues: ContractorCompanyFormValues = contractorCompany
    ? contractorCompany
    : {
        id: 0,
        name: "",
      };

  return (
    <Container maxWidth="xl">
      <PageHero
        title={
          id > 0
            ? `${contractorCompany ? contractorCompany.name : id}`
            : "New Contractor Company"
        }
        subtitle={
          id > 0 ? "Edit Contractor Company." : "Add Contractor Company."
        }
        showBackButton={true}
      />
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={ContractorCompanyFormSchema}
          onSubmit={(values, { setSubmitting }) =>
            onSubmit(values, setSubmitting)
          }
          enableReinitialize={true}
        >
          {({ values, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={10}>
                  <FormTextField
                    name={"name"}
                    label={"Name"}
                    value={values.name}
                  />
                  <FormErrorMessage name="name" />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    label="Save"
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
        <div className={classes.tableContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={10}>
              <Typography variant="h4">Projects</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                label="Add Project"
                color="primary"
                variant="contained"
                type="button"
                onClick={() => {
                  dispatch(clearContractorProjects());
                  history.push(`/projects/new/contractor-company/${id}`);
                }}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs>
              {contractorProjects && (
                <TableContainer>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell>Project ID</StyledTableCell>
                        <StyledTableCell>Company</StyledTableCell>
                        <StyledTableCell>Job Site</StyledTableCell>
                        <StyledTableCell>Site Contact</StyledTableCell>
                        <StyledTableCell>Site Contact Number</StyledTableCell>
                        <StyledTableCell>Office Contact</StyledTableCell>
                        <StyledTableCell>Office Contact Number</StyledTableCell>
                        <StyledTableCell>Tickets Sold</StyledTableCell>
                        <StyledTableCell>Approved</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractorProjects.map((contractorProject: Project) => {
                        return (
                          <StyledTableRow key={contractorProject.id}>
                            <StyledTableCell>
                              {contractorProject.id}
                            </StyledTableCell>
                            <StyledTableCell>
                              {contractorProject.projectId}
                            </StyledTableCell>
                            <StyledTableCell>
                              {contractorProject.contractorCompanyName}
                            </StyledTableCell>
                            <StyledTableCell>
                              {contractorProject.jobSite}
                            </StyledTableCell>
                            <StyledTableCell>
                              {contractorProject.siteContactName}
                            </StyledTableCell>
                            <StyledTableCell>
                              {contractorProject.siteContactNumber}
                            </StyledTableCell>
                            <StyledTableCell>
                              {contractorProject.officeContactName}
                            </StyledTableCell>
                            <StyledTableCell>
                              {contractorProject.officeContactNumber}
                            </StyledTableCell>
                            <StyledTableCell>
                              {contractorProject.ticketsSold}
                            </StyledTableCell>
                            <StyledTableCell>
                              {contractorProject.approved}
                            </StyledTableCell>
                            <StyledTableCell>
                              {contractorProject.overThreshold && (
                                <OverThresholdIcon />
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              {contractorProject.isSoilFLOProject && (
                                <SoilFLOIcon />
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              <div className={classes.iconRow}>
                                <div>
                                  <ProjectLogsIcon
                                    onClick={() =>
                                      history.push(
                                        `/project-log/${contractorProject.id}`
                                      )
                                    }
                                  />
                                </div>
                                <div>
                                  <EditIcon
                                    onClick={() =>
                                      history.push(
                                        `/projects/${contractorProject.id}/contractor-company/${id}`
                                      )
                                    }
                                  />
                                </div>
                                <div>
                                  <DeleteIcon
                                    onClick={() => {
                                      setContractorProjectSelectedItem(
                                        contractorProject
                                      );
                                      onOpenContractorProjectDialog();
                                    }}
                                  />
                                </div>
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
          </Grid>
          <DeleteDialog
            open={openContractorProjectDialog}
            onClose={onCloseContractorProjectDialog}
            onConfirmDelete={onConfirmContractorProjectDelete}
          />
        </div>
        <div className={classes.tableContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={10}>
              <Typography variant="h4">Contacts</Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                label="Add Contact"
                color="primary"
                variant="contained"
                type="button"
                onClick={() => {
                  dispatch(clearContractorCompanyContact());
                  history.push(`/contacts/new/contractor-company/${id}`);
                }}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs>
              {contractorContacts && (
                <TableContainer>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell>Company</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Number</StyledTableCell>
                        <StyledTableCell>Email</StyledTableCell>
                        <StyledTableCell>Office Contact</StyledTableCell>
                        <StyledTableCell>Site Contact</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contractorContacts.map(
                        (contractorContact: ContractorContact) => {
                          return (
                            <StyledTableRow key={contractorContact.id}>
                              <StyledTableCell>
                                {contractorContact.id}
                              </StyledTableCell>
                              <StyledTableCell>
                                {contractorContact.contractorCompanyName}
                              </StyledTableCell>
                              <StyledTableCell>
                                {contractorContact.name}
                              </StyledTableCell>
                              <StyledTableCell>
                                {contractorContact.number}
                              </StyledTableCell>
                              <StyledTableCell>
                                {contractorContact.email}
                              </StyledTableCell>
                              <StyledTableCell>
                                {contractorContact.isOfficeContact ? (
                                  <PersonCheckFillIcon />
                                ) : (
                                  <PersonDashFillIcon />
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                {contractorContact.isSiteContact ? (
                                  <PersonCheckFillIcon />
                                ) : (
                                  <PersonDashFillIcon />
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                <div className={classes.iconRow}>
                                  <div>
                                    <EditIcon
                                      onClick={() =>
                                        history.push(
                                          `/contacts/${contractorContact.id}/contractor-company/${id}`
                                        )
                                      }
                                    />
                                  </div>
                                  <div>
                                    <DeleteIcon
                                      onClick={() => {
                                        setContractorContactSelectedItem(
                                          contractorContact
                                        );
                                        onOpenContractorContactDialog();
                                      }}
                                    />
                                  </div>
                                </div>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
          </Grid>
          <DeleteDialog
            open={openContractorContactDialog}
            onClose={onCloseContractorContactDialog}
            onConfirmDelete={onConfirmContractorContactDelete}
          />
        </div>
      </div>
    </Container>
  );
};

export default connect()(ContractorCompanyView);
