import { Button, makeStyles } from "@material-ui/core";
import { AiOutlineExclamation } from "react-icons/ai";
import {
  BsFillPencilFill,
  BsFillStickyFill,
  BsFillTrashFill,
  BsListOl,
  BsFillPrinterFill,
  BsFillPersonCheckFill,
  BsFillPersonDashFill,
} from "react-icons/bs";
import { GiHazardSign } from "react-icons/gi";
import { BiMoveHorizontal } from "react-icons/bi";
import { FaRegNewspaper, FaTruck } from "react-icons/fa";
import { HiLockOpen, HiLockClosed } from "react-icons/hi";
import { IoMdCheckmark } from "react-icons/io";
import { MdCallReceived, MdOutlineEditOff } from "react-icons/md";

import { history } from "../..";
import { Ticket } from "../../interface/api/Ticket";
import { User } from "../../interface/api/User";
import { isAdmin } from "../../utils/constants";
import { isDateEqualTo } from "../../utils/date-helper";

const useStyles = makeStyles({
  editIcon: {
    cursor: "pointer",
    color: "#032e5b",
  },
  deleteIcon: {
    cursor: "pointer",
    color: "#ff0000",
  },
  moveIcon: {
    cursor: "pointer",
  },
  printIcon: {
    cursor: "pointer",
  },
  receiveIcon: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  digitalTicketIcon: {
    cursor: "pointer",
    color: "#ffc107",
    fontSize: "18px",
  },
  ticketLogsIcon: {
    cursor: "pointer",
    color: "blue",
    fontSize: "18px",
  },
  projectLogsIcon: {
    cursor: "pointer",
    color: "black",
    fontSize: "18px",
  },
  viewHaulageRecordIcon: {
    cursor: "pointer",
    fontSize: "18px",
  },
  receivedIcon: {
    color: "green",
    fontSize: "20px",
    cursor: "help",
    marginRight: "10px",
  },
  notCompleteIcon: {
    color: "red",
    fontSize: "20px",
    cursor: "help",
    marginRight: "10px",
  },
  overThresholdIcon: {
    color: "orange",
    fontSize: "20px",
  },
  soilFLOIcon: {
    color: "orange",
    fontSize: "20px",
    cursor: "help",
  },
  dispatchLockOpenIcon: {
    cursor: "pointer",
    fontSize: "18px",
  },
  dispatchedClosedLockIcon: {
    cursor: "pointer",
    fontSize: "18px",
  },
  editDisabledIcon: { color: "gray", fontSize: "18px", marginLeft: "20px" },
  greenCheckMark: {
    color: "green",
    fontSize: "20px",
  },
});

const ReceivedIcon = () => {
  const classes = useStyles();
  return <IoMdCheckmark className={classes.receivedIcon} />;
};

const NotCompleteIcon = () => {
  const classes = useStyles();
  return <AiOutlineExclamation className={classes.notCompleteIcon} />;
};

const OverThresholdIcon = () => {
  const classes = useStyles();
  return <GiHazardSign className={classes.overThresholdIcon} />;
};

const SoilFLOIcon = () => {
  const classes = useStyles();
  return <FaTruck className={classes.soilFLOIcon} />;
};

const TruckDispatchIcon = (
  row: Ticket,
  onToggleTicketDispatchLock: Function
) => {
  const classes = useStyles();
  if (
    !isDateEqualTo(row.timeLoaded, new Date()) ||
    row.isReceived ||
    row.isSoilFLOLoad
  )
    return;

  return row.isDispatched ? (
    <span title="Unlock Ticket Dispatch">
      <HiLockOpen
        className={classes.dispatchLockOpenIcon}
        onClick={() => onToggleTicketDispatchLock(row.id)}
      />
    </span>
  ) : (
    <span title="Lock Ticket Dispatch">
      <HiLockClosed
        className={classes.dispatchedClosedLockIcon}
        onClick={() => onToggleTicketDispatchLock(row.id)}
      />
    </span>
  );
};

const TicketEditIcon = (row: Ticket, user: User | null) => {
  const classes = useStyles();
  return user && isAdmin(user.roleId) ? (
    <span title="Edit Ticket">
      <EditIcon onClick={() => history.push(`/ticket/${row.id}`)} />
    </span>
  ) : (
    <>
      {row.isReceived ? (
        <span title="Disabled">
          <MdOutlineEditOff className={classes.editDisabledIcon} />
        </span>
      ) : (
        <span title="Edit Ticket">
          <EditIcon onClick={() => history.push(`/ticket/${row.id}`)} />
        </span>
      )}
    </>
  );
};

const EditIcon = ({ onClick }: any) => {
  const classes = useStyles();
  return <BsFillPencilFill onClick={onClick} className={classes.editIcon} />;
};

const DeleteIcon = ({ onClick }: any) => {
  const classes = useStyles();
  return <BsFillTrashFill className={classes.deleteIcon} onClick={onClick} />;
};

const MoveIcon = ({ onClick }: any) => {
  const classes = useStyles();
  return (
    <span title="Move Trucks">
      <BiMoveHorizontal className={classes.moveIcon} onClick={onClick} />
    </span>
  );
};

const PrintIcon = ({ onClick }: any) => {
  const classes = useStyles();
  return (
    <span title="Print Record">
      <BsFillPrinterFill className={classes.printIcon} onClick={onClick} />
    </span>
  );
};

const DigitalTicketIcon = ({ onClick }: any) => {
  const classes = useStyles();
  return (
    <span title="View Digital Ticket">
      <BsFillStickyFill
        className={classes.digitalTicketIcon}
        onClick={onClick}
      />
    </span>
  );
};

const ReceiveIcon = ({ onClick, isMobile }: any) => {
  const classes = useStyles();

  return (
    <Button
      type="submit"
      variant="contained"
      color="secondary"
      onClick={onClick}
      className={classes.receiveIcon}
    >
      {isMobile ? <MdCallReceived size={20} /> : "Receive"}
    </Button>
  );
};

const TicketLogsIcon = ({ onClick }: any) => {
  const classes = useStyles();

  return (
    <span title="View Ticket Logs">
      <BsListOl className={classes.ticketLogsIcon} onClick={onClick} />
    </span>
  );
};

const ProjectLogsIcon = ({ onClick }: any) => {
  const classes = useStyles();

  return (
    <span title="View Project Logs">
      <BsListOl className={classes.projectLogsIcon} onClick={onClick} />
    </span>
  );
};

const ViewHaulageRecordIcon = ({ onClick }: any) => {
  const classes = useStyles();
  return (
    <span title="View Haulage Record">
      <FaRegNewspaper
        className={classes.viewHaulageRecordIcon}
        onClick={onClick}
      />
    </span>
  );
};

const PersonCheckFillIcon = () => {
  return <BsFillPersonCheckFill size={22} />;
};

const PersonDashFillIcon = () => {
  return <BsFillPersonDashFill size={22} />;
};

export {
  ReceivedIcon,
  NotCompleteIcon,
  SoilFLOIcon,
  TruckDispatchIcon,
  TicketEditIcon,
  EditIcon,
  DeleteIcon,
  ReceiveIcon,
  DigitalTicketIcon,
  TicketLogsIcon,
  ProjectLogsIcon,
  ViewHaulageRecordIcon,
  MoveIcon,
  PrintIcon,
  PersonCheckFillIcon,
  PersonDashFillIcon,
  OverThresholdIcon,
};
