import { IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import { useFormikContext } from "formik";
import { useState } from "react";

import { FormPasswordFieldProps } from "../../interface/formik/FormPasswordFieldProps";

const FormPasswordField = ({ name, label, value }: FormPasswordFieldProps) => {
  const { handleChange, handleBlur } = useFormikContext<any>();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      type={showPassword ? "text" : "password"}
      name={name}
      label={label}
      variant="outlined"
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      fullWidth
      InputProps={{
        endAdornment: (
          <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        ),
      }}
    />
  );
};

export default FormPasswordField;
