import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

import { FormSelectProps } from "../../interface/formik/FormSelectProps";
import { useFormikContext } from "formik";

const FormSelect = ({
  name,
  label,
  value,
  items,
  onChange,
  disabled,
}: FormSelectProps) => {
  const { handleChange } = useFormikContext();

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        value={value ? value : ""}
        name={name}
        id={name}
        onChange={onChange ? (e) => onChange(e) : handleChange}
        label={label}
        defaultValue={0}
        displayEmpty={true}
        fullWidth
        disabled={disabled}
      >
        <MenuItem key={0} value={0}>
          (Clear Value)
        </MenuItem>
        {items}
      </Select>
    </FormControl>
  );
};

export default FormSelect;
