import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { orderBy as sort } from "lodash";

import { RootState } from "..";
import { ContractorState } from "../interface/redux-types/ContractorState";
import { SetContractorCompaniesAction } from "../interface/redux-types/SetContractorCompaniesAction";
import { SetContractorCompanyAction } from "../interface/redux-types/SetContractorCompanyAction";
import { SetContractorContactAction } from "../interface/redux-types/SetContractorContactAction";
import { SetContractorContactsAction } from "../interface/redux-types/SetContractorContactsAction";
import { SetContractorProjectAction } from "../interface/redux-types/SetContractorProjectAction";
import { SetContractorProjectsAction } from "../interface/redux-types/SetContractorProjectsAction";

const initialState: ContractorState = {
  contractorCompanies: [],
  contractor: {
    contractorCompany: null,
    contractorContacts: [],
    contractorContact: null,
    projects: [],
    project: null,
  },
};

const slice = createSlice({
  name: "contractorStore",
  initialState,
  reducers: {
    setContractorCompanies: (
      state,
      action: PayloadAction<SetContractorCompaniesAction>
    ) => {
      state.contractorCompanies = action.payload.contractorCompanies;
    },

    setContractorCompany: (
      state,
      action: PayloadAction<SetContractorCompanyAction>
    ) => {
      state.contractor.contractorCompany = action.payload.contractorCompany;
    },

    setContractorCompanyContacts: (
      state,
      action: PayloadAction<SetContractorContactsAction>
    ) => {
      state.contractor.contractorContacts = action.payload.contractorContacts;
    },

    setContractorCompanyContact: (
      state,
      action: PayloadAction<SetContractorContactAction>
    ) => {
      state.contractor.contractorContact = action.payload.contractorContact;
    },

    setContractorProjects: (
      state,
      action: PayloadAction<SetContractorProjectsAction>
    ) => {
      state.contractor.projects = action.payload.projects;
    },

    setContractorProject: (
      state,
      action: PayloadAction<SetContractorProjectAction>
    ) => {
      state.contractor.project = action.payload.project;
    },

    clearContractorCompany: (state) => {
      state.contractor.contractorCompany = null;
    },

    clearContractorCompanyContacts: (state) => {
      state.contractor.contractorContacts = [];
    },

    clearContractorCompanyContact: (state) => {
      state.contractor.contractorContact = null;
    },
    clearContractorProjects: (state) => {
      state.contractor.projects = [];
    },
    clearContractorProject: (state) => {
      state.contractor.project = null;
    },
  },
});

export const {
  setContractorCompanies,
  setContractorCompany,
  clearContractorCompany,
  clearContractorCompanyContact,
  setContractorCompanyContact,
  setContractorCompanyContacts,
  clearContractorCompanyContacts,
  setContractorProjects,
  clearContractorProjects,
  setContractorProject,
  clearContractorProject,
} = slice.actions;

export const getContractorCompanies = createSelector(
  (state: RootState) => state.contractorStore.contractorCompanies,
  (contractorCompanies) => {
    return sort(contractorCompanies, (c) => c.name, ["asc"]);
  }
);

export const getContractorCompany = createSelector(
  (state: RootState) => state.contractorStore.contractor.contractorCompany,
  (contractorCompany) => {
    return contractorCompany;
  }
);

export const getContractorCompanyContacts = createSelector(
  (state: RootState) => state.contractorStore.contractor.contractorContacts,
  (contractorContacts) => {
    return contractorContacts;
  }
);

export const getContractorCompanyContact = createSelector(
  (state: RootState) => state.contractorStore.contractor.contractorContact,
  (contractorContact) => {
    return contractorContact;
  }
);

export const getContractorProjects = createSelector(
  (state: RootState) => state.contractorStore.contractor.projects,
  (projects) => {
    return projects;
  }
);

export const getContractorProject = createSelector(
  (state: RootState) => state.contractorStore.contractor.project,
  (project) => {
    return project;
  }
);

export const reducer = slice.reducer;
