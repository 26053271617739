import http from "./httpService";
import auth from "./authService";
import { ProjectPayload } from "../interface/api/ProjectPayload";

http.setJwt(auth.getJwt());

export const deleteProject = (id: number | string) => {
  return http.delete(`/api/project/${id}`);
};

export const getProject = (id: number | string) => {
  return http.get(`/api/project/${id}`);
};

export const getProjects = () => {
  return http.get("/api/project/all");
};

export const getContractorProjects = (contractorCompanyId: number) => {
  return http.get(`/api/project/contractor/${contractorCompanyId}`);
};

export function saveProject(projectPayload: ProjectPayload) {
  if (projectPayload && projectPayload.project.id > 0) {
    return http.put("/api/project/edit", projectPayload);
  }
  return http.post("/api/project/new", projectPayload);
}
export const getProjectLogs = (projectId: number | string) => {
  return http.get(`/api/project/logs/${projectId}`);
};

export default {
  deleteProject,
  getProject,
  getProjects,
  saveProject,
  getContractorProjects,
  getProjectLogs,
};
