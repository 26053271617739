import InputMask from "react-input-mask";
import {
  TextFieldProps,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import { useFormikContext } from "formik";

import { FormTruckLicensePlateFieldProps } from "../../interface/TruckLicensePlateInputProps";
import InputSubtitle from "./input-subtitle";
import { useState } from "react";
import FormTextField from "./form-text-field";

const FormTruckLicensePlateField = ({
  value,
  name,
  label,
  placeholder,
  disabled,
}: FormTruckLicensePlateFieldProps) => {
  const { handleChange, handleBlur } = useFormikContext<any>();
  const [formatedEnabled, setFormatEnabled] = useState(true);

  const parseValue = () => {
    return capitalizeAll(value);
  };

  const capitalizeAll = (value: string) => {
    if (!value) return "";
    return value.toLocaleUpperCase();
  };

  if (!formatedEnabled) {
    return (
      <>
        <FormTextField
          value={value ? parseValue() : ""}
          label={label}
          name={name}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formatedEnabled}
                  onChange={() => setFormatEnabled(!formatedEnabled)}
                  color="primary"
                />
              }
              label="Formatted"
            />
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <InputMask
        mask={"aa99999"}
        value={value ? parseValue() : ""}
        onChange={handleChange}
        onBlur={handleBlur}
        maskChar="_"
        disabled={disabled}
      >
        {(props: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            {...props}
            label={label}
            variant="outlined"
            type="text"
            name={name}
            placeholder={placeholder ? placeholder : ""}
            inputProps={{ style: { textTransform: "uppercase" } }}
            fullWidth
          />
        )}
      </InputMask>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formatedEnabled}
                onChange={() => setFormatEnabled(!formatedEnabled)}
                color="primary"
              />
            }
            label="Formatted"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputSubtitle text="Format: AA99999" />
        </Grid>
      </Grid>
    </>
  );
};

export default FormTruckLicensePlateField;
