import { FormControl, makeStyles } from "@material-ui/core";
import Select from "react-select";

import { FormSelectSearchFieldProps } from "../../interface/formik/FormSelectSearchFieldProps";

const useStyles = makeStyles({
  control: {
    width: "100%",
  },
});

const FormSelectSearchField = ({
  name,
  value,
  options,
  onChange,
  placeholder,
  isClearable,
}: FormSelectSearchFieldProps) => {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.control}>
      <Select
        placeholder={placeholder}
        name={name}
        id={name}
        className="react-select"
        onChange={(state, triggeredAction) => onChange(state, triggeredAction)}
        options={options}
        isClearable={isClearable}
        value={value}
      />
    </FormControl>
  );
};

export default FormSelectSearchField;
