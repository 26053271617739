import { useState } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { history } from "../..";

import { Box, TextField, IconButton, makeStyles } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { LoginFormValues } from "../../interface/formik/LoginFormValues";
import { LoginFormSchema } from "./login-form-schema";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { login, getCurrentUser } from "../../services/authService";
import { getUser } from "../../services/userService";
import logo from "../../assets/logo.png";
import { User } from "../../interface/api/User";
import {
  getUserError,
  setCurrentUser,
  setUserError,
} from "../../store/userStore";
import FormErrorMessage from "../shared/form-error-message";
import Button from "../shared/Button";
import FormTextField from "../shared/form-text-field";
import FormPasswordField from "../shared/form-password-field";

const useStyles = makeStyles({
  logo: { marginBottom: "60px", maxWidth: "100%" },
  textField: {
    width: "100%",
    color: "secondary.main",
  },
  error: { color: "red", textAlign: "center" },
});

const Login = ({}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const error = useAppSelector(getUserError);
  const dispatch = useAppDispatch();

  const onSubmit = async (values: LoginFormValues) => {
    const { username, password } = values;

    try {
      await login(username, password);
      const token = getCurrentUser();

      if (token) {
        await getUser(token.primarysid).then((response) => {
          if (response.ok) {
            const user = response.data as User;
            const currentUserAction = {
              user,
              token,
            };

            dispatch(setCurrentUser(currentUserAction));

            history.push("/dashboard");
          }
        });
      }
    } catch (e) {
      const errorAction = {
        error: (e as Error).message,
      };

      dispatch(setUserError(errorAction));
    }
  };

  const initialValues: LoginFormValues = { username: "", password: "" };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        bgcolor: "primary.main",
      }}
    >
      <Box
        sx={{
          maxWidth: 600,
          bgcolor: "#FFFFFF",
          borderRadius: 5,
          padding: "40px",
          margin: 20,
        }}
      >
        <Box>
          <img
            src={logo}
            alt="United Soils Management Logo"
            className={classes.logo}
            draggable={false}
          />
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={LoginFormSchema}
          onSubmit={(values) => onSubmit(values)}
        >
          {({ values, handleSubmit, isSubmitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    marginBottom: 25,
                  }}
                >
                  <FormTextField
                    name="username"
                    type="email"
                    label={"Username"}
                    dontCapitalize={true}
                    value={values.username}
                  />
                  <FormErrorMessage name="username" />
                </Box>
                <Box
                  sx={{
                    marginBottom: 25,
                  }}
                >
                  <FormPasswordField
                    name="password"
                    label="Password"
                    value={values.password}
                  />
                  <FormErrorMessage name="password" />
                </Box>
                <Button
                  label={isSubmitting ? "Logging in..." : "Login"}
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                />
                {error && <p className={classes.error}>{error}</p>}
              </form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default connect()(Login);
