import InputMask from "react-input-mask";
import { TextFieldProps, TextField } from "@material-ui/core";
import { useFormikContext } from "formik";

import InputSubtitle from "./input-subtitle";
import { PhoneInputProps } from "../../interface/PhoneInputProps";

const PhoneInput = ({ value, name, label }: PhoneInputProps) => {
  const { handleChange, handleBlur } = useFormikContext<any>();
  return (
    <>
      <InputMask
        mask="(999)999-9999"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {(props: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            {...props}
            label={label}
            variant="outlined"
            type="text"
            name={name}
            fullWidth
          />
        )}
      </InputMask>
      <InputSubtitle text="Format: (###)###-####" />
    </>
  );
};

export default PhoneInput;
