import { Formik } from "formik";
import { useMemo } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Container, Grid } from "@material-ui/core";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import { history } from "../../..";
import { MaterialFormSchema } from "../schema/material-form-schema";
import PageHero from "../../shared/page-hero";
import scaleService from "../../../services/scaleService";
import { getMaterial, setMaterial } from "../../../store/scaleStore";
import { SetMaterialAction } from "../../../interface/redux-types/SetMaterialAction";
import { Material } from "../../../interface/api/Material";
import { MaterialFormValues } from "../../../interface/formik/MaterialFormValues";
import FormErrorMessage from "../../shared/form-error-message";
import FormTextField from "../../shared/form-text-field";
import Button from "../../shared/Button";

const MaterialView = ({}) => {
  const { id }: any = useParams();
  const dispatch = useAppDispatch();
  const material = useAppSelector(getMaterial);

  useMemo(() => {
    if (id > 0) {
      scaleService.getMaterial(id).then((response) => {
        if (response.ok) {
          const material = response.data as Material;
          const setMaterialAction: SetMaterialAction = {
            material,
          };
          dispatch(setMaterial(setMaterialAction));
        } else {
          toast.error(response.originalError.message);
        }
      });
    }
  }, []);

  const onSubmit = (values: MaterialFormValues, setSubmitting: Function) => {
    scaleService.saveMaterial(values as Material).then((response) => {
      if (response.ok) {
        history.push(`/materials`);
      } else {
        const data = response.data as string;
        toast.error(data);
        setSubmitting(false);
      }
    });

    setSubmitting(false);
  };

  const initialValues: MaterialFormValues = material
    ? material
    : {
        id: 0,
        name: "",
      };

  return (
    <Container maxWidth="xl">
      <PageHero
        title={id > 0 ? `${material ? material.name : id}` : "New Material"}
        subtitle={id > 0 ? `Edit Material.` : "Add Material."}
        showBackButton={true}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={MaterialFormSchema}
        onSubmit={(values, { setSubmitting }) =>
          onSubmit(values, setSubmitting)
        }
        enableReinitialize={true}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormTextField name="name" label="Name" value={values.name} />
                <FormErrorMessage name="name" />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  label="Save"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default connect()(MaterialView);
