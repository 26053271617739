import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  makeStyles,
} from "@material-ui/core";

import Button from "../Button";
import { ClearBulkTicketsDialogProps } from "../../../interface/dialog/ClearBulkTicketsDialogProps";

const useStyles = makeStyles({
  dialogActions: {
    padding: "20px",
  },
  bottomSpace: {
    marginBottom: "1rem",
  },
});

const ConfirmBulkClearDialog = ({
  open,
  onClose,
  onConfirmSubmit,
  response,
}: ClearBulkTicketsDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>{"Confirm Bulk Clear?"}</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to proceed and clear out selected ticket values?
        </Typography>
      </DialogContent>
      <DialogContent>
        <>
          <Typography className={classes.bottomSpace}>
            Excluded tickets ({response?.excludedTicketNumbers.length}):{" "}
            {Number(response?.excludedTicketNumbers?.length) > 0
              ? response?.excludedTicketNumbers.join(",")
              : "none"}
          </Typography>
          {response && response?.ticketNumbers?.length > 0 ? (
            <>
              <Typography>
                {response.ticketNumbers.length} {"ticket(s)"} will be cleared:
              </Typography>

              {response &&
                response.results.map((result, index) => {
                  return (
                    <Typography key={index}>
                      <b>{result.key}</b> {result.value}
                    </Typography>
                  );
                })}
            </>
          ) : (
            <Typography>{"No ticket conflict's were found."}</Typography>
          )}
        </>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          label="No"
          onClick={() => onClose()}
          color="default"
          variant="contained"
          type="button"
        />
        <Button
          label="Yes"
          onClick={() => onConfirmSubmit()}
          autoFocus
          color="primary"
          variant="contained"
          type="button"
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmBulkClearDialog;
