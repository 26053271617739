import { Formik } from "formik";
import { useMemo } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Container, Box, Grid } from "@material-ui/core";

import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { history } from "../../../..";
import {
  getTransportCompany,
  getTransportCompanyTruck,
  setTransportCompany,
  setTransportCompanyTruck,
} from "../../../../store/transportStore";
import truckService from "../../../../services/truckService";
import { Truck } from "../../../../interface/api/Truck";
import { SetTruckAction } from "../../../../interface/redux-types/SetTruckAction";
import transportCompanyService from "../../../../services/transportCompanyService";
import { TransportCompany } from "../../../../interface/api/TransportCompany";
import { SetTransportCompanyAction } from "../../../../interface/redux-types/SetTransportCompanyAction";
import { TruckFormValues } from "../../../../interface/formik/TruckFormValues";
import { TransportPayload } from "../../../../interface/api/TransportPayload";
import { TruckFormSchema } from "../../schema/truck-form-schema";
import PageHero from "../../../shared/page-hero";
import FormTruckLicensePlateField from "../../../shared/form-truck-license-plate-field";
import { getSetting, setSetting } from "../../../../store/settingStore";
import { Setting } from "../../../../interface/api/Setting";
import { SetSettingAction } from "../../../../interface/redux-types/SetSettingAction";
import settingService from "../../../../services/settingService";
import FormErrorMessage from "../../../shared/form-error-message";
import FormTextField from "../../../shared/form-text-field";
import PhoneInput from "../../../shared/phone-input";
import Button from "../../../shared/Button";

const TruckView = ({}) => {
  const { truckId, companyId }: any = useParams();
  const dispatch = useAppDispatch();
  const truck = useAppSelector(getTransportCompanyTruck);
  const transportCompany = useAppSelector(getTransportCompany);
  const setting = useAppSelector(getSetting);

  useMemo(() => {
    if (truckId > 0) {
      truckService.getTruck(truckId).then((response) => {
        if (response.ok) {
          const truck = response.data as Truck;
          const setTruckAction: SetTruckAction = {
            truck,
          };
          dispatch(setTransportCompanyTruck(setTruckAction));
        } else {
          toast.error(response.originalError.message);
        }
      });
    }

    if (companyId > 0) {
      transportCompanyService
        .getTransportCompany(companyId)
        .then((response) => {
          if (response.ok) {
            const transportCompany = response.data as TransportCompany;
            const setTransportCompanyAction: SetTransportCompanyAction = {
              transportCompany,
            };

            dispatch(setTransportCompany(setTransportCompanyAction));
          } else {
            toast.error(response.originalError.message);
          }
        });
    }

    settingService.getSetting().then((response) => {
      if (response.ok) {
        const setting = response.data as Setting;
        const setSettingAction: SetSettingAction = {
          setting,
        };
        dispatch(setSetting(setSettingAction));
      }
    });
  }, []);

  const onSubmit = (values: TruckFormValues, setSubmitting: Function) => {
    const transportPayload: TransportPayload = {
      transportCompanyId: values.transportCompanyId,
      truck: values as Truck,
      transportContact: null,
    };

    truckService.saveTruck(transportPayload).then((response) => {
      if (response.ok) {
        history.goBack();
      } else {
        const data = response.data as string;
        toast.error(data);
        setSubmitting(false);
      }
    });

    setSubmitting(false);
  };

  const initialValues: TruckFormValues = truck
    ? truck
    : {
        id: 0,
        transportCompanyId: transportCompany ? transportCompany.id : 0,
        transportCompanyName: transportCompany ? transportCompany.name : "",
        driverName: "",
        driverNumber: "",
        number: "",
        licensePlate: "",
        tareWeight: setting.defaultTareWeight,
      };

  return (
    <Container maxWidth="xl">
      <PageHero
        title={truckId > 0 ? `Truck: ${truckId}` : "New Truck"}
        subtitle={truckId > 0 ? "Edit Truck Details." : "Add New Truck."}
        showBackButton={true}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={TruckFormSchema}
        onSubmit={(values, { setSubmitting }) =>
          onSubmit(values, setSubmitting)
        }
        enableReinitialize={true}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="transportCompanyName"
                  label={"Transport Company Name"}
                  value={values.transportCompanyName}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="driverName"
                  label={"Driver Name"}
                  value={values.driverName}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <PhoneInput
                  name="driverNumber"
                  label={"Phone Number"}
                  value={values.driverNumber}
                />
                <FormErrorMessage name="driverNumber" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="number"
                  label={"Truck Number"}
                  value={values.number}
                />
                <FormErrorMessage name="number" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTruckLicensePlateField
                  value={values.licensePlate}
                  name={"licensePlate"}
                  label={"License Plate"}
                  placeholder={null}
                />
                <FormErrorMessage name="licensePlate" />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextField
                  name="tareWeight"
                  label={"Tare Weight"}
                  value={values.tareWeight}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Button
                    label="Save"
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default connect()(TruckView);
