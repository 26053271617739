import * as Yup from "yup";

export const TicketFormSchema = Yup.object().shape({
  ticketNumber: Yup.string().required("Ticket number is required"),
  date: Yup.date().nullable().required("Date is required"),
  jobSite: Yup.string().nullable().required("Job site is required"),
  siteContactName: Yup.string().nullable().required("Site contact is required"),
  contractorCompanyId: Yup.number()
    .nullable()
    .min(1, "Please select a contractor")
    .required("Contractor is required"),
  timeLoaded: Yup.date().nullable().required("Time loaded is required"),
  transportCompanyId: Yup.number()
    .nullable()
    .min(1, "Please select a haulage company")
    .required("Haulage company is required"),
  truckTypeId: Yup.number()
    .nullable()
    .min(1, "Please select a truck type")
    .required("Truck type is required"),
  truckDriverName: Yup.string().nullable().required("Driver name is required"),
  truckLicensePlate: Yup.string()
    .nullable()
    .required("License plate is required"),
  timeReceived: Yup.date()
    .nullable()
    .min(Yup.ref("timeLoaded"), "Time received must be after time loaded")
    .required("Time received is required"),
  notes: Yup.string().nullable().max(500, "Notes can be max 500 characters"),
  weight: Yup.number().nullable().typeError("Weight must be a number"),
});
