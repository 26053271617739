import { UserState } from "../interface/redux-types";
import { TicketState } from "../interface/redux-types/TicketState";
import { ContractorState } from "../interface/redux-types/ContractorState";
import * as User from "./userStore";
import * as Ticket from "./ticketStore";
import * as Contractor from "./contractorStore";
import * as Transport from "./transportStore";
import * as Scale from "./scaleStore";
import * as Setting from "./settingStore";
import { TransportState } from "../interface/redux-types/TransportState";
import { ScaleState } from "../interface/redux-types/ScaleState";
import { SettingState } from "../interface/redux-types/SettingState";

export interface ApplicationState {
  userStore: UserState | undefined;
  ticketStore: TicketState | undefined;
  contractorStore: ContractorState | undefined;
  transportStore: TransportState | undefined;
  scaleStore: ScaleState | undefined;
  settingStore: SettingState | undefined;
}
export const reducers = {
  userStore: User.reducer,
  ticketStore: Ticket.reducer,
  contractorStore: Contractor.reducer,
  transportStore: Transport.reducer,
  scaleStore: Scale.reducer,
  settingStore: Setting.reducer,
};

export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
