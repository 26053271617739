import { useMemo, useState } from "react";
import { connect } from "react-redux";
import QRCode from "react-qr-code";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Box, Container, Typography, Grid } from "@material-ui/core";

import { Ticket } from "../../interface/api/Ticket";
import ticketService from "../../services/ticketService";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getTicket, setTicket } from "../../store/ticketStore";
import { SetTicketAction } from "../../interface/redux-types/SetTicketAction";
import { USMQRCodeSize } from "../../utils/constants";
import UnitedLogo from "../../assets/logo.png";
import { formatDate, formatTime } from "../../utils/date-helper";

const DigitalTicketView = ({}) => {
  const { id }: any = useParams();
  const [ticketUrl, setTicketUrl] = useState("");
  const [haulageRecordUrl, setHaulageRecordUrl] = useState("");
  const dispatch = useAppDispatch();
  const ticket = useAppSelector(getTicket);

  useMemo(() => {
    ticketService.getTicket(id).then(async (response) => {
      if (response.ok) {
        const ticket = response.data as Ticket;
        const setTicketAction: SetTicketAction = {
          ticket,
        };

        if (ticket.id > 10015) {
          const { data } = await ticketService.getTicketHash(ticket.id);
          setTicketUrl(
            `${window.location.origin}/ticket/${ticket.id}?hash=${data}`
          );
        } else {
          setTicketUrl(`${window.location.origin}/ticket/${ticket.id}`);
        }

        setHaulageRecordUrl(
          `${window.location.origin}/haulage-record/${ticket.id}`
        );

        dispatch(setTicket(setTicketAction));
      } else {
        toast.error(response.originalError.message);
      }
    });
  }, []);

  if (ticket === null) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <Typography variant="h3">Loading...</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          border: "2px solid black",
          padding: "20px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        {ticket.isReceived && (
          <Box
            style={{ backgroundColor: "green" }}
            sx={{
              border: "3px solid green",
              borderRadius: 6,
              textAlign: "center",
              padding: 5,
              marginBottom: 20,
            }}
          >
            <Typography
              style={{
                fontSize: 38,
                textTransform: "uppercase",
                letterSpacing: 5,
                margin: 0,
                padding: 0,
                color: "white",
              }}
            >
              Received
            </Typography>
          </Box>
        )}
        <Typography variant="h5" style={{ marginBottom: 15 }}>
          <b>United</b> Soils Management Ltd.
        </Typography>
        <Typography variant="h5" style={{ color: "red", marginBottom: 15 }}>
          No. {ticket.ticketNumber}
        </Typography>
        <Grid container spacing={3} style={{ marginBottom: 10 }}>
          <Grid item xs={6}>
            <QRCode value={ticketUrl} size={USMQRCodeSize} />
            <img src={UnitedLogo} width={125} style={{ marginTop: 30 }} />
          </Grid>
        </Grid>
        <Box sx={{ marginBottom: 20 }}>
          <Typography style={{ marginBottom: 12 }}>
            <b>Date:</b> {formatDate(ticket.timeLoaded, ticket.isSoilFLOLoad)}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Contractor:</b> {ticket.contractorCompanyName}
          </Typography>
          <Typography>
            <b>Job Site:</b> {ticket.jobSite}
          </Typography>
        </Box>
        <Box sx={{ marginBottom: 20 }}>
          <Typography
            variant="h6"
            style={{
              textTransform: "uppercase",
              marginBottom: 12,
            }}
          >
            Loading Site
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Haulage Co.:</b> {ticket.transportCompanyName}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Truck No./Plate No.:</b> {ticket.truckNumber} /{" "}
            {ticket.truckLicensePlate}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Driver Name:</b> {ticket.truckDriverName}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Time Loaded:</b>
            {formatTime(ticket.timeLoaded, ticket.isSoilFLOLoad)}
          </Typography>
          <Typography>
            By signing below, I certify that I have witnessed the loading of the
            truck, with soil approved for disposal at United Soils and its
            transportation is in compliance with applicable regulations.
          </Typography>
        </Box>
        <Box sx={{ marginBottom: 20 }}>
          <Typography
            variant="h6"
            style={{
              marginBottom: 12,
              marginTop: 20,
              color: "red",
              textAlign: "center",
            }}
          >
            No Refund - Non Transferable
          </Typography>
        </Box>
        <Box sx={{ marginBottom: 20 }}>
          <Typography
            variant="h6"
            style={{
              textTransform: "uppercase",
              marginBottom: 12,
            }}
          >
            Receiving Site Information
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Address:</b> 14395 Ninth Line, Stouffville, ON L4A 8B3
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Time Unloaded:</b>
            {formatTime(ticket.timeReceived, ticket.isSoilFLOLoad)}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            By registering this ticket, United Soils acknowledges the placement
            of the excess soil.
          </Typography>
        </Box>
        <div style={{ border: "1px dashed black" }} />
        <Box
          sx={{
            marginTop: 20,
          }}
        >
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            <b>United</b> Soils Management Ltd.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>14395 Ninth Line, Stouffville, ON L4A 8B3 </Typography>
          <Typography variant="h6" style={{ color: "red" }}>
            No {ticket.ticketNumber}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <QRCode value={haulageRecordUrl} size={USMQRCodeSize} />
          </Box>
          <Box
            sx={{
              marginLeft: 40,
            }}
          >
            <Typography style={{ marginBottom: 8 }}>
              <b>Haulage Co.:</b> {ticket.transportCompanyName}
            </Typography>
            <Typography>
              <b>Job Site:</b> {ticket.jobSite}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default connect()(DigitalTicketView);
