import { useMemo, useState } from "react";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { useFormikContext } from "formik";

import FormTextField from "./form-text-field";
import FormSelectSearchField from "./form-select-search-field";
import { FormJobSiteFieldProps } from "../../interface/formik/FormJobSiteFieldProps";

const FormJobSiteField = ({
  value,
  name,
  label,
  items,
  placeholder,
}: FormJobSiteFieldProps) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const [enterManually, setEnterManually] = useState(false);

  const getValue = () => {
    if (values[name]) {
      return {
        value: values[name],
        label: values[name],
      };
    }
    return null;
  };

  const getData = useMemo(() => {
    if (!items) return;
    return items.map((i) => {
      return {
        value: i,
        label: i,
      };
    });
  }, [items]);

  return (
    <>
      {enterManually ? (
        <FormTextField
          value={value}
          label={label}
          name={name}
          placeholder={placeholder ? placeholder : ""}
        />
      ) : (
        <FormSelectSearchField
          name={name}
          value={getValue()}
          options={getData}
          onChange={(state: any, { action }: any) => {
            setFieldValue(name, action === "clear" ? "" : state.value);
          }}
          placeholder={placeholder ? placeholder : ""}
          isClearable={true}
        />
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={enterManually}
                onChange={() => {
                  setFieldValue(name, "");
                  setEnterManually(!enterManually);
                }}
                color="primary"
              />
            }
            label="Enter manually"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormJobSiteField;
