import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Formik, setNestedObjectValues } from "formik";
import { Container, Typography, Box, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { orderBy as sort } from "lodash";

import { TicketFormValues } from "../../interface/formik/TicketFormValues";
import { TicketFormSchema } from "./schema/ticket-form-schema";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Ticket } from "../../interface/api/Ticket";
import {
  getTicket,
  setTicket,
  setTicketInTickets,
} from "../../store/ticketStore";
import { SetTicketAction } from "../../interface/redux-types/SetTicketAction";
import ticketService from "../../services/ticketService";
import { toast } from "react-toastify";
import contractorCompanyService from "../../services/contractorCompanyService";
import { ContractorCompany } from "../../interface/api/ContractorCompany";
import { SetContractorCompaniesAction } from "../../interface/redux-types/SetContractorCompaniesAction";
import {
  setContractorCompanies,
  setContractorCompanyContacts,
} from "../../store/contractorStore";
import contractorContactService from "../../services/contractorContactService";
import { ContractorPayload } from "../../interface/api/ContractorPayload";
import { ContractorContact } from "../../interface/api/ContractorContact";
import { SetContractorContactsAction } from "../../interface/redux-types/SetContractorContactsAction";
import transportCompanyService from "../../services/transportCompanyService";
import { TransportCompany } from "../../interface/api/TransportCompany";
import { SetTransportCompaniesAction } from "../../interface/redux-types/SetTransportCompaniesAction";
import {
  setTransportCompanies,
  setTransportCompanyContacts,
  setTransportCompanyTrucks,
} from "../../store/transportStore";
import { TransportPayload } from "../../interface/api/TransportPayload";
import truckService from "../../services/truckService";
import { Truck } from "../../interface/api/Truck";
import { SetTrucksAction } from "../../interface/redux-types/SetTrucksAction";
import { history } from "../..";
import { isAdmin } from "../../utils/constants";
import { getCurrentUser } from "../../store/userStore";
import transportContactService from "../../services/transportContactService";
import { TransportContact } from "../../interface/api/TransportContact";
import { SetTransportContactsAction } from "../../interface/redux-types/SetTransportContactsAction";
import PageHero from "../shared/page-hero";
import TicketShortView from "./ticket-short-view";
import { Load } from "../../interface/api/soilflo/Load";
import TicketSoilfloView from "./ticket-soilflo-view";
import { TicketSoilFLOFormValues } from "../../interface/formik/TicketSoilFLOFormValues";
import { UpdateSoilFLOTicketResponse } from "../../interface/api/UpdateSoilFLOTicketResponse";
import { TicketPayload } from "../../interface/api/TicketPayload";
import { TicketSoilFLOPayload } from "../../interface/api/soilflo/TicketSoilFLOPayload";
import { SaveTicketFormSchema } from "./schema/save-ticket-form-schema";
import { Material } from "../../interface/api/Material";
import { SetMaterialsAction } from "../../interface/redux-types/SetMaterialsAction";
import scaleService from "../../services/scaleService";
import { setMaterials, setMaterialTypes } from "../../store/scaleStore";
import { MaterialType } from "../../interface/api/MaterialType";
import { SetMaterialTypesAction } from "../../interface/redux-types/SetMaterialTypesAction";
import TicketHeader from "./sections/ticket-header";
import LoadingSiteInformation from "./sections/loading-site-information";
import TransportInformation from "./sections/transport-information";
import ScaleInformation from "./sections/scale-information";
import UnitedSoilsInformation from "./sections/united-soils-information";
import { removeSeconds } from "../../utils/date-helper";
import Button from "../shared/Button";
import { SetTicketInTicketsAction } from "../../interface/redux-types/SetTicketInTicketsAction";
import { ContractorCompaniesWithThresholdsResponse } from "../../interface/api/ContractorCompaniesWithThresholdsResponse";

const TicketView = ({}) => {
  const dispatch = useAppDispatch();
  const ticket = useAppSelector(getTicket);
  const user = useAppSelector(getCurrentUser);

  const { id }: any = useParams();
  const [saving, setSaving] = useState(false);
  const [isSoilFLOLoad, setIsSoilFLOLoad] = useState(false);
  const [load, setLoad] = useState<Load>();
  const [loading, setLoading] = useState(true);

  let savingForm = false;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      setLoading(false);
    };
  }, []);

  useMemo(() => {
    ticketService.getTicket(id).then((response) => {
      if (response.ok) {
        const ticket = response.data as Ticket;
        const setTicketAction: SetTicketAction = {
          ticket,
        };

        dispatch(setTicket(setTicketAction));

        if (
          ticket &&
          ticket.contractorCompanyId &&
          ticket.contractorCompanyId > 0
        ) {
          const contractorPayload: ContractorPayload = {
            contractorCompanyId: ticket.contractorCompanyId,
            contractorContact: null,
          };

          contractorContactService
            .getContractorContacts(contractorPayload)
            .then((response) => {
              if (response.ok) {
                const contractorContacts = response.data as ContractorContact[];
                const setContractorContactsAction: SetContractorContactsAction =
                  {
                    contractorContacts: contractorContacts,
                  };

                dispatch(
                  setContractorCompanyContacts(setContractorContactsAction)
                );
              } else {
                toast.error(response.originalError.message);
              }
            });
        }

        if (ticket.transportCompanyId && ticket.transportCompanyId > 0) {
          const transportPayload: TransportPayload = {
            transportCompanyId: ticket.transportCompanyId,
            truck: null,
            transportContact: null,
          };

          transportContactService
            .getTransportContacts(transportPayload)
            .then((response) => {
              if (response.ok) {
                const transportContacts = response.data as TransportContact[];
                const setTransportContactsAction: SetTransportContactsAction = {
                  transportContacts,
                };

                dispatch(
                  setTransportCompanyContacts(setTransportContactsAction)
                );
              } else {
                toast.error(response.originalError.message);
              }
            });

          truckService.getTrucks(transportPayload).then((response) => {
            if (response.ok) {
              const trucks = response.data as Truck[];
              const SetTrucksAction: SetTrucksAction = {
                trucks,
              };

              dispatch(setTransportCompanyTrucks(SetTrucksAction));
            } else {
              toast.error(response.originalError.message);
            }
          });
        }

        if (!ticket.isSoilFLOLoad) return;

        //check if ticket is SoilFLO ticket
        ticketService
          .getLoad(ticket.ticketNumber.toString())
          .then((response) => {
            if (response.ok) {
              const data = response.data as [Load];
              if (data !== null && data.length > 0) {
                const load = sort(data, (t) => t.id, "desc")?.[0];

                setIsSoilFLOLoad(true);
                setLoad(load);
              }
            }
          });
      } else {
        toast.error(response.originalError.message);
      }
    });

    contractorCompanyService.getContractorCompanies().then((response) => {
      if (response.ok) {
        const contractorCompaniesWithThresholdsResponse =
          response.data as ContractorCompaniesWithThresholdsResponse;
        const contractorCompanies =
          contractorCompaniesWithThresholdsResponse.contractorCompanies as ContractorCompany[];

        const setContractorCompaniesAction: SetContractorCompaniesAction = {
          contractorCompanies: contractorCompanies,
        };

        dispatch(setContractorCompanies(setContractorCompaniesAction));
      } else {
        toast.error(response.originalError.message);
      }
    });

    transportCompanyService.getTransportCompanies().then((response) => {
      if (response.ok) {
        const transportCompanies = response.data as TransportCompany[];
        const setTransportCompaniesAction: SetTransportCompaniesAction = {
          transportCompanies,
        };

        dispatch(setTransportCompanies(setTransportCompaniesAction));
      } else {
        toast.error(response.originalError.message);
      }
    });

    scaleService.getMaterials().then((response) => {
      if (response.ok) {
        const materials = response.data as Material[];
        const setMaterialsAction: SetMaterialsAction = {
          materials,
        };
        dispatch(setMaterials(setMaterialsAction));
      }
    });

    scaleService.getMaterialTypes().then((response) => {
      if (response.ok) {
        const materialTypes = response.data as MaterialType[];
        const setMaterialTypesAction: SetMaterialTypesAction = {
          materialTypes,
        };
        dispatch(setMaterialTypes(setMaterialTypesAction));
      }
    });
    return () => {
      setSaving(false);
    };
  }, []);

  const initialValues: TicketFormValues | null = ticket;

  const onSaveTicket = async (values: TicketFormValues) => {
    setSaving(true);

    const _values = { ...values };
    _values.timeReceived = removeSeconds(_values.timeReceived);
    _values.timeLoaded = removeSeconds(_values.timeLoaded);
    _values.date = removeSeconds(_values.date);

    const ticketPayload: TicketPayload = {
      ticket: _values as Ticket,
      loggedInUserId: user ? user.id : undefined,
      createTransportIfDoesNotExist: false,
    };

    ticketService.saveTicket(ticketPayload).then((response) => {
      if (response.ok) {
        history.push("/dashboard");
      } else {
        toast.error(response.originalError.message);
        setSaving(false);
      }
    });
  };

  const chooseValidationSchema = () => {
    if (savingForm) {
      return SaveTicketFormSchema;
    }
    return TicketFormSchema;
  };

  const onShortTicketSubmit = async (
    values: TicketFormValues,
    setSuccessful: Function
  ) => {
    const _values = { ...values };

    _values.timeLoaded = _values.timeLoaded
      ? new Date(_values.timeLoaded)
      : null;

    _values.truckLicensePlate = _values.truckLicensePlate.toLocaleUpperCase();

    const ticketPayload: TicketPayload = {
      ticket: _values as Ticket,
      loggedInUserId: undefined,
      createTransportIfDoesNotExist: false,
    };

    ticketService.saveShortTicket(ticketPayload).then((response) => {
      if (response.ok) {
        setSuccessful(true);
      } else {
        toast.error(response.originalError.message);
      }
    });
  };

  const onReceiveTicket = (values: TicketFormValues) => {
    setSaving(true);

    const ticketPayload: TicketPayload = {
      ticket: {
        ...values,
        isReceived: true,
        timeLoaded: values.timeLoaded ? values.timeLoaded : new Date(),
      } as Ticket,
      loggedInUserId: user ? user.id : undefined,
    };

    ticketService.saveTicket(ticketPayload).then((response) => {
      if (response.ok) {
        history.push("/dashboard");
      } else {
        toast.error(response.originalError.message);
      }
      setSaving(false);
    });
  };

  const onReceiveSoilFLOTicket = (values: TicketSoilFLOFormValues) => {
    setSaving(true);

    let _values = { ...values } as unknown as TicketSoilFLOPayload;

    if (values.isReceived) {
      //reset ticket received
      _values.isReceived = false;
      _values.updateSoilFLO = false;
      _values.timeReceived = null;
    } else {
      //if ticket is not received receive
      _values.isReceived = true;
      _values.updateSoilFLO = true;
      _values.timeReceived = new Date();
    }

    _values.loggedInUserId = user ? user.id : undefined;
    _values.userId = user && user.id ? user.id : null;

    ticketService.saveSoilFLOTicket(_values).then((response) => {
      if (response.ok) {
        const updateSoilFLOTicketResponse =
          response.data as UpdateSoilFLOTicketResponse;
        if (updateSoilFLOTicketResponse.success) {
          const ticket = updateSoilFLOTicketResponse.ticket;
          const setTicketInTicketsAction: SetTicketInTicketsAction = {
            ticket,
          };

          dispatch(setTicketInTickets(setTicketInTicketsAction));

          history.push("/dashboard");
        } else {
          toast.error("Failed to update SoilFLO ticket!");
        }
      } else {
        toast.error(response.originalError.message);
      }
      setSaving(false);
    });
  };

  const onSaveSoilFLOTicket = (values: TicketSoilFLOFormValues) => {
    setSaving(true);

    let _values = { ...values } as unknown as TicketSoilFLOPayload;

    _values.loggedInUserId = user ? user.id : undefined;
    _values.timeReceived = _values.timeReceived
      ? new Date(_values.timeReceived)
      : null;

    ticketService.saveSoilFLOTicket(_values).then((response) => {
      if (response.ok) {
        history.push("/dashboard");
      } else {
        toast.error(response.originalError.message);
      }
      setSaving(false);
    });
  };

  const onReset = () => {
    setSaving(true);

    var ticketPayload: TicketPayload = {
      ticket: initialValues as Ticket,
      loggedInUserId: user ? user.id : undefined,
    };

    ticketService.resetTicket(ticketPayload).then((response) => {
      if (response.ok) {
        history.push("/dashboard");
      } else {
        toast.error(response.originalError.message);
      }
      setSaving(false);
    });
  };

  const LoadingView = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <Typography variant="h3">Loading...</Typography>
      </Box>
    );
  };

  if (!initialValues || loading) {
    return <LoadingView />;
  }

  if (!user) {
    return (
      <TicketShortView
        initialValues={initialValues}
        onShortTicketSubmit={onShortTicketSubmit}
      />
    );
  }

  if (load && isSoilFLOLoad) {
    return (
      <TicketSoilfloView
        ticketFormValues={initialValues}
        onReceiveSoilFLOTicket={onReceiveSoilFLOTicket}
        user={user}
        onSaveSoilFLOTicket={onSaveSoilFLOTicket}
        saving={saving}
        load={load}
      />
    );
  }

  return (
    <Container maxWidth="xl">
      <PageHero
        title="Edit Ticket"
        subtitle="Edit Ticket Information"
        showBackButton={true}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={chooseValidationSchema}
        onSubmit={(values) => onReceiveTicket(values)}
        enableReinitialize={true}
        validateOnChange={true}
      >
        {({ values, handleSubmit, validateForm, setTouched }) => (
          <form onSubmit={handleSubmit}>
            <TicketHeader />
            <LoadingSiteInformation />
            <TransportInformation />
            <ScaleInformation />
            <UnitedSoilsInformation />
            <Box
              sx={{
                marginTop: 30,
                marginBottom: 60,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  {isAdmin(user.roleId) && (
                    <Button
                      label="Save"
                      type="button"
                      color="primary"
                      variant="contained"
                      disabled={saving}
                      onClick={() => {
                        savingForm = true;
                        validateForm().then((errors) => {
                          if (Object.keys(errors).length > 0) {
                            setTouched(setNestedObjectValues(errors, true));
                            return;
                          }
                          onSaveTicket(values);
                        });
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2}>
                  {!values.isReceived && (
                    <Button
                      label={"Receive Ticket"}
                      color="default"
                      variant="contained"
                      type="submit"
                      disabled={saving}
                      onClick={() => (savingForm = false)}
                    />
                  )}
                  {values.isReceived && (
                    <Button
                      label={"Reset Receive"}
                      color="secondary"
                      variant="contained"
                      type="button"
                      disabled={saving}
                      onClick={() => onReset()}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default connect()(TicketView);
