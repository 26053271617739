import { create } from "apisauce";
import { toast } from "react-toastify";
import { history } from "..";

const api = create({
  baseURL: window.location.origin,
});

api.axiosInstance.interceptors.response.use(undefined, (error) => {
  const ignoreRoutes = ["/api/user/all"];

  if (!ignoreRoutes.includes(error.response.config.url)) {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedError) {
      toast.error("An unexpected error occurred.");
    }

    if (
      (error.response.status === 401 &&
        !ignoreRoutes.includes(error.response.config.url)) ||
      error.response.status === 403
    ) {
      history.push("/login");
    }
  }

  return Promise.reject(error);
});

const setJwt = (jwt: string | null) => {
  if (jwt) {
    api.setHeader("X-auth-token", jwt);
    api.setHeader("Authorization", "Bearer " + jwt);
  }
};

export default {
  get: api.get,
  post: api.post,
  put: api.put,
  delete: api.delete,
  setJwt,
};
