import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { orderBy as sort } from "lodash";

import { RootState } from "..";
import { SetTransportCompaniesAction } from "../interface/redux-types/SetTransportCompaniesAction";
import { SetTransportCompanyAction } from "../interface/redux-types/SetTransportCompanyAction";
import { SetTransportContactAction } from "../interface/redux-types/SetTransportContactAction";
import { SetTransportContactsAction } from "../interface/redux-types/SetTransportContactsAction";
import { SetTruckAction } from "../interface/redux-types/SetTruckAction";
import { SetTrucksInTruckAction } from "../interface/redux-types/SetTruckInTrucksAction";
import { SetTrucksAction } from "../interface/redux-types/SetTrucksAction";
import { TransportState } from "../interface/redux-types/TransportState";

const initialState: TransportState = {
  transportCompanies: [],
  transport: {
    transportCompany: null,
    trucks: [],
    truck: null,
    transportContacts: [],
    transportContact: null,
  },
  trucks: [],
};

const slice = createSlice({
  name: "transportStore",
  initialState,
  reducers: {
    setTransportCompanies: (
      state,
      action: PayloadAction<SetTransportCompaniesAction>
    ) => {
      state.transportCompanies = action.payload.transportCompanies;
    },

    setTransportCompany: (
      state,
      action: PayloadAction<SetTransportCompanyAction>
    ) => {
      state.transport.transportCompany = action.payload.transportCompany;
    },

    setTransportCompanyTrucks: (
      state,
      action: PayloadAction<SetTrucksAction>
    ) => {
      state.transport.trucks = action.payload.trucks;
    },

    setTrucks: (state, action: PayloadAction<SetTrucksAction>) => {
      state.trucks = action.payload.trucks;
    },

    setTransportCompanyTruck: (
      state,
      action: PayloadAction<SetTruckAction>
    ) => {
      state.transport.truck = action.payload.truck;
    },

    setTransportCompanyContacts: (
      state,
      action: PayloadAction<SetTransportContactsAction>
    ) => {
      state.transport.transportContacts = action.payload.transportContacts;
    },

    setTransportCompanyContact: (
      state,
      action: PayloadAction<SetTransportContactAction>
    ) => {
      state.transport.transportContact = action.payload.transportContact;
    },

    clearTransportCompanyContact: (state) => {
      state.transport.transportContact = null;
    },

    clearTransportCompany: (state) => {
      state.transport.transportCompany = null;
    },

    clearTransportCompanyTruck: (state) => {
      state.transport.truck = null;
    },
    clearTransportCompanyTrucks: (state) => {
      state.transport.trucks = [];
    },
    clearTransportCompanyContacts: (state) => {
      state.transport.transportContacts = [];
    },

    setTruckInTrucks: (
      state,
      action: PayloadAction<SetTrucksInTruckAction>
    ) => {
      const { truck } = action.payload;

      const index = state.trucks.findIndex((t) => t.id === truck.id);
      state.trucks[index] = truck;
    },
  },
});

export const {
  setTransportCompanies,
  setTransportCompany,
  setTransportCompanyTrucks,
  setTrucks,
  setTransportCompanyTruck,
  setTransportCompanyContacts,
  setTransportCompanyContact,
  clearTransportCompany,
  clearTransportCompanyContact,
  clearTransportCompanyTruck,
  setTruckInTrucks,
  clearTransportCompanyContacts,
  clearTransportCompanyTrucks,
} = slice.actions;

export const getTransportCompanies = createSelector(
  (state: RootState) => state.transportStore.transportCompanies,
  (transportCompanies) => {
    return sort(transportCompanies, (t) => t.name, "asc");
  }
);

export const getTransportCompany = createSelector(
  (state: RootState) => state.transportStore.transport.transportCompany,
  (transportCompany) => {
    return transportCompany;
  }
);

export const getTransportCompanyTrucks = createSelector(
  (state: RootState) => state.transportStore.transport.trucks,
  (trucks) => {
    return trucks;
  }
);

export const getTrucks = createSelector(
  (state: RootState) => state.transportStore.trucks,
  (trucks) => {
    return trucks;
  }
);

export const getTransportCompanyTruck = createSelector(
  (state: RootState) => state.transportStore.transport.truck,
  (truck) => {
    return truck;
  }
);

export const getTransportCompanyContacts = createSelector(
  (state: RootState) => state.transportStore.transport.transportContacts,
  (transportContacts) => {
    return transportContacts;
  }
);

export const getTransportCompanyContact = createSelector(
  (state: RootState) => state.transportStore.transport.transportContact,
  (transportContact) => {
    return transportContact;
  }
);

export const reducer = slice.reducer;
