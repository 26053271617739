import { JWTToken } from "./../interface/JWTToken";
import http from "./httpService";
import jwtDecode from "jwt-decode";

const tokenKey = "token";

export const login = async (username: string, password: string) => {
  await http
    .post("/api/auth/Authenticate", { username, password })
    .then((response) => {
      if (response.ok) {
        const jwt = response.data as string;

        http.setJwt(jwt);
        localStorage.setItem(tokenKey, jwt);
      } else {
        if (response.status === 400) {
          throw Error("User does not exist.");
        } else {
          throw Error(response.originalError.message);
        }
      }
    });
};

export const loginWithJwt = (jwt: string) => {
  localStorage.setItem(tokenKey, jwt);
};

export const logout = async () => {
  localStorage.removeItem(tokenKey);
};

export const getCurrentUser = (): JWTToken | null | undefined => {
  try {
    const jwt = localStorage.getItem(tokenKey);
    if (jwt) {
      return jwtDecode(jwt) as JWTToken;
    }
  } catch (ex) {
    return null;
  }
};

export const getJwt = () => {
  return localStorage.getItem(tokenKey);
};

export default {
  login,
  logout,
  getCurrentUser,
  loginWithJwt,
  getJwt,
};
