import { useMemo, useRef, useEffect, BaseSyntheticEvent } from "react";
import { Typography, MenuItem, Box, Grid } from "@material-ui/core";
import { useFormikContext } from "formik";

import { TicketFormValues } from "../../../interface/formik/TicketFormValues";
import FormErrorMessage from "../../shared/form-error-message";
import FormSelect from "../../shared/form-select";
import FormTextField from "../../shared/form-text-field";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getContractorCompanies,
  getContractorCompanyContacts,
  getContractorProjects,
  setContractorCompanyContacts,
  setContractorProjects,
} from "../../../store/contractorStore";
import { toast } from "react-toastify";
import { ContractorContact } from "../../../interface/api/ContractorContact";
import { ContractorPayload } from "../../../interface/api/ContractorPayload";
import { SetContractorContactsAction } from "../../../interface/redux-types/SetContractorContactsAction";
import contractorContactService from "../../../services/contractorContactService";
import FormDateTimePicker from "../../shared/form-date-time-picker";
import PhoneInput from "../../shared/phone-input";
import FormJobSiteField from "../../shared/form-job-site-field";
import { Project } from "../../../interface/api/Project";
import { SetContractorProjectsAction } from "../../../interface/redux-types/SetContractorProjectsAction";
import contractorProjectService from "../../../services/contractorProjectService";

const LoadingSiteInformation = () => {
  const { values, setFieldValue, handleChange } =
    useFormikContext<TicketFormValues>();

  const dispatch = useAppDispatch();
  const contractorCompanies = useAppSelector(getContractorCompanies);
  const contractorCompanyContacts = useAppSelector(
    getContractorCompanyContacts
  );
  const contractorProjects = useAppSelector(getContractorProjects);
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
  });

  useMemo(() => {
    if (!values.contractorCompanyId) return;

    const { contractorCompanyId } = values;

    contractorProjectService
      .getContractorProjects(contractorCompanyId)
      .then((response) => {
        if (response.ok) {
          const projects = response.data as Project[];
          const setProjectsAction: SetContractorProjectsAction = {
            projects,
          };

          dispatch(setContractorProjects(setProjectsAction));
        } else {
          toast.error(response.originalError.message);
        }
      });
  }, [values.contractorCompanyId]);

  useMemo(() => {
    setTimeout(() => {
      if (!values.contractorCompanyId || firstRender.current) return;

      const { contractorCompanyId } = values;
      const contractorCompany = contractorCompanies.find(
        (c) => c.id === contractorCompanyId
      );

      setFieldValue(
        "contractorCompanyName",
        contractorCompany ? contractorCompany.name : ""
      );

      setFieldValue("contractorOfficeContactId", 0);
      updateContractorCompanyContacts(contractorCompanyId);
    }, 10);
  }, [values.contractorCompanyId]);

  const onContractorProjectChange = (
    e: BaseSyntheticEvent,
    setFieldValue: Function
  ) => {
    setFieldValue("projectId", e.target.value);
    setFieldValue("jobSite", "");
  };

  const updateContractorCompanyContacts = (contractorCompanyId: number) => {
    const contractorPayload: ContractorPayload = {
      contractorCompanyId: contractorCompanyId,
      contractorContact: null,
    };

    contractorContactService
      .getContractorContacts(contractorPayload)
      .then((response) => {
        if (response.ok) {
          const contractorContacts = response.data as ContractorContact[];
          const setContractorContactsAction: SetContractorContactsAction = {
            contractorContacts: contractorContacts,
          };

          dispatch(setContractorCompanyContacts(setContractorContactsAction));
          setFieldValue(
            "contractorOfficeContactId",
            values.contractorOfficeContactId
          );
        } else {
          toast.error(response.originalError.message);
        }
      });
  };

  return (
    <>
      <Box
        sx={{
          marginBottom: "25px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">Loading Site Information</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormSelect
              name="contractorCompanyId"
              label={"Contractor"}
              value={values.contractorCompanyId}
              items={[
                contractorCompanies.map((contractorCompany, index) => (
                  <MenuItem key={index} value={contractorCompany.id}>
                    {contractorCompany.name}
                  </MenuItem>
                )),
              ]}
              onChange={handleChange}
            />
            <FormErrorMessage name="contractorCompanyId" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormSelect
              name="projectId"
              label={"Project ID"}
              value={values.projectId ? values.projectId : 0}
              items={[
                contractorProjects.map((contractorProject, index) => (
                  <MenuItem key={index} value={contractorProject.id}>
                    {contractorProject.projectId}
                  </MenuItem>
                )),
              ]}
              onChange={(e: BaseSyntheticEvent) =>
                onContractorProjectChange(e, setFieldValue)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormJobSiteField
              name="jobSite"
              label="Job Site"
              value={values.jobSite}
              items={
                values.projectId
                  ? contractorProjects
                      .filter((p) => p.id === values.projectId)
                      .map((c) => c.jobSite)
                  : contractorProjects.map((c) => c.jobSite)
              }
              placeholder="Job Site"
            />
            <FormErrorMessage name="jobSite" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormTextField
              name="projectLeader"
              label="Project Leader"
              value={values.projectLeader}
            />
            <FormErrorMessage name="projectLeader" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormTextField
              name="siteContactName"
              label="Site Contact Name"
              value={values.siteContactName}
            />
            <FormErrorMessage name="siteContactName" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PhoneInput
              name="siteContactNumber"
              label="Site Contact Number"
              value={values.siteContactNumber}
            />
            <FormErrorMessage name="siteContactNumber" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormSelect
              name="contractorOfficeContactId"
              label={"Office Contact"}
              value={values.contractorOfficeContactId}
              items={[
                contractorCompanyContacts
                  .filter((c) => c.isOfficeContact)
                  .map((contact, index) => (
                    <MenuItem key={index} value={contact.id}>
                      {contact.name} {contact.number}
                    </MenuItem>
                  )),
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormDateTimePicker
              label="Time Loaded"
              value={values.timeLoaded ? new Date(values.timeLoaded) : null}
              onChange={(date: any) => {
                setFieldValue("timeLoaded", date, true);
              }}
            />
            <FormErrorMessage name="timeLoaded" />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LoadingSiteInformation;
