import { Route, Redirect } from "react-router-dom";

import { ProtectedRouteProps } from "../interface/ProtectedRouteProps";
import { getCurrentUser } from "../services/authService";

const ProtectedRoute = ({
  component: Component,
  ...rest
}: ProtectedRouteProps) => {
  const jwtTokenExpired = () => {
    const jwt = getCurrentUser();
    const current_time = new Date().getTime() / 1000;

    if (jwt && current_time > jwt.exp) {
      return true;
    }
    return false;
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!getCurrentUser() || jwtTokenExpired())
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
