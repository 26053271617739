import { Grid } from "@material-ui/core";
import { Formik } from "formik";

import FormErrorMessage from "../../shared/form-error-message";
import FormTextField from "../../shared/form-text-field";
import { TicketsExcelExportFormProps } from "../../../interface/forms/TicketsExcelExportFormProps";
import { GenerateExcelExportFormSchema } from "../schema/generate-excel-export-form-schema";
import Button from "../../shared/Button";

const TicketsExcelExportForm = ({
  generateExcelExportFormValues,
  onSubmit,
}: TicketsExcelExportFormProps) => {
  return (
    <Formik
      initialValues={generateExcelExportFormValues}
      validationSchema={GenerateExcelExportFormSchema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} lg={3}>
                <FormTextField
                  name={"from"}
                  label={"From Ticket Number"}
                  value={values.from}
                  placeholder="From Ticket Number"
                />
                <FormErrorMessage name="from" />
              </Grid>
              <Grid item xs={6} sm={6} lg={3}>
                <FormTextField
                  name={"to"}
                  label={"To Ticket Number"}
                  value={values.to}
                  placeholder="To Ticket Number"
                />
                <FormErrorMessage name="to" />
              </Grid>
              <Grid item lg={2} />
              <Grid item xs={12} lg={2}>
                <Button
                  label={
                    isSubmitting
                      ? "Generating Export..."
                      : "Generate Excel Export"
                  }
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                />
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </Formik>
  );
};

export default TicketsExcelExportForm;
