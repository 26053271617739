import { useMemo } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Container, Typography, Box, Grid, MenuItem } from "@material-ui/core";

import { isAdmin } from "../../utils/constants";
import PageHero from "../shared/page-hero";
import { TicketSoilFLOViewProps } from "../../interface/TicketSoilFLOViewProps";
import { TicketSoilFLOFormValues } from "../../interface/formik/TicketSoilFLOFormValues";
import FormTextField from "../shared/form-text-field";
import FormSelect from "../shared/form-select";
import Button from "../shared/Button";
import { SoilFLOIcon } from "../shared/icons";
import FormDateTimePicker from "../shared/form-date-time-picker";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { User } from "../../interface/api/User";
import { SetUsersAction } from "../../interface/redux-types/SetUsersAction";
import userService from "../../services/userService";
import { getUsers, setUsers } from "../../store/userStore";

const TicketSoilFLOView = ({
  ticketFormValues,
  onReceiveSoilFLOTicket,
  user,
  onSaveSoilFLOTicket,
  saving,
  load,
}: TicketSoilFLOViewProps) => {
  const dispatch = useAppDispatch();

  const users = useAppSelector(getUsers);

  useMemo(() => {
    userService.getUsers().then((response) => {
      if (response.ok) {
        const users = response.data as User[];
        const setUsersAction: SetUsersAction = {
          users,
        };
        dispatch(setUsers(setUsersAction));
      }
    });
  }, []);

  const initialValues: TicketSoilFLOFormValues = {
    id: ticketFormValues.id,
    ticketNumber: ticketFormValues.ticketNumber,
    soilFLOData: { ...load },
    date: new Date(ticketFormValues.date ? ticketFormValues.date : ""),
    userFirstName: "",
    userLastName: "",
    userPhoneNumber: "",
    timeReceived: ticketFormValues.timeReceived,
    isReceived: ticketFormValues.isReceived,
    userId: ticketFormValues.userId,
  };

  return (
    <Container maxWidth="xl">
      <PageHero
        title="Edit Ticket"
        subtitle="Edit Ticket Information"
        showBackButton={true}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onReceiveSoilFLOTicket(values)}
        enableReinitialize={true}
      >
        {({ values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                marginBottom: 25,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    SoilFLO Load <SoilFLOIcon />
                  </Typography>
                  <Typography>
                    To update fields in this ticket, do so in SoilFLO dashboard
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormTextField
                    name="id"
                    label="Ticket Id"
                    value={values.id}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormTextField
                    name="ticketNumber"
                    label="Ticket Number"
                    value={values.ticketNumber}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                marginBottom: 25,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">Loading Site Information</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormTextField
                    label="Job Site"
                    value={values?.soilFLOData?.sourceSite?.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormTextField
                    label="Contractor"
                    value={
                      values?.soilFLOData?.sourceSite?.contractingcompany?.name
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormTextField
                    label="Site Contact Name"
                    value={
                      values?.soilFLOData?.assignerUser
                        ? `${values?.soilFLOData?.assignerUser?.firstName} ${values?.soilFLOData?.assignerUser?.lastName} [${values?.soilFLOData?.assignerUser?.phone}]`
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormDateTimePicker
                    label={"Time Loaded"}
                    value={
                      values?.soilFLOData?.dispatchedTime
                        ? new Date(values?.soilFLOData?.dispatchedTime)
                        : null
                    }
                    readOnly
                    onChange={undefined}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                marginBottom: 25,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h5">Transport Information</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormTextField
                    label="Haulage Co."
                    value={
                      values?.soilFLOData?.truck?.global_truck?.hauler?.name
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormTextField
                    label="Truck"
                    value={values?.soilFLOData?.truck?.global_truck?.number}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormTextField
                    label="Truck Driver Name"
                    value={values?.soilFLOData?.truck?.driverName}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormTextField
                    label="Truck Driver Number"
                    value={values?.soilFLOData?.truck?.driverPhone}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormTextField
                    label="Truck License Plate"
                    value={values?.soilFLOData?.truck?.global_truck?.license
                      ?.trim()
                      ?.replace(" ", "")}
                  />
                </Grid>
              </Grid>
            </Box>
            {values.isReceived && (
              <>
                <Box
                  sx={{
                    marginBottom: 25,
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        United Soils Information
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormSelect
                        name="userId"
                        label={"Receiving Contact"}
                        value={values.userId}
                        items={[
                          users.map((user, index) => (
                            <MenuItem key={index} value={user.id}>
                              {user.firstName} {user.lastName} (
                              {user.phoneNumber})
                            </MenuItem>
                          )),
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} md={4} lg={2}>
                {isAdmin(user.roleId) && (
                  <Button
                    label="Save"
                    color="primary"
                    variant="contained"
                    disabled={saving}
                    onClick={() => onSaveSoilFLOTicket(values)}
                    type="button"
                  />
                )}
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={2}>
                <Button
                  label={values.isReceived ? "Reset Receive" : "Receive Ticket"}
                  color={values.isReceived ? "secondary" : "default"}
                  variant="contained"
                  type="submit"
                  disabled={saving}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default connect()(TicketSoilFLOView);
