import { Formik } from "formik";
import { useMemo } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Container, Grid } from "@material-ui/core";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import { history } from "../../..";
import { MaterialTypeFormSchema } from "../schema/material-type-form-schema";
import PageHero from "../../shared/page-hero";
import scaleService from "../../../services/scaleService";
import { getMaterialType, setMaterialType } from "../../../store/scaleStore";
import { SetMaterialTypeAction } from "../../../interface/redux-types/SetMaterialTypeAction";
import { MaterialType } from "../../../interface/api/MaterialType";
import { MaterialTypeFormValues } from "../../../interface/formik/MaterialTypeFormValues";
import FormErrorMessage from "../../shared/form-error-message";
import FormTextField from "../../shared/form-text-field";
import Button from "../../shared/Button";

const MaterialTypeView = ({}) => {
  const { id }: any = useParams();
  const dispatch = useAppDispatch();
  const materialType = useAppSelector(getMaterialType);

  useMemo(() => {
    if (id > 0) {
      scaleService.getMaterialType(id).then((response) => {
        if (response.ok) {
          const materialType = response.data as MaterialType;
          const setMaterialTypeAction: SetMaterialTypeAction = {
            materialType,
          };
          dispatch(setMaterialType(setMaterialTypeAction));
        } else {
          toast.error(response.originalError.message);
        }
      });
    }
  }, []);

  const onSubmit = (
    values: MaterialTypeFormValues,
    setSubmitting: Function
  ) => {
    scaleService.saveMaterialType(values as MaterialType).then((response) => {
      if (response.ok) {
        history.goBack();
      } else {
        const data = response.data as string;
        toast.error(data);
        setSubmitting(false);
      }
    });

    setSubmitting(false);
  };

  const initialValues: MaterialTypeFormValues = materialType
    ? materialType
    : {
        id: 0,
        name: "",
      };

  return (
    <Container maxWidth="xl">
      <PageHero
        title={
          id > 0
            ? `${materialType ? materialType.name : id}`
            : "New Material Type"
        }
        subtitle={id > 0 ? `Edit Material Type.` : "Add Material Type."}
        showBackButton={true}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={MaterialTypeFormSchema}
        onSubmit={(values, { setSubmitting }) =>
          onSubmit(values, setSubmitting)
        }
        enableReinitialize={true}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormTextField name="name" label="Name" value={values.name} />
                <FormErrorMessage name="name" />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  label="Save"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default connect()(MaterialTypeView);
