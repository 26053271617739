import React, { useMemo, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import PageHero from "../shared/page-hero";
import { SetScalesAction } from "../../interface/redux-types/SetScalesAction";
import scaleService from "../../services/scaleService";
import { setScales, setMaterials } from "../../store/scaleStore";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { toast } from "react-toastify";
import settingService from "../../services/settingService";
import { SetSettingAction } from "../../interface/redux-types/SetSettingAction";
import { getSetting, setSetting } from "../../store/settingStore";
import { Setting } from "../../interface/api/Setting";
import { TransportPayload } from "../../interface/api/TransportPayload";
import { Truck } from "../../interface/api/Truck";
import truckService from "../../services/truckService";
import { setTrucks, setTruckInTrucks } from "../../store/transportStore";
import { SetTrucksAction } from "../../interface/redux-types/SetTrucksAction";
import { Formik } from "formik";
import { setTicketInTickets, setTickets } from "../../store/ticketStore";
import { Ticket } from "../../interface/api/Ticket";
import ticketService from "../../services/ticketService";
import { Material } from "../../interface/api/Material";
import { SetMaterialsAction } from "../../interface/redux-types/SetMaterialsAction";
import { ScaleViewType } from "../../utils/constants";
import { SetTrucksInTruckAction } from "../../interface/redux-types/SetTruckInTrucksAction";
import { getCurrentUser } from "../../store/userStore";
import { TicketScalePayload } from "../../interface/api/TicketScalePayload";
import { TruckScaleFormValues } from "../../interface/formik/ScaleFormValues";
import { TruckScaleFormSchema } from "./schema/truck-scale-form-schema";
import TruckScale from "./truck-scale";
import { Scale } from "../../interface/api/Scale";
import { SetTicketInTicketsAction } from "../../interface/redux-types/SetTicketInTicketsAction";
import ConfirmUpdateWeightDialog from "../shared/dialog/confirm-update-weight";
import { TareWeightWithTicketPayload } from "../../interface/api/TareWeightWithTicketPayload";
import { TareWeightWithTicketResponse } from "../../interface/api/TareWeightWithTicketResponse";
import { SetTicketsAction } from "../../interface/redux-types/SetTicketsAction";

const ScaleView = ({}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getCurrentUser);
  const setting = useAppSelector(getSetting);

  const [selectedScale, setSelectedScale] = useState<Scale>();
  const [weighing, setWeighing] = useState(false);
  const [weight, setWeight] = useState(0);
  const [selectedScaleViewType, setSelectedScaleViewType] = useState(
    ScaleViewType.WeighIn
  );
  const [manuallyUpdatedWeight, setManuallyUpdatedWeight] = useState(false);
  const [tareTruckWithTicket, setTareTruckWithTicket] = useState(false);
  const [open, setOpen] = useState(false);

  const getLatestActiveTickets = () => {
    ticketService.getLatestActiveTickets().then((response) => {
      if (response.ok) {
        const tickets = response.data as Ticket[];
        const setTicketsAction: SetTicketsAction = {
          tickets,
        };
        dispatch(setTickets(setTicketsAction));
      } else {
        toast.error(response.originalError.message);
      }
    });
  };

  useMemo(() => {
    getLatestActiveTickets();
  }, []);

  useMemo(() => {
    const transportPayload: TransportPayload = {
      transportCompanyId: null,
      truck: null,
      transportContact: null,
    };

    truckService.getTrucks(transportPayload).then((response) => {
      if (response.ok) {
        const trucks = response.data as Truck[];
        const SetTrucksAction: SetTrucksAction = {
          trucks,
        };

        dispatch(setTrucks(SetTrucksAction));
      } else {
        toast.error(response.originalError.message);
      }
    });
  }, []);

  useEffect(() => {
    if (!weighing || !selectedScale) return;
    let interval = setInterval(async () => {
      scaleService.getScale(selectedScale.id).then((response) => {
        const scale = response.data as Scale;
        setWeight(scale.currentWeight);
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [weighing]);

  useMemo(() => {
    scaleService.getScales().then((response) => {
      if (response.ok) {
        const scales = response.data as Scale[];
        const setScalesAction: SetScalesAction = {
          scales,
        };
        setSelectedScale(scales[0]);
        dispatch(setScales(setScalesAction));
      }
    });

    settingService.getSetting().then((response) => {
      if (response.ok) {
        const setting = response.data as Setting;
        const setSettingAction: SetSettingAction = {
          setting,
        };
        dispatch(setSetting(setSettingAction));
      }
    });

    scaleService.getMaterials().then((response) => {
      if (response.ok) {
        const materials = response.data as Material[];
        const setMaterialsAction: SetMaterialsAction = {
          materials,
        };
        dispatch(setMaterials(setMaterialsAction));
      }
    });
  }, []);

  const getNetWeight = (truck: Truck | null) => {
    const tareWeight = truck?.tareWeight
      ? truck?.tareWeight
      : setting.defaultTareWeight;

    return Number(weight - tareWeight).toFixed(2);
  };

  const onTruckTare = (
    values: TruckScaleFormValues,
    setSubmitting: Function,
    setFieldValue: Function
  ) => {
    const { truck } = values;
    if (!truck) return;

    const transportPayload: TransportPayload = {
      transportCompanyId: truck.transportCompanyId,
      truck: { ...truck, tareWeight: weight },
      transportContact: null,
    };

    truckService
      .updateTareWeight(transportPayload)
      .then((response) => {
        if (response.ok) {
          toast.success(`Tare weight set to ${weight} for ${truck.number}`);
          const _truck = response.data as Truck;
          const setTrucksInTruckAction: SetTrucksInTruckAction = {
            truck: _truck,
          };
          setFieldValue("truck", _truck);
          dispatch(setTruckInTrucks(setTrucksInTruckAction));
        } else {
          toast.error(`Failed to update ${truck.number}`);
          setSubmitting(false);
        }
      })
      .catch((_e) => {
        toast.error(`Failed to update ${truck.number}`);
        setSubmitting(false);
      });
  };

  const onScaleViewTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedScaleViewType((event.target as HTMLInputElement).value);
  };

  const onWeighIn = (values: TruckScaleFormValues, setFieldValue: Function) => {
    const { ticket } = values;
    if (!ticket) return;

    const ticketScalePayload: TicketScalePayload = {
      loggedInUserId: user ? user.id : undefined,
      materialId: values.materialId,
      weight: Number(getNetWeight(values.truck)),
      ticketId: ticket.id,
    };

    ticketService.weighInTicket(ticketScalePayload).then((response) => {
      if (response.ok) {
        toast.success(`Ticket updated successfully`);
        const _ticket = response.data as Ticket;
        const setTicketInTicketsAction: SetTicketInTicketsAction = {
          ticket: _ticket,
        };
        setFieldValue("ticket", _ticket);
        dispatch(setTicketInTickets(setTicketInTicketsAction));
      } else {
        toast.error(response.originalError.message);
      }
    });
  };

  const isWeighIn = () => {
    return selectedScaleViewType === ScaleViewType.WeighIn;
  };

  const onWeightChange = (value: number) => {
    setManuallyUpdatedWeight(true);
    setWeight(value);
  };

  const onTareTruckWithTicket = (
    values: TruckScaleFormValues,
    setSubmitting: Function,
    setFieldValue: Function
  ) => {
    const { truck, ticket } = values;
    if (!truck) return;

    if (!ticket) return;

    const tareWeightWithTicketPayload: TareWeightWithTicketPayload = {
      ticketId: ticket.id,
      scaleWeight: weight,
      truckId: truck.id,
    };

    truckService
      .tareWeightWithTicket(tareWeightWithTicketPayload)
      .then((response) => {
        if (response.ok) {
          const tareWeightWithTicketResponse =
            response.data as TareWeightWithTicketResponse;

          var _truck = tareWeightWithTicketResponse.truck;
          var _ticket = tareWeightWithTicketResponse.ticket;

          toast.success(
            `Tare weight set to ${weight} KG for ${_truck.number}, ticket number ${_ticket.ticketNumber} weight updated to ${_ticket.weight} KG`
          );

          const setTrucksInTruckAction: SetTrucksInTruckAction = {
            truck: _truck,
          };
          setFieldValue("truck", _truck);
          dispatch(setTruckInTrucks(setTrucksInTruckAction));

          const setTicketInTicketsAction: SetTicketInTicketsAction = {
            ticket: _ticket,
          };
          setFieldValue("ticket", _ticket);
          dispatch(setTicketInTickets(setTicketInTicketsAction));
        } else {
          toast.error(`Failed to update ${truck.number}`);
          setSubmitting(false);
        }
      })
      .catch((_e) => {
        toast.error(`Failed to update ${truck.number}`);
        setSubmitting(false);
      });
  };

  const onSubmit = (
    values: TruckScaleFormValues,
    setSubmitting: Function,
    setFieldValue: Function
  ) => {
    if (selectedScaleViewType === ScaleViewType.WeighIn) {
      onWeighIn(values, setFieldValue);
    } else {
      if (tareTruckWithTicket) {
        setTareTruckWithTicket(false);
        onTareTruckWithTicket(values, setSubmitting, setFieldValue);
      } else {
        onTruckTare(values, setSubmitting, setFieldValue);
      }
    }
  };

  const onClose = () => setOpen(false);

  const scaleFormValues: TruckScaleFormValues = {
    scaleId: selectedScale ? selectedScale.id : 0,
    ticket: null,
    truck: null,
    materialId: 0,
  };

  return (
    <Container maxWidth="xl">
      <PageHero
        title="Scale"
        subtitle="Weigh and Tare Trucks."
        showBackButton={false}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <FormControl>
                <RadioGroup
                  row
                  value={selectedScaleViewType}
                  onChange={onScaleViewTypeChange}
                >
                  <FormControlLabel
                    value={ScaleViewType.WeighIn}
                    control={<Radio color="primary" />}
                    label="Weigh In"
                  />
                  <FormControlLabel
                    value={ScaleViewType.Tare}
                    control={<Radio color="primary" />}
                    label="Tare"
                  />
                </RadioGroup>
              </FormControl>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={scaleFormValues}
            validationSchema={TruckScaleFormSchema}
            onSubmit={(values, { setSubmitting, setFieldValue }) => {
              if (manuallyUpdatedWeight) {
                setOpen(true);
                setManuallyUpdatedWeight(false);
              } else {
                onSubmit(values, setSubmitting, setFieldValue);
              }
            }}
            enableReinitialize={true}
          >
            <>
              <TruckScale
                isWeighIn={isWeighIn}
                weighing={weighing}
                setWeighing={setWeighing}
                weight={weight}
                onWeightChange={onWeightChange}
                getNetWeight={getNetWeight}
                setTareTruckWithTicket={setTareTruckWithTicket}
              />
              <ConfirmUpdateWeightDialog open={open} onClose={onClose} />
            </>
          </Formik>
        </Grid>
      </Grid>
    </Container>
  );
};

export default connect()(ScaleView);
