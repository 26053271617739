import http from "./httpService";
import auth from "./authService";
import { ContractorCompany } from "../interface/api/ContractorCompany";

http.setJwt(auth.getJwt());

export const deleteContractorCompany = (id: number | string) => {
  return http.delete(`/api/contractorcompany/${id}`);
};

export const getContractorCompany = (id: number | string) => {
  return http.get(`/api/contractorcompany/${id}`);
};

export const getContractorCompanies = () => {
  return http.get("/api/contractorcompany/all");
};

export function saveContractorCompany(contractorCompany: ContractorCompany) {
  if (contractorCompany.id > 0) {
    return http.put("/api/contractorcompany/edit", contractorCompany);
  }
  return http.post("/api/contractorcompany/new", contractorCompany);
}

export default {
  deleteContractorCompany,
  getContractorCompany,
  getContractorCompanies,
  saveContractorCompany,
};
