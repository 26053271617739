import { MenuItem, Grid } from "@material-ui/core";
import { Formik } from "formik";
import { connect } from "react-redux";

import FormSelect from "../../shared/form-select";
import FormTextField from "../../shared/form-text-field";
import InputSubtitle from "../../shared/input-subtitle";
import FormTruckLicensePlateField from "../../shared/form-truck-license-plate-field";
import { SearchTicketsFormSchema } from "../schema/search-tickets-form-schema";
import { SearchTicketsFormProps } from "../../../interface/forms/SearchTicketsFormProps";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { getContractorCompanies } from "../../../store/contractorStore";
import { getTransportCompanies } from "../../../store/transportStore";
import Button from "../../shared/Button";
import useFocusSearch from "../../../hooks/useFocusSearch";
import FormErrorMessage from "../../shared/form-error-message";

const SearchTicketsForm = ({
  searchTicketsFormValues,
  onSubmit,
  onSearchTicketNumberChange,
  onClear,
  searchFormRef,
}: SearchTicketsFormProps) => {
  const contractorCompanies = useAppSelector(getContractorCompanies);
  const transportCompanies = useAppSelector(getTransportCompanies);
  const { focusOnSearchInput } = useFocusSearch("ticketNumber");

  return (
    <Formik
      innerRef={searchFormRef}
      initialValues={searchTicketsFormValues}
      validationSchema={SearchTicketsFormSchema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      enableReinitialize={true}
    >
      {({ values, handleSubmit, isSubmitting, setFieldValue, resetForm }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} md={6} lg={2}>
                <FormTextField
                  name={"ticketNumber"}
                  label={"Ticket Number"}
                  placeholder="Search Ticket Number"
                  onChange={(e: { target: { value: string } }) =>
                    onSearchTicketNumberChange(
                      values,
                      e.target.value,
                      setFieldValue
                    )
                  }
                  value={values.ticketNumber ?? ""}
                />
                <FormErrorMessage name="ticketNumber" />
                <InputSubtitle text="*Auto Search Enabled" />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={2}>
                <FormSelect
                  name="contractorCompanyId"
                  label={"Contractor Company"}
                  value={values.contractorCompanyId}
                  items={[
                    contractorCompanies.map((contractorCompany, index) => (
                      <MenuItem key={index} value={contractorCompany.id}>
                        {contractorCompany.name}
                      </MenuItem>
                    )),
                  ]}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={2}>
                <FormSelect
                  name="transportCompanyId"
                  label="Haulage Co."
                  value={values.transportCompanyId}
                  items={[
                    transportCompanies.map((transportCompany, index) => (
                      <MenuItem key={index} value={transportCompany.id}>
                        {transportCompany.name}
                      </MenuItem>
                    )),
                  ]}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={2}>
                <FormTextField
                  name={"jobSite"}
                  label={"Job Site"}
                  value={values.jobSite ?? ""}
                  placeholder="Search Job Site"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={2}>
                <FormTruckLicensePlateField
                  value={values.truckLicensePlate ?? ""}
                  name={"truckLicensePlate"}
                  label={"Truck License Plate"}
                  placeholder={null}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Button
                  label={isSubmitting ? "Searching..." : "Search"}
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  label={isSubmitting ? "Clearing..." : "Clear"}
                  type="button"
                  disabled={isSubmitting}
                  variant="contained"
                  onClick={() => {
                    focusOnSearchInput();
                    resetForm();
                    onClear();
                  }}
                  color={"default"}
                />
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </Formik>
  );
};

export default connect()(SearchTicketsForm);
