import { Card, CardContent, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";

import { TruckScaleFormValues } from "../../../interface/formik/ScaleFormValues";
import Alert from "../../shared/Alert";

const TruckDetails = () => {
  const { values } = useFormikContext<TruckScaleFormValues>();
  const { truck, ticket } = values;
  if (!truck) return <Alert severity="error">No truck selected.</Alert>;

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography>
          <b>Truck No.</b> {truck.number}
        </Typography>
        <Typography>
          <b>Ticket Number:</b> {ticket?.ticketNumber}
        </Typography>
        <Typography>
          <b>Haulage Co.:</b> {truck.transportCompanyName}
        </Typography>
        <Typography>
          <b>Plate No.:</b> {truck.licensePlate}
        </Typography>
        <Typography>
          <b>Driver Name:</b> {truck.driverName}
        </Typography>
        <Typography>
          <b>Driver Number:</b> {truck.driverNumber}
        </Typography>
        <Typography>
          <b>Tare Weight:</b> {truck.tareWeight} KG
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TruckDetails;
