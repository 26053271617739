import TextField from "@material-ui/core/TextField";

import { FormTextReadOnlyFieldProps } from "../../interface/formik/FormTextReadOnlyFieldProps";

const FormTextReadOnlyField = ({
  name,
  label,
  value,
}: FormTextReadOnlyFieldProps) => {
  return (
    <TextField
      type="text"
      variant="outlined"
      label={label}
      name={name}
      value={value}
      fullWidth
    />
  );
};

export default FormTextReadOnlyField;
