import http from "./httpService";
import auth from "./authService";

http.setJwt(auth.getJwt());

export const getSetting = () => {
  return http.get(`/api/setting`);
};

export default {
  getSetting,
};
