import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { RootState } from "..";
import { ScaleState } from "../interface/redux-types/ScaleState";
import { SetMaterialAction } from "../interface/redux-types/SetMaterialAction";
import { SetMaterialsAction } from "../interface/redux-types/SetMaterialsAction";
import { SetMaterialTypeAction } from "../interface/redux-types/SetMaterialTypeAction";
import { SetMaterialTypesAction } from "../interface/redux-types/SetMaterialTypesAction";
import { SetScalesAction } from "../interface/redux-types/SetScalesAction";

const initialState: ScaleState = {
  scales: [],
  materials: [],
  material: null,
  materialTypes: [],
  materialType: null,
};

const slice = createSlice({
  name: "scale",
  initialState,
  reducers: {
    setScales: (state, action: PayloadAction<SetScalesAction>) => {
      state.scales = action.payload.scales;
    },
    setMaterials: (state, action: PayloadAction<SetMaterialsAction>) => {
      state.materials = action.payload.materials;
    },
    setMaterial: (state, action: PayloadAction<SetMaterialAction>) => {
      state.material = action.payload.material;
    },
    clearMaterial: (state) => {
      state.material = null;
    },
    clearMaterials: (state) => {
      state.materials = [];
    },
    clearMaterialTypes: (state) => {
      state.materialTypes = [];
    },
    setMaterialTypes: (
      state,
      action: PayloadAction<SetMaterialTypesAction>
    ) => {
      state.materialTypes = action.payload.materialTypes;
    },
    setMaterialType: (state, action: PayloadAction<SetMaterialTypeAction>) => {
      state.materialType = action.payload.materialType;
    },
    clearMaterialType: (state) => {
      state.materialType = null;
    },
  },
});

export const {
  setScales,
  setMaterials,
  setMaterial,
  clearMaterial,
  setMaterialTypes,
  setMaterialType,
  clearMaterialType,
  clearMaterials,
  clearMaterialTypes,
} = slice.actions;

export const getScales = createSelector(
  (state: RootState) => state.scaleStore.scales,
  (scales) => {
    return scales;
  }
);

export const getMaterials = createSelector(
  (state: RootState) => state.scaleStore.materials,
  (materials) => {
    return materials;
  }
);

export const getMaterial = createSelector(
  (state: RootState) => state.scaleStore.material,
  (material) => {
    return material;
  }
);

export const getMaterialTypes = createSelector(
  (state: RootState) => state.scaleStore.materialTypes,
  (materialTypes) => {
    return materialTypes;
  }
);

export const getMaterialType = createSelector(
  (state: RootState) => state.scaleStore.materialType,
  (materialType) => {
    return materialType;
  }
);

export const reducer = slice.reducer;
