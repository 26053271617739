import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import {
  Box,
  Typography,
  Container,
  Grid,
  makeStyles,
} from "@material-ui/core";

import UnitedLogo from "../../assets/logo.png";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Ticket } from "../../interface/api/Ticket";
import { Truck } from "../../interface/api/Truck";
import { SetTicketAction } from "../../interface/redux-types/SetTicketAction";
import { SetTruckAction } from "../../interface/redux-types/SetTruckAction";
import ticketService from "../../services/ticketService";
import truckService from "../../services/truckService";
import { getTicket, setTicket } from "../../store/ticketStore";
import {
  getTransportCompanyTruck,
  setTransportCompanyTruck,
} from "../../store/transportStore";
import {
  Trailer,
  TrailerSoilQuantity,
  TriaxleSoilQuantity,
} from "../../utils/constants";
import { formatDateTime } from "../../utils/date-helper";

const useStyles = makeStyles({
  boxBorder: {
    border: "2px solid",
    padding: 5,
  },
  logo: {
    maxWidth: 350,
  },
  alignRight: {
    textAlign: "right",
  },
  row: {
    marginBottom: 10,
  },
  bottomBorder: {
    borderBottom: "2px solid",
  },
  container: {
    padding: 20,
    fontSize: 14,
  },
});

const PrintRecordView = ({}) => {
  const { id }: any = useParams();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const ticket = useAppSelector(getTicket);
  const truck = useAppSelector(getTransportCompanyTruck);

  useEffect(() => {
    setTimeout(function () {
      window.print();
    }, 5000);
  }, []);

  useMemo(() => {
    ticketService.getTicket(id, true).then((response) => {
      if (response.ok) {
        const ticket = response.data as Ticket;
        const setTicketAction: SetTicketAction = {
          ticket,
        };
        dispatch(setTicket(setTicketAction));

        if (!ticket.truckId) return;

        truckService.getTruck(ticket.truckId).then((response) => {
          const truck = response.data as Truck;
          const setTruckAction: SetTruckAction = {
            truck,
          };
          dispatch(setTransportCompanyTruck(setTruckAction));
        });
      } else {
        toast.error(response.originalError.message);
      }
    });
  }, []);

  if (ticket === null) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <Typography variant="h3">Loading...</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <img src={UnitedLogo} className={classes.logo} />
            <Typography>14245 Ninth Line, Stouffville, ON, L4A 2Y6</Typography>
          </Grid>
          <Grid item xs={4} className={classes.alignRight}>
            <div className={classes.boxBorder}>
              <Typography>
                <b>Ticket #:</b> {ticket.ticketNumber}
              </Typography>
              <Typography>
                {formatDateTime(ticket.timeReceived, ticket.isSoilFLOLoad)}
              </Typography>
            </div>
            <Typography>Phone: 905-850-3044</Typography>
            <Typography>Fax: 905-850-3046</Typography>
            <Typography>unitedsoilsmanagement.com</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          className={`${classes.bottomBorder} ${classes.row}`}
        >
          <Grid item xs={12} md={6}>
            <Grid container className={classes.row}>
              <Grid item xs>
                Customer
              </Grid>
              <Grid item xs>
                {ticket.contractorCompanyName}
              </Grid>
            </Grid>
            <Grid container className={classes.row}>
              <Grid item xs>
                Jobsite
              </Grid>
              <Grid item xs>
                {ticket.jobSite}
              </Grid>
            </Grid>
            <Grid container className={classes.row}>
              <Grid item xs>
                Material
              </Grid>
              <Grid item xs>
                {ticket.materialName}
              </Grid>
            </Grid>
            <Grid container className={classes.row}>
              <Grid item xs>
                Ticket #
              </Grid>
              <Grid item xs>
                {ticket.ticketNumber}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            {" "}
            <Grid container className={classes.row}>
              <Grid item xs>
                Haulage Co.
              </Grid>
              <Grid item xs>
                {ticket.transportCompanyName}
              </Grid>
            </Grid>
            <Grid container className={classes.row}>
              <Grid item xs>
                License
              </Grid>
              <Grid item xs>
                {ticket.truckLicensePlate}
              </Grid>
            </Grid>
            <Grid container className={classes.row}>
              <Grid item xs>
                Driver's Name
              </Grid>
              <Grid item xs>
                {ticket.truckDriverName}
              </Grid>
            </Grid>
            <Grid container className={classes.row}>
              <Grid item xs>
                Truck #
              </Grid>
              <Grid item xs>
                {ticket.truckNumber}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={4} className={classes.row}>
          <Grid item xs={6}>
            <Grid container className={classes.row}>
              <Grid item xs>
                Received by
              </Grid>
              <Grid item xs>
                {ticket.userFirstName} {ticket.userLastName}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {ticket.weight && ticket.weight > 0 ? (
              <>
                <Grid container className={classes.row}>
                  <Grid item xs>
                    Gross
                  </Grid>
                  <Grid item xs>
                    <Typography variant="h5">
                      {ticket.weight + Number(truck?.tareWeight)} KG
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item xs>
                    Tare
                  </Grid>
                  <Grid item xs>
                    <Typography variant="h5">
                      {Number(truck?.tareWeight)} KG
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item xs>
                    <b>Net</b>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="h5">
                      {ticket.weight} KG {(ticket.weight / 1000).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <>
                  {ticket.truckTypeId === Trailer
                    ? TrailerSoilQuantity
                    : TriaxleSoilQuantity}
                </>
              </>
            )}
            s
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default connect()(PrintRecordView);
