import { Formik } from "formik";
import { useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  Container,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import { history } from "../../..";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  clearTransportCompanyContact,
  clearTransportCompanyTruck,
  getTransportCompany,
  getTransportCompanyContacts,
  getTransportCompanyTrucks,
  setTransportCompany,
  setTransportCompanyContacts,
  setTransportCompanyTrucks,
} from "../../../store/transportStore";
import transportCompanyService from "../../../services/transportCompanyService";
import { TransportCompany } from "../../../interface/api/TransportCompany";
import { SetTransportCompanyAction } from "../../../interface/redux-types/SetTransportCompanyAction";
import { TransportCompanyFormValues } from "../../../interface/formik/TransportCompanyFormValues";
import { TransportCompanyFormSchema } from "../schema/transport-company-form-schema";
import PageHero from "../../shared/page-hero";
import FormErrorMessage from "../../shared/form-error-message";
import FormTextField from "../../shared/form-text-field";
import Button from "../../shared/Button";
import { TransportContact } from "../../../interface/api/TransportContact";
import DeleteDialog from "../../shared/dialog/delete";
import { EditIcon, DeleteIcon } from "../../shared/icons";
import { StyledTableCell } from "../../shared/styled-table-cell";
import { StyledTableRow } from "../../shared/styled-table-row";
import { SetTransportContactsAction } from "../../../interface/redux-types/SetTransportContactsAction";
import transportContactService from "../../../services/transportContactService";
import { Truck } from "../../../interface/api/Truck";
import { SetTrucksAction } from "../../../interface/redux-types/SetTrucksAction";
import truckService from "../../../services/truckService";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  iconRow: {
    display: "flex",
    justifyContent: "space-around",
    minWidth: "150px",
  },
  tableContainer: {
    marginTop: "2rem",
  },
});

const TransportCompanyView = ({}) => {
  const classes = useStyles();
  const { id }: any = useParams();
  const dispatch = useAppDispatch();
  const transportCompany = useAppSelector(getTransportCompany);
  const transportContacts = useAppSelector(getTransportCompanyContacts);
  const trucks = useAppSelector(getTransportCompanyTrucks);
  const [openTransportContactDialog, setOpenTransportContactDialog] =
    useState(false);
  const [selectedTransportContactItem, setSelectedTransportContactItem] =
    useState<Partial<TransportContact>>({});
  const [openTransportTruckDialog, setOpenTransportTruckDialog] =
    useState(false);
  const [selectedTransportTruckItem, setSelectedTransportTruckItem] = useState<
    Partial<Truck>
  >({});
  const onOpenTransportTruckDialog = () => setOpenTransportTruckDialog(true);
  const onCloseTransportTruckDialog = () => setOpenTransportTruckDialog(false);

  const onOpenTransportContactDialog = () =>
    setOpenTransportContactDialog(true);

  const onCloseTransportContactDialog = () =>
    setOpenTransportContactDialog(false);

  useMemo(() => {
    if (!id || id === "new") return;

    transportCompanyService.getTransportCompany(id).then((response) => {
      if (response.ok) {
        const transportCompany = response.data as TransportCompany;
        const setTransportCompanyAction: SetTransportCompanyAction = {
          transportCompany,
        };

        dispatch(setTransportCompany(setTransportCompanyAction));
      } else {
        toast.error(response.originalError.message);
      }
    });

    transportContactService
      .getTransportContacts({
        transportCompanyId: id,
        truck: null,
        transportContact: null,
      })
      .then((response) => {
        if (response.ok) {
          const transportContacts = response.data as TransportContact[];
          const setTransportContactsAction: SetTransportContactsAction = {
            transportContacts: transportContacts,
          };
          dispatch(setTransportCompanyContacts(setTransportContactsAction));
        }
      });

    truckService
      .getTrucks({
        transportCompanyId: id,
        truck: null,
        transportContact: null,
      })
      .then((response) => {
        if (response.ok) {
          const trucks = response.data as Truck[];
          const setTrucksAction: SetTrucksAction = {
            trucks,
          };
          dispatch(setTransportCompanyTrucks(setTrucksAction));
        }
      });
  }, []);

  const onSubmit = (
    values: TransportCompanyFormValues,
    setSubmitting: Function
  ) => {
    transportCompanyService
      .saveTransportCompany(values as TransportCompany)
      .then((response) => {
        if (response.ok) {
          history.push("/transports");
        } else {
          const data = response.data as string;
          toast.error(data);
          setSubmitting(false);
        }
      });
  };

  const onConfirmDeleteTransportContact = () => {
    if (selectedTransportContactItem && selectedTransportContactItem.id) {
      transportContactService
        .deleteTransportContact(selectedTransportContactItem.id)
        .then((response) => {
          if (response.ok) {
            const setTransportContactsAction: SetTransportContactsAction = {
              transportContacts: transportContacts.filter(
                (c) => c.id !== selectedTransportContactItem.id
              ),
            };
            dispatch(setTransportCompanyContacts(setTransportContactsAction));
          }
        });
    }

    onCloseTransportContactDialog();
  };

  const onConfirmDeleteTransportTruck = () => {
    if (selectedTransportTruckItem && selectedTransportTruckItem.id) {
      truckService
        .deleteTruck(selectedTransportTruckItem.id)
        .then((response) => {
          if (response.ok) {
            const setTrucksAction: SetTrucksAction = {
              trucks: trucks.filter(
                (t) => t.id !== selectedTransportTruckItem.id
              ),
            };
            dispatch(setTransportCompanyTrucks(setTrucksAction));
          }
        });
    }

    onCloseTransportTruckDialog();
  };

  const initialValues: TransportCompanyFormValues = transportCompany
    ? transportCompany
    : {
        id: 0,
        name: "",
      };

  return (
    <Container maxWidth="xl">
      <PageHero
        title={
          id > 0
            ? `${transportCompany ? transportCompany.name : id}`
            : "New Transport Company"
        }
        subtitle={
          id > 0 ? "Edit Transport Company." : "Add a new Transport Company."
        }
        showBackButton={true}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={TransportCompanyFormSchema}
        onSubmit={(values, { setSubmitting }) =>
          onSubmit(values, setSubmitting)
        }
        enableReinitialize={true}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={10}>
                <FormTextField
                  name="name"
                  value={values.name}
                  label={"Company Name"}
                />
                <FormErrorMessage name="name" />
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  label="Save"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <div className={classes.tableContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={10}>
            <Typography variant="h4">Trucks</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              label="Add a Truck"
              type="button"
              onClick={() => {
                dispatch(clearTransportCompanyTruck());
                history.push(`/trucks/new/transport-company/${id}`);
              }}
              variant="contained"
              color="primary"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs>
            {trucks && (
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>ID</StyledTableCell>
                      <StyledTableCell>Company</StyledTableCell>
                      <StyledTableCell>Driver</StyledTableCell>
                      <StyledTableCell>Phone Number</StyledTableCell>
                      <StyledTableCell>Truck Number</StyledTableCell>
                      <StyledTableCell>License Plate</StyledTableCell>
                      <StyledTableCell>Tare Weight</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {trucks.map((truck: Truck) => {
                      return (
                        <StyledTableRow key={truck.id}>
                          <StyledTableCell>{truck.id}</StyledTableCell>
                          <StyledTableCell>
                            {truck.transportCompanyName}
                          </StyledTableCell>
                          <StyledTableCell>{truck.driverName}</StyledTableCell>
                          <StyledTableCell>
                            {truck.driverNumber}
                          </StyledTableCell>
                          <StyledTableCell>{truck.number}</StyledTableCell>
                          <StyledTableCell>
                            {truck.licensePlate}
                          </StyledTableCell>
                          <StyledTableCell>{truck.tareWeight}</StyledTableCell>
                          <StyledTableCell>
                            <div className={classes.iconRow}>
                              <div>
                                <EditIcon
                                  onClick={() =>
                                    history.push(
                                      `/trucks/${truck.id}/transport-company/${id}`
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <DeleteIcon
                                  onClick={() => {
                                    setSelectedTransportTruckItem(truck);
                                    onOpenTransportTruckDialog();
                                  }}
                                />
                              </div>
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
        <DeleteDialog
          open={openTransportTruckDialog}
          onClose={onCloseTransportTruckDialog}
          onConfirmDelete={onConfirmDeleteTransportTruck}
        />
      </div>
      <div className={classes.tableContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={10}>
            <Typography variant="h4">Contacts</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              label="Add Contact"
              color="primary"
              variant="contained"
              type="button"
              onClick={() => {
                dispatch(clearTransportCompanyContact());
                history.push(`/contacts/new/transport-company/${id}`);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs>
            {transportContacts && (
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>ID</StyledTableCell>
                      <StyledTableCell>Company</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Number</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transportContacts.map(
                      (transportContact: TransportContact) => {
                        return (
                          <StyledTableRow key={transportContact.id}>
                            <StyledTableCell>
                              {transportContact.id}
                            </StyledTableCell>
                            <StyledTableCell>
                              {transportContact.transportCompanyName}
                            </StyledTableCell>
                            <StyledTableCell>
                              {transportContact.name}
                            </StyledTableCell>
                            <StyledTableCell>
                              {transportContact.number}
                            </StyledTableCell>
                            <StyledTableCell>
                              <div className={classes.iconRow}>
                                <div>
                                  <EditIcon
                                    onClick={() =>
                                      history.push(
                                        `/contacts/${transportContact.id}/transport-company/${id}`
                                      )
                                    }
                                  />
                                </div>
                                <div>
                                  <DeleteIcon
                                    onClick={() => {
                                      setSelectedTransportContactItem(
                                        transportContact
                                      );
                                      onOpenTransportContactDialog();
                                    }}
                                  />
                                </div>
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
        <DeleteDialog
          open={openTransportContactDialog}
          onClose={onCloseTransportContactDialog}
          onConfirmDelete={onConfirmDeleteTransportContact}
        />
      </div>
    </Container>
  );
};

export default connect()(TransportCompanyView);
