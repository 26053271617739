import { useMemo } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router";

import { Ticket } from "../../interface/api/Ticket";
import ticketService from "../../services/ticketService";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getTicket, setTicket } from "../../store/ticketStore";
import { SetTicketAction } from "../../interface/redux-types/SetTicketAction";
import { Box, Container, Typography } from "@material-ui/core";
import UnitedLogo from "../../assets/logo.png";
import {
  Trailer,
  TrailerSoilQuantity,
  TriaxleSoilQuantity,
} from "../../utils/constants";
import { formatDate, formatTime } from "../../utils/date-helper";
import { getSetting, setSetting } from "../../store/settingStore";
import settingService from "../../services/settingService";
import { Setting } from "../../interface/api/Setting";
import { SetSettingAction } from "../../interface/redux-types/SetSettingAction";
import truckService from "../../services/truckService";
import { Truck } from "../../interface/api/Truck";
import { SetTruckAction } from "../../interface/redux-types/SetTruckAction";
import {
  getTransportCompanyTruck,
  setTransportCompanyTruck,
} from "../../store/transportStore";

const HaulageRecordView = ({}) => {
  const { id }: any = useParams();
  const dispatch = useAppDispatch();
  const ticket = useAppSelector(getTicket);
  const truck = useAppSelector(getTransportCompanyTruck);
  const setting = useAppSelector(getSetting);

  console.log("ticket", ticket);

  useMemo(() => {
    ticketService.getTicket(id, true).then((response) => {
      if (response.ok) {
        const ticket = response.data as Ticket;
        const setTicketAction: SetTicketAction = {
          ticket,
        };
        dispatch(setTicket(setTicketAction));

        if (!ticket.truckId) return;

        truckService.getTruck(ticket.truckId).then((response) => {
          const truck = response.data as Truck;
          const setTruckAction: SetTruckAction = {
            truck,
          };
          dispatch(setTransportCompanyTruck(setTruckAction));
        });
      } else {
        toast.error(response.originalError.message);
      }
    });

    settingService.getSetting().then((response) => {
      if (response.ok) {
        const setting = response.data as Setting;
        const setSettingAction: SetSettingAction = {
          setting,
        };
        dispatch(setSetting(setSettingAction));
      }
    });
  }, []);

  const contactName = useMemo(
    () =>
      ticket?.isSoilFLOLoad
        ? ticket.siteContactName
        : ticket?.contractorOfficeContactName,
    [ticket, ticket?.isSoilFLOLoad]
  );

  const contactNumber = useMemo(
    () =>
      ticket?.isSoilFLOLoad
        ? ticket.siteContactNumber
        : ticket?.contractorOfficeContactNumber,
    [ticket, ticket?.isSoilFLOLoad]
  );

  const getSoilQuantity = () => {
    if (!ticket) return;

    if (ticket.weight) {
      return (
        <>
          <div>
            <i>Gross:</i> {ticket.weight + Number(truck?.tareWeight)} KG
          </div>
          <div>
            <i>Net:</i> {ticket.weight} KG
          </div>
          <div>
            <i>Tare:</i> {Number(truck?.tareWeight)} KG
          </div>
        </>
      );
    } else {
      return ticket.truckTypeId === Trailer
        ? TrailerSoilQuantity
        : TriaxleSoilQuantity;
    }
  };

  if (ticket === null) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <Typography variant="h3">Loading...</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          border: "2px solid black",
          padding: "20px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Box
          style={{
            marginBottom: 15,
          }}
        >
          <Typography variant="h5" style={{ marginBottom: 5 }}>
            <b>United</b> Soils Management Ltd.
          </Typography>
          <img src={UnitedLogo} style={{ maxWidth: 125 }} />
        </Box>
        <Typography variant="h6" style={{ marginBottom: 30 }}>
          Haulage Record
        </Typography>
        <Box sx={{ marginBottom: 20 }}>
          <Typography style={{ marginBottom: 12 }}>
            <b>Ticket Number:</b> {ticket.ticketNumber}
          </Typography>
          <Typography variant="h5" style={{ marginBottom: 15 }}>
            LOADING SITE
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Project Address: </b>
            {ticket.jobSite}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Soil Quality: </b>
            {`Reg 153/04 Table 2 Agricultural and Other Property Use, except for EC and SAR, with Reg 406/19 exceptions for PAHs and VOCs.`}
          </Typography>
          <Typography style={{ marginBottom: 12, textDecoration: "underline" }}>
            {ticket.isSaltImpacted
              ? ` Load is to be managed as salt-impacted.`
              : ` Load is not identified as salt-impacted.`}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Contractor: </b>
            {ticket.contractorCompanyName}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Contact Name: </b>
            {contactName}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Number: </b>
            {contactNumber}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Email: </b>
            {ticket.contractorOfficeContactEmail}
          </Typography>
          {ticket.materialName && (
            <Typography style={{ marginBottom: 12 }}>
              <b>Material: </b>
              {ticket.materialName}
            </Typography>
          )}
          <Typography style={{ marginBottom: 12 }}>
            <b>Soil Quantity: </b>
            {getSoilQuantity()}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Date Loaded:</b>{" "}
            {formatDate(ticket.timeLoaded, ticket.isSoilFLOLoad)}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Time Loaded:</b>{" "}
            {formatTime(ticket.timeLoaded, ticket.isSoilFLOLoad)}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            {`Entry of Time Loaded is Loading Site confirmation that the soil was loaded under supervision, is approved for disposal at United Soils and complies with transport regulations.`}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Hauling Company: </b>
            {ticket.transportCompanyName}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Driver Name: </b>
            {ticket.truckDriverName}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Licence Plate Number: </b>
            {ticket.truckLicensePlate}
          </Typography>
          <Typography variant="h5" style={{ marginBottom: 15 }}>
            RECEIVING SITE
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Location: </b>
            {`United Soils Management 14245 Ninth Line, Stouffville, ON`}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Receiving Site Contact: </b>
            {ticket.isReceived && (
              <span>{setting.haulageRecordReceiverName}</span>
            )}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Receiving Site Number: </b>
            {ticket.isReceived && (
              <span>{setting.haulageRecordReceiverPhone}</span>
            )}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Receiving Site Email: </b>
            {setting.haulageRecordReceiverEmail}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Receiving Date: </b> {formatDate(ticket.timeReceived, false)}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            <b>Receiving Time: </b> {formatTime(ticket.timeReceived, false)}
          </Typography>
          <Typography style={{ marginBottom: 12 }}>
            {`Entry of Receiving Time is acknowledgement that soil was received and placed at United Soils.`}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default connect()(HaulageRecordView);
