import { useMemo, useRef, useEffect } from "react";
import { Typography, MenuItem, Grid, Box } from "@material-ui/core";
import { useFormikContext } from "formik";

import { TicketFormValues } from "../../../interface/formik/TicketFormValues";
import { TruckTypes } from "../../../utils/constants";
import FormErrorMessage from "../../shared/form-error-message";
import FormSelect from "../../shared/form-select";
import FormTextField from "../../shared/form-text-field";
import FormTruckLicensePlateField from "../../shared/form-truck-license-plate-field";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getTransportCompanies,
  getTransportCompanyTrucks,
  setTransportCompanyContacts,
  setTransportCompanyTrucks,
} from "../../../store/transportStore";
import { toast } from "react-toastify";
import { TransportContact } from "../../../interface/api/TransportContact";
import { TransportPayload } from "../../../interface/api/TransportPayload";
import { Truck } from "../../../interface/api/Truck";
import { SetTransportContactsAction } from "../../../interface/redux-types/SetTransportContactsAction";
import transportContactService from "../../../services/transportContactService";
import truckService from "../../../services/truckService";
import { SetTrucksAction } from "../../../interface/redux-types/SetTrucksAction";
import PhoneInput from "../../shared/phone-input";

const TransportInformation = () => {
  const dispatch = useAppDispatch();
  const transportCompanies = useAppSelector(getTransportCompanies);
  const trucks = useAppSelector(getTransportCompanyTrucks);
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
  });

  const { values, setFieldValue, handleChange } =
    useFormikContext<TicketFormValues>();

  const updateTransportCompanyTrucks = (transportCompanyId: number) => {
    const transportPayload: TransportPayload = {
      transportCompanyId,
      truck: null,
      transportContact: null,
    };

    truckService.getTrucks(transportPayload).then((response) => {
      if (response.ok) {
        const trucks = response.data as Truck[];
        const setTrucksAction: SetTrucksAction = {
          trucks,
        };

        dispatch(setTransportCompanyTrucks(setTrucksAction));
      } else {
        toast.error(response.originalError.message);
      }
    });
  };

  const updateTransportCompanyContacts = (transportCompanyId: number) => {
    const transportPayload: TransportPayload = {
      transportCompanyId,
      truck: null,
      transportContact: null,
    };

    transportContactService
      .getTransportContacts(transportPayload)
      .then((response) => {
        if (response.ok) {
          const transportContacts = response.data as TransportContact[];
          const setTransportContactsAction: SetTransportContactsAction = {
            transportContacts,
          };

          dispatch(setTransportCompanyContacts(setTransportContactsAction));
        } else {
          toast.error(response.originalError.message);
        }
      });
  };

  useMemo(() => {
    setTimeout(() => {
      if (!values.transportCompanyId || firstRender.current) return;

      const { transportCompanyId } = values;

      const transportCompany = transportCompanies.find(
        (tc) => tc.id === transportCompanyId
      );

      if (transportCompany) {
        setFieldValue("transportCompanyName", transportCompany.name);
      } else {
        setFieldValue("transportCompanyName", "");
        setFieldValue("transportCompanyId", 0);
      }

      updateTransportCompanyTrucks(transportCompanyId);
      updateTransportCompanyContacts(transportCompanyId);
    }, 10);
  }, [values.transportCompanyId]);

  useMemo(() => {
    setTimeout(() => {
      if (!values.truckId || firstRender.current) return;

      const { truckId } = values;
      const truck = trucks.find((t) => t.id === truckId);

      if (!truck) return;

      setFieldValue("truckId", truck.id);
      if (truck.driverName) {
        setFieldValue("truckDriverName", truck.driverName);
      }
      if (truck.driverNumber) {
        setFieldValue("truckDriverNumber", truck.driverNumber);
      }
      if (truck.licensePlate) {
        setFieldValue("truckLicensePlate", truck.licensePlate);
      }
    }, 10);
  }, [values.truckId]);

  return (
    <Box
      sx={{
        marginBottom: "25px",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Transport Information</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTruckLicensePlateField
            value={values.truckLicensePlate ? values.truckLicensePlate : ""}
            name={"truckLicensePlate"}
            label={"Truck License Plate"}
            placeholder={null}
          />
          <FormErrorMessage name="truckLicensePlate" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormSelect
            name="transportCompanyId"
            label={"Haulage Co."}
            value={values.transportCompanyId}
            items={[
              transportCompanies.map((transportCompany, index) => (
                <MenuItem key={index} value={transportCompany.id}>
                  {transportCompany.name}
                </MenuItem>
              )),
            ]}
            onChange={handleChange}
          />
          <FormErrorMessage name="transportCompanyId" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormSelect
            name="truckId"
            label={"Truck Number"}
            value={values.truckId}
            items={[
              trucks.map((truck, index) => (
                <MenuItem key={index} value={truck.id}>
                  {truck.number}
                </MenuItem>
              )),
            ]}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormTextField
            name="truckDriverName"
            label="Truck Driver Name"
            value={values.truckDriverName}
          />
          <FormErrorMessage name="truckDriverName" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <PhoneInput
            name="truckDriverNumber"
            label="Truck Driver Number"
            value={values.truckDriverNumber}
          />
          <FormErrorMessage name="truckDriverNumber" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormSelect
            name="truckTypeId"
            label={"Triaxle or Trailer"}
            value={values.truckTypeId}
            items={[
              TruckTypes.map((truckType, index) => (
                <MenuItem key={index} value={truckType.value}>
                  {truckType.label}
                </MenuItem>
              )),
            ]}
          />
          <FormErrorMessage name="truckTypeId" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransportInformation;
