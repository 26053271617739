import { Button as MaterialUIButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ButtonProps } from "../../interface/ButtonProps";

const useStyles = makeStyles({
  button: {
    padding: "14px 18.5px",
  },
});

const Button = ({
  label,
  color,
  variant,
  type,
  onClick,
  disabled,
  autoFocus,
}: ButtonProps) => {
  const classes = useStyles();

  return (
    <MaterialUIButton
      type={type}
      className={classes.button}
      color={color}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      autoFocus={autoFocus}
      fullWidth
    >
      {label}
    </MaterialUIButton>
  );
};

export default Button;
