import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import { useState } from "react";

import { MoveTrucksDialogProps } from "../../../interface/dialog/MoveTrucksDialogProps";
import Button from "../Button";
import FreeSelect from "../free-select";

const useStyles = makeStyles({
  dialogActions: {
    padding: "20px",
    width: 550,
  },
});

const MoveTrucksDialog = ({
  open,
  onClose,
  transportCompanies,
  onConfirm,
  fromTransportCompanyName,
}: MoveTrucksDialogProps) => {
  const classes = useStyles();
  const [selectedTransportCompanyId, setSelectedTransportCompanyId] =
    useState(0);
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>{`Move "${fromTransportCompanyName}" trucks to...`}</DialogTitle>
      <DialogContent>
        <FreeSelect
          name={"transportCompany"}
          label={"Transport Company"}
          value={selectedTransportCompanyId}
          onChange={(e: { target: { value: number } }) =>
            setSelectedTransportCompanyId(e.target.value)
          }
          items={[
            transportCompanies.map((transportCompany, index) => (
              <MenuItem key={index} value={transportCompany.id}>
                {transportCompany.name}
              </MenuItem>
            )),
          ]}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          label="No"
          type="button"
          onClick={() => onClose()}
          color="default"
          variant="contained"
        />
        <Button
          label="Move"
          type="button"
          onClick={() => onConfirm(selectedTransportCompanyId)}
          autoFocus
          color="primary"
          variant="contained"
          disabled={selectedTransportCompanyId === 0}
        />
      </DialogActions>
    </Dialog>
  );
};

export default MoveTrucksDialog;
