import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  makeStyles,
} from "@material-ui/core";

import { CheckBulkTicketsDialogProps } from "../../../interface/dialog/CheckBulkTicketsDialogProps";
import Button from "../Button";
import { useFormikContext } from "formik";
import { UpdateBulkTicketsFormValues } from "../../../interface/formik/UpdateBulkTicketsFormValues";

const useStyles = makeStyles({
  dialogActions: {
    padding: "20px",
  },
  bottomSpace: {
    marginBottom: "1rem",
  },
});

const CheckBulkTicketsDialog = ({
  open,
  onClose,
  onConfirmSubmit,
  response,
}: CheckBulkTicketsDialogProps) => {
  const classes = useStyles();
  const { resetForm } = useFormikContext<UpdateBulkTicketsFormValues>();
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>{"Confirm Bulk Update?"}</DialogTitle>
      {response && response.count === 0 ? (
        <DialogContent>
          <Typography>
            {
              "Your selected tickets have been received. No ticket's are available for bulk update."
            }
          </Typography>
        </DialogContent>
      ) : (
        <>
          {response && response?.ticketNumbers?.length > 0 ? (
            <DialogContent>
              <Typography>
                {response.ticketNumbers.length} {"ticket(s)"} will be updated:
              </Typography>
              <Typography className={classes.bottomSpace}>
                Excluded tickets ({response.excludedTicketNumbers.length}):{" "}
                {response.excludedTicketNumbers.length > 0
                  ? response.excludedTicketNumbers.join(",")
                  : "none"}
              </Typography>
              {response &&
                response.results.map((result, index) => {
                  return (
                    <Typography key={index}>
                      <b>{result.key}</b> {result.value}
                    </Typography>
                  );
                })}
            </DialogContent>
          ) : (
            <Typography>{"No ticket conflict's were found."}</Typography>
          )}
        </>
      )}
      <DialogActions className={classes.dialogActions}>
        <Button
          label="No"
          onClick={() => onClose()}
          color="default"
          variant="contained"
          type="button"
        />
        {response && response.count > 0 && (
          <Button
            label="Yes"
            onClick={() => onConfirmSubmit(resetForm)}
            autoFocus
            color="primary"
            variant="contained"
            type="button"
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CheckBulkTicketsDialog;
