export const AdministratorRole = 1;
export const StandardRole = 2;
export const Triaxle = 1;
export const TriaxleSoilQuantity = "Triaxle (10 m3)";
export const Trailer = 2;
export const TrailerSoilQuantity = "Trailer (40 m3)";
export const USMQRCodeSize = 130;

export const Roles = [
  { value: AdministratorRole, label: "Administrator" },
  { value: StandardRole, label: "Standard" },
];

export const ScaleViewType = {
  WeighIn: "WeighIn",
  Tare: "Tare",
};

export const TruckTypes = [
  { value: Triaxle, label: "Triaxle" },
  { value: Trailer, label: "Trailer" },
];

export const enum ScaleWeighTypes {
  Weight = 1,
  Tare = 2,
}

export const PhoneRegExp =
  /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const getRoleName = (roleId: number) => {
  const role = Roles.find((r) => r.value === roleId);
  return role ? role.label : "";
};

export const isAdmin = (roleId: number | string | undefined) => {
  if (roleId) {
    return Number(roleId) === AdministratorRole;
  }
  return false;
};

export const a11yProps = (index: number) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};
