import * as Yup from "yup";

export const GenerateExcelExportFormSchema = Yup.object().shape({
  from: Yup.number()
    .typeError("From number must be a number")
    .max(999999, "Max ticket number is 999999")
    .required("From ticket number is required"),
  to: Yup.number()
    .typeError("To number must be a number")
    .max(999999, "Max ticket number is 999999")
    .required("To ticket number is required"),
});
