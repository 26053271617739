import { Typography, MenuItem, Grid, Box } from "@material-ui/core";
import { useFormikContext } from "formik";

import { TicketFormValues } from "../../../interface/formik/TicketFormValues";
import FormErrorMessage from "../../shared/form-error-message";
import FormSelect from "../../shared/form-select";
import FormTextField from "../../shared/form-text-field";
import { useAppSelector } from "../../../hooks";
import { getMaterials, getMaterialTypes } from "../../../store/scaleStore";

const ScaleInformation = () => {
  const materials = useAppSelector(getMaterials);
  const materialTypes = useAppSelector(getMaterialTypes);

  const { values, handleChange } = useFormikContext<TicketFormValues>();
  return (
    <>
      <Box
        sx={{
          marginBottom: "25px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">Scale Information</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormSelect
              name="materialTypeId"
              label={"Material Type"}
              value={values.materialTypeId}
              items={[
                materialTypes
                  .filter((c) => c.name)
                  .map((materialType, index) => (
                    <MenuItem key={index} value={materialType.id}>
                      {materialType.name}
                    </MenuItem>
                  )),
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormSelect
              name="materialId"
              label={"Material"}
              value={values.materialId}
              items={[
                materials.map((material, index) => (
                  <MenuItem key={index} value={material.id}>
                    {material.name}
                  </MenuItem>
                )),
              ]}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormTextField
              name="weight"
              label="Weight"
              value={values.weight ? values.weight : 0}
            />
            <FormErrorMessage name="weight" />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ScaleInformation;
