import { useMemo, useState } from "react";
import { connect } from "react-redux";
import { Container, Typography, Box, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";

import PageHero from "../shared/page-hero";
import useWindowSize from "../../hooks/useWindowSize";
import { ProjectLog } from "../../interface/api/ProjectLog";
import contractorProjectService from "../../services/contractorProjectService";

const ProjectLowView = ({}) => {
  const { id }: any = useParams();
  const [projectLogs, setProjectLogs] = useState<ProjectLog[]>([]);
  const { isMobile } = useWindowSize();

  useMemo(() => {
    const init = async () => {
      let response = await contractorProjectService.getProjectLogs(id);
      if (response.ok) {
        const data = response.data as ProjectLog[];
        data.sort((a, b) => (a.date > b.date ? -1 : a.date < b.date ? 1 : 0));
        setProjectLogs(data);
      }
    };
    init();
  }, [id]);

  const formatData = (data: string) => {
    const formattedData = data
      .replace(/,/g, ",\n")
      .replace(/^./, "{\n")
      .replace(/.$/, "\n}");
    return formattedData;
  };

  const projectChanges = (projectLog: ProjectLog, index: number) => {
    return (
      <div
        key={projectLog.id}
        style={{ borderTop: index === 0 ? "" : "2px solid", padding: "15px" }}
      >
        <div>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box sx={{ marginBottom: 15 }}>
                <Typography>
                  <b>Change Id:</b> {projectLog.id}
                </Typography>
                <Typography>
                  <b>Timestamp:</b> {new Date(projectLog.date).toLocaleString()}
                </Typography>
                <Typography>
                  <b>Username:</b> {projectLog.username}
                </Typography>
                <Typography>
                  <b>First Name:</b> {projectLog.firstName}
                </Typography>
                <Typography>
                  <b>Last Name:</b> {projectLog.lastName}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <ReactDiffViewer
          oldValue={formatData(projectLog.currentDataObject)}
          newValue={formatData(projectLog.updatedDataObject)}
          compareMethod={DiffMethod.WORDS}
          splitView={isMobile ? false : true}
          hideLineNumbers={true}
          showDiffOnly={true}
          leftTitle={"Before"}
          rightTitle={"After"}
        />
      </div>
    );
  };

  return (
    <Container maxWidth="xl">
      <PageHero
        title="Project Logs"
        subtitle="A Log of Project Edits."
        showBackButton={true}
      />
      {projectLogs.length ? (
        projectLogs.map((log, index) => projectChanges(log, index))
      ) : (
        <PageHero
          title="No project logs found"
          subtitle=""
          showBackButton={false}
        />
      )}
    </Container>
  );
};

export default connect()(ProjectLowView);
