import { PageHeroProps } from "../../interface/PageHeroProps";
import { Box, makeStyles, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { getCurrentUser } from "../../services/authService";
import { useAppSelector } from "../../hooks";

const useStyles = makeStyles({
  backArrow: {
    color: "black",
    marginBottom: 20,
    display: "block",
    cursor: "pointer",
  },
  title: {
    marginBottom: "10px",
  },
});

const PageHero = ({ title, subtitle, showBackButton }: PageHeroProps) => {
  const classes = useStyles();
  const history = useHistory();
  const user = useAppSelector(getCurrentUser);

  const goBack = () => {
    history.goBack();
  };

  return (
    <Box
      sx={{
        marginY: 8,
      }}
    >
      {showBackButton && user !== undefined && (
        <div onClick={goBack} className={classes.backArrow}>
          <ArrowBackIcon />
        </div>
      )}
      {showBackButton && user === undefined && (
        <RouterLink to="/" className={classes.backArrow}>
          <ArrowBackIcon />
        </RouterLink>
      )}
      <Typography variant="h3" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body1">{subtitle}</Typography>
    </Box>
  );
};

export default PageHero;
