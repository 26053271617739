import * as Yup from "yup";

export const ShortTicketFromSchema = Yup.object().shape({
  timeLoaded: Yup.date().nullable().required("Time loaded is required"),
  transportCompanyName: Yup.string()
    .nullable()
    .required("Haulage Company is required"),
  truckDriverName: Yup.string().nullable().required("Driver name is required"),
  truckLicensePlate: Yup.string()
    .nullable()
    .required("License plate is required"),
});
