import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@material-ui/core";
import { Formik } from "formik";

import FormErrorMessage from "../../shared/form-error-message";
import FormTextField from "../../shared/form-text-field";
import { GenerateTicketsFormSchema } from "../schema/generate-tickets-form-schema";
import { GenerateTicketsFormProps } from "../../../interface/forms/GenerateTicketsFormProps";
import Button from "../../shared/Button";


const GenerateTicketsForm = ({
  generateTicketsFormValues,
  onSubmit,
}: GenerateTicketsFormProps) => {

  return (
    <Formik
      initialValues={generateTicketsFormValues}
      validationSchema={GenerateTicketsFormSchema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
    >
      {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
        <Box sx={{ marginBottom: 40 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} lg={3}>
                <FormTextField
                  name={"from"}
                  label={"From Ticket Number"}
                  value={values.from}
                />
                <FormErrorMessage name="from" />
              </Grid>
              <Grid item xs={6} sm={6} lg={3}>
                <FormTextField
                  name={"to"}
                  label={"To Ticket Number"}
                  value={values.to}
                  placeholder="To Ticket Number"
                />
                <FormErrorMessage name="to" />
              </Grid>
              <Grid item xs={6} sm={6} lg={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isSoilFLOTicket}
                      onChange={() =>
                        setFieldValue(
                          "isSoilFLOTicket",
                          !values.isSoilFLOTicket
                        )
                      }
                      name="isSoilFLOTicket"
                      color="primary"
                    />
                  }
                  label="SoilFLO Ticket/Load"
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button
                  label={
                    isSubmitting ? "Generating Tickets..." : "Generate Tickets"
                  }
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </Formik>
  );
};
export default GenerateTicketsForm;
