import http from "./httpService";
import auth from "./authService";
import { TransportPayload } from "../interface/api/TransportPayload";

http.setJwt(auth.getJwt());

export const deleteTransportContact = (id: number | string) => {
  return http.delete(`/api/transportcontact/${id}`);
};

export const getTransportContact = (id: number | string) => {
  return http.get(`/api/transportcontact/${id}`);
};

export const getTransportContacts = (transportPayload: TransportPayload) => {
  return http.post("/api/transportcontact/all", transportPayload);
};

export function saveTransportContact(transportPayload: TransportPayload) {
  const transportContact = transportPayload.transportContact;
  if (transportContact && transportContact.id > 0) {
    return http.put("/api/transportcontact/edit", transportPayload);
  }
  return http.post("/api/transportcontact/new", transportPayload);
}

export default {
  deleteTransportContact,
  getTransportContact,
  getTransportContacts,
  saveTransportContact,
};
